@import "../../color";
@import "../../mixins";

div.interactionRow.row {
    margin: 0;
    padding: 7px;
    border-top: solid #CCC 1px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
}

div.questionsRow.row {
    padding: 0 7px 7px;
}

/*Video statistics*/
.row span.statsNumber {
    font-size: 200%;
    display: block;
    margin-top: 8px;
}

.statsImg {
    margin-top: 5px;
    margin-right: 10px;
}

.singleQuestionHeaderText {
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.loadingScreen {
    position: absolute;
    width: 100%;
    height: calc(100% - 80px);
    left: 0;
    top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: $sky-blue-400;
    background-color: white;
}

h2 {
    margin-top: 8px;
}

.noSessions {
    text-align: center;
}

.line-legend, .pie-legend, .doughnut-legend {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.chart-legend li span, .legend-item {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

#legendDiv {
    font-size: 16pt;
    margin-bottom: 10px;
}

#ViewGraph {
    width: 100% !important;
    max-height: 300px;
    height: auto !important;
}

#DevicePieChart,
#SuccessrateDonut {
    position: relative;
    max-width: 400px;
    max-height: 400px;
    min-width: 400px;
    min-height: 400px;
    margin-right: auto;
    margin-left: auto;
    object-fit: contain;
}

.singleQuestionChart {
    width: 250px;
    height: 250px;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
    background: none;
}

.whiteContent {
    overflow: visible !important;
}

.close-chart {
    margin-top: 10px;
}

#videoTable th, #videoTable td, #questionTable th, #questionTable td, #interactionTable th, #interactionTable td, #singleQuestionHeader {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

#singleQuestionHeader {
    margin-top: 5px;
    text-align: center;
}

.required {
    color: red;
    font-size: larger;
}

#sessionButtons {
    text-align: center;
}

.showSessions {
    margin-right: 5px;
}

/* Chart JS fix test */

.chart-tooltip {
    opacity: 0;
    position: absolute;
    background: rgba(0, 0, 0, .7);
    color: white;
    padding: 3px;
    border-radius: 3px;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    pointer-events: none;
    @include translate(-50%, 0);

    &.below {
        @include translate(-50%, 0);

        &:before {
            border: solid;
            border-color: rgba(0, 0, 0, .8) transparent;
            border-width: 0 8px 8px 8px;
            bottom: 1em;
            content: "";
            display: block;
            left: 50%;
            position: absolute;
            z-index: 99;
            @include translate(-50%, -100%);
        }
    }

    &.above {
        @include translate(-50%, -100%);

        &:before {
            border: solid;
            border-color: rgba(0, 0, 0, .8) transparent;
            border-width: 8px 8px 0 8px;
            bottom: 1em;
            content: "";
            display: block;
            left: 50%;
            top: 100%;
            position: absolute;
            z-index: 99;
            @include translate(-50%, 0);
        }
    }
}

/*General statistics*/

.statBar {
    background-color: $sky-blue-800;
    color: $sky-blue-800;
    vertical-align: middle;
    padding-bottom: 20px;
    margin: 0px -30px 35px;

    div {
        text-align: center;
    }

    span.statSetting {
        font-size: 150%;
        display: block;

        p {
            font-size: 14px;
        }

        label small {
            font-weight: normal;
        }
    }

    .statsItems {
        background-color: $sky-blue-800;
        margin-bottom: -40px;
    }

    .settingItem {
        color: #FFFFFF;
        background-color: $sky-blue-800;
        margin: 16px 0;
    }
}

.btn.downloadCollapseToggle {
    margin-bottom: 10px;
}
