@import "./player.color";
@import "../../variables";

$menu-item-hover-color : $menu-item-hover-color;
.menu-interaction-trigger{
    background-color: rgba(164, 199, 229, 0.89);
    height: 10.33333%;
    color: $menu_text_color;
    font-size: 50px;
    padding: 0 25px;

    .top{
        top: 0;
    }
    .bottom{
        bottom: 0;
    }
    .left{
        left: 0;
    }
    .right{
        right: 0;
    }
    .top.left{
        border-bottom-right-radius: 20px;
    }
    .top.right{
        border-bottom-left-radius: 20px;
    }
    .bottom.left{
        border-top-right-radius: 20px;
    }
    .bottom.right{
        border-top-left-radius: 20px;
    }
}

/* Menu videoOverlay */
.videoOverlay.menu {
    z-index: map_get($player_layers, overlayMenu);

    .hideOverlay.show {
        display: none;
    }

    &.right, &.left {
        .videoOverlayInner {
            @apply rounded-none mt-0 h-full top-0;
        }
        .scrollable {
            max-height: inherit;
        }
    }
    &.center .videoOverlayInner {
        @apply mx-auto left-0 right-0 -top-full;
    }
    &.right .videoOverlayInner {
        @apply left-auto -right-full;
    }
    &.left .videoOverlayInner {
        @apply right-auto -left-full;
    }
    &.show {
        &.center .videoOverlayInner {
            @apply top-0;
        }
        &.left .videoOverlayInner {
            @apply left-0;
        }
        &.right .videoOverlayInner {
            @apply right-0;
        }
    }

    &.animate .videoOverlayInner {
        transition-property: left, right, top;
        transition: 0.5s ease;
    }

    .videoOverlayInner {
        background: #FFFFFF;
        position: absolute;
        padding-top: 1%;

        .enrich[data-orientation="portrait"] & {
            width: 90%;
        }

        .scrollable {
            max-height: 580px;

            body[data-orientation="portrait"] &, .enrich[data-orientation="portrait"] & {
                max-height: 1120px;
            }

            .menu-list {
                /* This only overwrites ".interactionContainer ul" since it's more specific than Tailwind */
                @apply mx-0 list-none;
            }
        }
    }

    .VideoContainer[data-text-direction="rtl"] & .chapter-index {
        padding-right: 0;
        padding-left: 1rem;
    }
}
