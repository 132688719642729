/*

Visual Studio-like style based on original C# coloring by Jason Diamond <jason@diamond.name>

*/
.hljs {
  display: inline-block;
  padding: 0.5em;
  background: white;
  color: black;
}

.hljs-comment,
.hljs-annotation,
.hljs-template_comment,
.diff .hljs-header,
.hljs-chunk,
.apache .hljs-cbracket {
  color: #008000;
}

.hljs-keyword,
.hljs-id,
.hljs-built_in,.css
.smalltalk .hljs-class,
.hljs-winutils,
.bash .hljs-variable,
.tex .hljs-command,
.hljs-request,
.hljs-status,
.nginx .hljs-title,
.xml .hljs-tag,
.xml .hljs-tag .hljs-value {
  color: #00f;
}

.hljs-string,
.hljs-title,
.hljs-parent,
.hljs-tag .hljs-value,
.hljs-rules .hljs-value,
.ruby .hljs-symbol,
.ruby .hljs-symbol .hljs-string,
.hljs-template_tag,
.django .hljs-variable,
.hljs-addition,
.hljs-flow,
.hljs-stream,
.apache .hljs-tag,
.hljs-date,
.tex .hljs-formula,
.coffeescript .hljs-attribute {
  color: #a31515;
}

.ruby .hljs-string,
.hljs-decorator,
.hljs-filter .hljs-argument,
.hljs-localvars,
.hljs-array,
.hljs-attr_selector,
.hljs-pseudo,
.hljs-pi,
.hljs-doctype,
.hljs-deletion,
.hljs-envvar,
.hljs-shebang,
.hljs-preprocessor,
.hljs-pragma,
.userType,
.apache .hljs-sqbracket,
.nginx .hljs-built_in,
.tex .hljs-special,
.hljs-prompt {
  color: #2b91af;
}

.hljs-phpdoc,
.hljs-javadoc,
.hljs-xmlDocTag {
  color: #808080;
}

.vhdl .hljs-typename { font-weight: bold; }
.vhdl .hljs-string { color: #666666; }
.vhdl .hljs-literal { color: #a31515; }
.vhdl .hljs-attribute { color: #00b0e8; }

.xml .hljs-attribute { color: #f00; }