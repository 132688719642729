@import "player.color";
@import '../../mixins';
@import "../../variables";

$controlbar-background-color: $playerbar-color;

html.iframed{
    overflow: hidden;
}

#video-preview, #playerContainer {
    margin-left: auto;
    margin-right: auto;

    body[data-orientation="portrait"]:not(.is-mobile) & {
        width: 50%;
    }
}

.radio {
    margin: 0 !important;
}

/*----------------------- video Overlays player*/
.videoOverlay {
    position: absolute;
    clear: both;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
    //background-color: rgba(7, 26, 39, 0.8);
    z-index: map-get($player_layers, videoOverlay);
    color: white;
    .answers.empty-title {
        margin-top: 50px;
    }
    &.menu-button, &.previous-video-button, &.enable-sound {
        pointer-events: none;
        background-color: transparent;
        z-index: map-get($player_layers, menuButton);
        .show-menu {
            right: 15px;
            bottom: 15px;

            i {
                top: -3px;
            }
        }
        .show-previous-video-button {
            right: 15px;
            top: 15px;
            .btn-show-video-back {
                font-size: 175%;
            }
        }
        .show-menu, .show-previous-video-button, .enable-sound-button {
            pointer-events: auto;
            position: absolute;
            .VideoContainer:not(.static-control-bar) & {
                margin-bottom: 50px;
            }

            .VideoContainer.big-control-bar & {
                margin-bottom: 60px;
            }

            &.bottom-left {
                left: 15px;
                bottom: 15px;
                right: auto;
                top: auto;
            }

            &.bottom-right {
                right: 15px;
                bottom: 15px;
                left: auto;
                top: auto;
            }

            &.top-right {
                right: 15px;
                top: 15px;
                left: auto;
                bottom: auto;
            }

            &.top-left {
                bottom: auto;
                right: auto;
                top: 15px;
                left: 15px;
            }
        }
    }
    &.default-subtitle-toggle {
        pointer-events: none;
        background-color: transparent;
        z-index: map-get($player_layers, subtitleToggleButton);

        .toggle-default-subtitles {
            pointer-events: auto;
            position: absolute;

            .btn-toggle-default-subtitle {
                font-weight: var(--font-weight, 400);
                font-size: 32px;
                // @TODO - Needs a discussion about how to handle button size on small screens
                //@media (hover: none) and (pointer: coarse) and (max-width: 768px) {
                //    font-size: 2.9em;
                //}
                padding: 8px 18px;
                .subtitle-toggle-title {
                    margin-left: 5px;
                }
                .icon-enabled {
                    display: none;
                }
                &.enabled {
                    .icon-enabled {
                        display: inline;
                    }

                    .icon-disabled {
                        display: none;
                    }
                }
            }

            &[data-position="default"], &[data-position*="top-"] {
                top: 15px;
            }

            &[data-position*="bottom-"] {
                bottom: 15px;

                .VideoContainer:not(.static-control-bar) & {
                    margin-bottom: 50px;
                }

                .VideoContainer.big-control-bar & {
                    margin-bottom: 60px;
                }
            }

            &[data-position*="-right"] {
                right: 15px;
            }

            &[data-position="default"], &[data-position*="-left"] {
                left: 15px;
            }
        }
    }
    &.question, &.menu, &.loading, &.nextVideo, &.iphoneNotice, &.videoPlaylist, &.scoreEndScreen, &.feedback, &.variable {
       @include gradient-color-overlay($background-color,$background-color);
        &[data-layout="MODERN"]:not(.feedback):not(.scoreEndScreen) {
            @include gradient-color-overlay($background-color, $background-color, 0.67, 0.88, 0%, 90%);
        }
    }
    &.mobilePlayButton {
        background-color: rgba(29,29,27,0.4);
    }
    .full-screen-button {
        display: none;
    }

    .VideoContainer.menu-interaction-active &.chapter {
        display: none;
    }

    &.question,
    &.feedback,
    &.tincan,
    &.loading,
    &.scoreEndScreen,
    &.variable,
    &.iphoneNotice {
        z-index: map_get($player_layers, overlayQuestion);
        padding: 1%;
        &[data-layout="MODERN"]:not(.scoreEndScreen) {
            padding: 1.9rem $default-gutter-modern 1% $default-gutter-modern;
        }

        body[data-orientation="portrait"] & {
            &[data-layout="MODERN"]:not(.scoreEndScreen) {
                padding: 1.9rem 0 1% 0;
            }
        }
    }
}

.videoOverlay.scoreEndScreen {
    background-color: rgba(7, 26, 39, 0.3);
    #buttons-bottom {
        padding: 0;
        width: calc(100% - 3rem);
    }
}
.videoOverlay.variable .description {
    padding-top: 2%;
    padding-bottom: 1%;
    font-size: 30px;
}
.videoOverlay.variable p.error {
    padding-top: 1%;
    color: #FFF;
    font-size: 25px;
}
.videoOverlay.variable p.progress-text {
    font-size: 20px;
    font-weight: var(--font-weight, 700);
    color: slategray;
}
.videoOverlay.question h2, .videoOverlay.feedback h2 {
    margin-top: 0;
}
.videoOverlay.question [type=checkbox],
.videoOverlay.question [type=radio],
.videoOverlay.feedback [type=checkbox],
.videoOverlay.feedback [type=radio] {
    vertical-align: middle;
    margin-top: 0;
    margin-right: 5px;
}
/* Grijze kleur voor custom form controls */
.videoOverlay.question .answers .fa {
    color: #555;
}
.videoOverlay.question .error-text .error {
    color: red;
    font-size: 25px;
    padding-top: 1%;
    &.no-answer-selected {
        display: flex;
        margin-bottom: 3%;
    }
}
.videoOverlay .scrollable {
    height: 100%;
    padding: 0 2%;
    overflow-y: auto;
}

.videoOverlay.question .answers .checkbox,
.videoOverlay.question .answers .radio {
    padding-left: 0;
}
.questionsTable {
    table.questions {
        color: #fff;
        border: none !important;
        th {
            border: none !important;
            font-size: 200% !important;
            padding: 10px 15px 20px 15px !important;
            &:first-child {
                padding-left: 0;
            }
            .result-column {
                width: 4rem;
            }
        }
        td {
            border: none !important;
            &:not(:first-child) {
                background-color: rgba(255, 255, 255, 0.2);
                padding: 10px 20px 0 15px !important;
            }
        }
        tr.question:hover, tr.question:hover td {
            background-color: rgba(255, 255, 255, 0.2) !important;
        }
        thead tr:hover, thead tr:hover th {
            background-color: initial;
        }
    }

}

// Right now only applicable for the  Noordhoff theme
#scoreQuestionResults table.questions {
    position: relative;
    tbody::after {
        position: absolute;
        display: block;
    }
}
.videoOverlay.correct {
    background: linear-gradient(to bottom, rgba(0,71,42,0.8) 0%,rgb(0,71,42) 85%);
    color: #ffffff;
}
.videoOverlay.incorrect {
    background: linear-gradient(to bottom, rgba(109,28,40,0.8) 0%,rgb(109,28,40) 85%);
    color: #ffffff;
}
.videoOverlay.partlyCorrect {
    background: linear-gradient(to bottom, rgba(192, 86, 33, 0.8) 0%, rgb(192, 86, 33) 85%);
    color: #ffffff;
}

.videoOverlay.direct-feedback-open {
    .answers {
        opacity: .7;
        filter: grayscale(.6);
    }
}

.videoOverlay.direct.feedback:not(.correct):not(.incorrect),
.videoOverlay.direct {
    background: transparent !important;
    .videoOverlayInner > div > h3 {
        position: absolute;
        top: .5rem;
        left: 50%;
        display: inline-flex;
        align-items: center;
        gap: 1rem;
        padding: 1.3rem 3rem .8rem 3rem;
        color: var(--player-theme-background-color, $deep-blue-800);
        background: var(--player-theme-question-text-color, $hihaho-white);
        box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.8);
        border-radius: 2.2rem;
        font-size: 275%;
        opacity: 1;
        transform: translate(-50%, -100%);
        animation: toastDown .2s cubic-bezier(0, 0, .75, 2) forwards;
        transition: opacity .3s linear;
        &:hover {
            opacity: .2;
        }
        p,
        p.interactionTextQuestion {
            font-family: var(--font-family, 'Roboto', Arial, sans-serif);
        }
        p,
        p.interactionTextQuestion,
        .feedbackTextIcon {
            display: inline;
            vertical-align: middle;
            color: var(--player-theme-background-color, $deep-blue-800);
            font-size: 1em;
            font-weight: bold;
            line-height: 1;
        }

        .feedbackTextIcon {
            font-size: 105%;
            font-weight: normal;
            &::before {
                display: inline-block;
            }
            &.fa-answer-state-incorrect {
                margin-right: -.72rem;
                transform: translate(0, -.05em);
                color: var(--player-theme-background-color, $text-red-500);
            }
            &.fa-answer-state-correct {
                transform: translate(0, -.06em);
                color: var(--player-theme-background-color, $green-500);
            }
            &.fa-answer-state-partly-correct {
                color: var(--player-theme-background-color, $text-orange-300);
            }
        }
        p {
            transform: translate(0, -.04em);
        }
        p:empty {
            display: none;
        }
        &[data-layout="modern"] {
            .videoOverlayInner > div > h3 {
                bottom: .8rem;
            }
        }
    }
}

.hideOverlay {
    display: none;
}

.videoOverlay {
    &.question .videoOverlayInner {
        padding-bottom: 9%;
        // @TODO - Needs a discussion about how to handle button size on small screens
        //@media (hover: none) and (pointer: coarse) and (max-width: 768px) {
        //    padding-bottom: 13%;
        //}
    }
    .videoOverlayInner {
        padding: 2% 0;
        position: relative;
        height: 100%;
        color: black;
    }
    &[data-layou="modern"] {
        &.question .videoOverlayInner {
            padding-bottom: 9.2%;
        }
    }
}

.videoOverlay:not(.correct, .incorrect, .partlyCorrect) {
    .videoOverlayInner {
        /*background-color: rgba(255,255,255,0.8);*/
    }
}

.vimeoQuestionButtons {
    bottom: 15% !important;
}

.videoOverlayInner {
    #buttons-bottom,
    .bottom-items {
        position: absolute;
        bottom: 4%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 2%;
        [data-layout="MODERN"] & {
            padding-right: $default-gutter-modern;
            padding-left: $default-gutter-modern;
        }

        [data-layout="MODERN"] & {
            body[data-orientation="portrait"] & {
                padding-right: 2em;
                padding-left: 2em;
                display: block;
            }
        }

        .btn {
            // @TODO - Needs a discussion about how to handle button size on small screens
            //@media (hover: none) and (pointer: coarse) and (max-width: 768px) {
            //    padding: .4em .9em;
            //    font-size: 3.9em;
            //    border-radius: 40px / 50%;
            //}
            font-weight: normal;

            [data-layout="MODERN"] & {
                svg {
                    margin-top: -5px;
                    margin-right: 0;
                }
            }
        }

        .bottom-center {
            margin: 0 2%;
            p {
                color: #FFF;
                font-size: 48px;
            }
        }

        .bottom-left, .bottom-right {
            flex: 1;
            display: flex;
        }

        .bottom-left {
            justify-content: flex-start;
            body[data-orientation="portrait"] & {
                justify-content: center;
                padding-bottom: 15px;
            }
            .btn {
                margin-right: 15px;
                &::before {
                    display: inline-block;
                }
            }
            body[data-orientation="portrait"] & {
                justify-content: center;
                padding-bottom: 15px;
                .btn {
                    margin-right: 0;
                }
            }
        }

        .bottom-right {
            justify-content: flex-end;
            .btn {
                margin-left: 15px;
                &::before {
                    display: inline-block;
                }
            }
            body[data-orientation="portrait"] & {
                justify-content: center;
                .btn {
                    margin-left: 0;
                }
            }
        }
    }
    .scroll-hint-wrapper {
        p {
            color: #FFF;
            font-size: 48px;
        }
    }
    .skip-question-wrapper {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        bottom: 17%;
        width: 100%;
        padding: 0 2%;
    }

    body:not([data-orientation="portrait"]) & {
        .portrait-items {
            display: none;
        }
    }

    body:not([data-orientation="landscape"]) & {
        .landscape-items {
          display: none;
        }
    }
}

.videoOverlayInner {
    .top-left {
        position: absolute;
        left: 23px;
        top: 4%;
    }

    .top-right {
        position: absolute;
        right: 23px;
        top: 4%;
    }
}

.optional-variable {
    h1, .description {
        color: $question_text_color;
    }
}

.videoOverlayInner h1,
.videoOverlayInner h2,
.videoOverlayInner h3,
.videoOverlayInner h4 {
    margin-top: 0;
}

/*end video overlays*/
#SkipFeedback {
    color: #FFF;
    background-color: $player-primary;
    border-color: $player-primary;
    &:hover {
        color: $player-primary;
        background-color: #fff;
        border-color: #fff;
    }
}

/*player*/
.VideoContent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: map_get($player_layers, videoContent);
    margin: auto;
    .tooltip .tooltip-inner{
        ol {
            list-style-type: decimal;
            margin-left: 1.5rem;
        }
        ul {
            list-style-type: disc;
            margin-left: 1.5rem;
        }
    }
}

#player, #player_wrapper, #Vimeoplayer, #qumu_player{
    z-index: map-get($player_layers, playerWrapper) !important;
    position: absolute!important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%!important;
}

.VideoContainer {
    z-index: map-get($player_layers, videoContainer);
    position: relative;
    height: 0;
    width: 100%;
    padding-top: 0;
    padding-bottom: 56.25%;
    background-color: transparent;
    overflow: hidden;
    opacity: 1;

    .videoOverlay {
        &.menu {
            .videoOverlayInner {
                body[data-orientation="portrait"] & {
                    width: 90%;
                }
            }
        }
        &.question {
            body[data-orientation="portrait"] & {
                width: 100%;
                .scrollable {
                    max-height: 980px;
                    .title {
                        max-height: 560px;
                    }
                }
            }
        }
    }

    body[data-orientation="portrait"] & {
        padding-bottom: 177.778%
    }

    p {
        margin: 0;
        line-height: 1.1;
    }
    .PlayerPrevention{
        position: absolute;
        width:100%;
        height:100%;
        z-index: map_get($player_layers, playerPrevention)!important;
        top: 0;
        bottom: 0;
    }

    &.hide-control-bar {
        &:not(.asking-optional-variables):not(.disable-clicks):not(.asking-question):not(.menu-interaction-active):not(.score-screen-active):not(.video-ended):not(.interaction-disables-player) {
            cursor: none;

            .VideoContent, .interactionContainer {
                cursor: none;
            }
        }
    }

    &[data-text-direction="rtl"] {
        .videoOverlay.chapter .chapter-interaction .btn-expand,
        .videoOverlay.subtitles .subtitles,
        .menu-layout-title,
        .chapter-item,
        .menu,
        .subtitle-dropdown-menu,
        .subtitle-toggle-title,
        #editor-close-menu {
            direction: rtl;
        }
    }

    .videoOverlay {
        &.question,
        &.feedback,
        &.menu,
        &.menu-button,
        &.chapter,
        &.variable,
        &.scoreEndScreen,
        &.subtitles,
        &.default-subtitle-toggle,
        &.videoPlaylist,
        &.nextVideo {
            font-family: var(--font-family, 'Roboto', Arial, sans-serif);
            font-weight: var(--font-weight, 400);
            font-style: var(--font-style, normal);
        }

        &.feedback:not(.correct):not(.incorrect) {
            .interactionTextQuestion {
                color: var(--player-theme-question-text-color, #FFFFFF);
            }
        }

        &.feedback .rich-text {
            p {
                margin-block-start: 1em;
                margin-block-end: 1em;
            }

            ol, ul {
                margin-block-start: 1em;
                margin-block-end: 1em;
            }

            a {
                mix-blend-mode: difference;
                color: #fff;
                text-decoration: underline;
            }
        }

        &.variable:not(.incorrect) {
            &:not(.user-input) {
                display: none;
            }
        }
    }

    &.asking-optional-variables {
        .interactionContainer {
            display: block !important;
        }
    }

    &.disable-clicks {
        .videoOverlay {
            &.pause, &.mobilePlayButton {
                display: none !important;
            }
        }
    }
    &.panopto.disable-clicks .controlBar  {
        display: none;
    }
    &.disable-clicks, &.use-native-player {
        &.static-control-bar, &.is-mobile {
            background-color: transparent;
            .controlBar {
                display: none;
            }
        }
        .interactionContainer, .controlBar, .PlayerPrevention {
            pointer-events: none;
        }
        .controlBar .controls .right-buttons {
            pointer-events: auto;
        }
        .videoOverlay {
            &.question, &.feedback, &.menu, &.variable, &.scoreEndScreen {
                pointer-events: auto;
            }
            &.default-subtitle-toggle {
                display: none;
            }

            &.chapter .chapter-interaction {
                pointer-events: auto;
            }
        }
    }

    body.embedded &, body.emulate-fullscreen & {
        background-color: #000;
    }

    &.fullscreen {
        background-color: #000;
    }

    &.static-control-bar {
        padding-top: $controlbar-height;
        overflow: visible;

        .VideoContent {
            bottom: $controlbar-height;
        }
    }
}

#playerContainer {
    body:not(.embedded):not(.fullscreen) & {
        margin-top: 30px;
    }

    @media (max-width: $screen-md-max) {
        & {
            margin-top: 0 !important;
            width: 100%;
            height: auto;
        }
    }

    body:not([data-orientation="portrait"]).embedded &,
    body:not([data-orientation="portrait"]).fullscreen & {
        .VideoContainer {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 0;
            margin: 0;
            width: auto;
            height: auto;
        }
    }
}

body {
    &.embedded, &.emulate-fullscreen, &.fullscreen {
        &, & #body {
            background-color: black;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .video-footer, .videoinformation, .comments {
            display: none;
        }
    }

    &:not([data-orientation="portrait"]).emulate-fullscreen #body {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
}

.interactionContainer {
    position: absolute;
    overflow: hidden;
    height: 720px;
    width: 1280px;

    body[data-orientation="portrait"] & {
        height: 1280px;
        width: 720px;
    }

    &.is-360 {
        pointer-events: none;

        .PlayerPrevention {
            pointer-events: none;
        }

        .videoOverlay > *:not(.videoOverlayInner), .videoOverlayInner > * {
            pointer-events: auto;
        }
    }

    .interactionText {
        img {
            display: inline;
        }
    }

    .interactionText, .videoOverlay.feedback .interactionTextQuestion {
        font-size: 200%;
    }

    .interactionText, .interactionTextQuestion {
        .questiontext, .feedbackText {
            font-size:250%;
        }
     }
    .PauseInteraction {
        .interactionText {
            p {
                margin: 13px 0;
                > span {
                    //TODO: block = bad?
                    display: block;
                }
            }
        }
    }
    .interactionTextQuestion {
        color: #ffffff;

        .questiontext {
            color: #ffffff;
        }
    }
    .btn {
        font-size: 275%;
    }
    .portrait-btn {
        body[data-orientation="portrait"] & {
            font-size: 225%;
        }
    }
    ol {
        list-style-type: decimal;
        margin-left: 3.5rem;
        margin-right: 3.5rem;
    }
    ul {
        list-style-type: disc;
        margin-left: 3.5rem;
        margin-right: 3.5rem;
    }
}

.btn-responsive {
    font-size: 14px;
    line-height: 1.42857143;
    @include break(0px, 479px){
        padding: 1px 5px;
        font-size: 12px;
        line-height: 1.5;
    }
    @include break(480px, 699px){
        padding: 5px 10px;
        font-size: 12px;
        line-height: 1.5;
    }
    @include break(700px, 991px){
        padding: 1px 5px;
        font-size: 12px;
        line-height: 1.5;
    }
    @include break(992px){
        padding: 10px 16px;
        font-size: 18px;
        line-height: 1.33;
    }
}
#playerContainer label::selection, #playerContainer p::selection, #playerContainer div::selection, #playerContainer span::selection, #playerContainer h2::selection, #playerContainer h3::selection {
  background: rgba(0,0,0,0);
}
#playerContainer label::selection, #playerContainer p::-moz-selection, #playerContainer div::-moz-selection, #playerContainer span::-moz-selection, #playerContainer h2::selection , #playerContainer h3::selection{
  background: rgba(0,0,0,0);
}

.jwlogo {
    display: none !important;
}

.jwplayer.jw-stretch-uniform .jw-media video {
    object-fit: contain !important;
    object-position: bottom;
}

.details-step-numbers,
#scoreOverview,
#scoreQuestionResults {
    .interactionText {
        color: #FFF;
    }
}

#scoreQuestionResults {
    h3 {
        margin-bottom: 0;
    }
}

#buttons-bottom .details-navigator {
    .details-navigator-item {
        border-radius: 50%;
        padding: .2rem .9rem .1rem .9rem;
    }
}

#ScoreDetailedResults {
    .interactionText {
        color: #FFF;
    }

    .score-detailed-results-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
    }

    h3 p {
        word-break: break-all;
        margin-bottom: 0;
    }

    h4 {
        margin-bottom:1.8rem;
        font-weight: bold;
    }

    p {
        margin-bottom: 2rem;
    }

    #singleQuestionDetailContainer {
        display: grid;
        grid-auto-columns: 100%;
        grid-auto-flow: column;
        width: 100%;
        overflow-x: hidden;
        scroll-snap-type: x mandatory;
        .single-question-detail-template-item {
            grid-column: span 1 / span 1;
            display: flex;
            overflow-x: hidden;
            scroll-snap-align: start;
            width: 100%;
            min-width: 100%;
        }
        .question-and-answer {
            flex: 1;
            height: 430px;
            background: rgba(255, 255, 255, 0.2);
            padding: 2rem;
            overflow: auto;
        }
        .score-and-result {
            width: 14rem;
        }
    }

    .result-indicator {
        display: flex;
        flex-direction: column;
    }
}

.videoOverlayInner {
    .tooltip {
        //margin-top: 2%;
        .tooltip-inner {
            background-color: #FFF;
            color: #000;
        }
    }
}

.off-line-alert {
    padding: 20px;
    background-color: $controlbar-background-color;
    border-color: $controlbar-background-color;
    color: #FFFFFF;
    strong {
        color: inherit;
    }
    a, a:hover, a:focus, a:active, a:active:focus, a:active:hover  {
        background-color: $hihaho-green;
        color: inherit;
        float: right;
        margin-top: -7px;
        display: block;
        box-shadow: 0 20px 25px -5px rgba(0,0,0,.1),0 10px 10px -5px rgba(0,0,0,.04);
        text-decoration: none;
        border-color:  $hihaho-green;
    }
}

.Starthihaho {
    padding: 8px 30px 8px 30px;
}

.animate-pause-gif {
    .default-image {
        display: none;
    }

    .static-gif {
        display: block;
    }

    &:hover {
        .default-image {
            display: block;
        }

        .static-gif {
            display: none;
        }
    }
}
