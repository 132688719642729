@import "../../color";
@import "../../mixins";

@layer components {
    .btn-remove-item {
        @apply
        px-2
        pt-1.25
        pb-1
        border-1
        border-red-400
        rounded-full
        bg-red-400
        text-white
        text-lg;
        &:hover {
            @apply bg-white text-red-400
        }
    }
}

.theme-btn {
    @apply flex justify-center items-center py-1 px-10 rounded-xl border no-underline cursor-pointer;

    &:focus,
    &:active,
    &:hover {
        @apply no-underline;
    }

    &:focus,
    &:active {
        @apply outline-none;
    }

    &[disabled] {
        @apply text-white bg-gray-400 border-gray-400;
        &:hover {
            @apply text-white bg-gray-600 border-gray-600;
        }
    }

    .svg-inline--fa, span.glyphicons, i {
        @apply mr-2;
    }
}

.theme-btn-default {
    @apply bg-white text-sky-blue-400 border-sky-blue-400;

    &:hover {
        @apply bg-sky-blue-400 text-white border-sky-blue-400;
    }
}

.theme-btn-primary {
    @apply bg-deep-blue-800 text-white border-deep-blue-800;

    &:hover {
        @apply bg-deep-blue-800 text-white border-deep-blue-800;
    }
}

.theme-btn-success {
    @apply bg-green-500 text-white border-green-500;

    &:hover {
        @apply bg-green-600 text-white border-green-600;
    }
}

.theme-btn-cancel {
    @apply bg-white text-deep-blue-300 border-deep-blue-300;

    &:hover {
        @apply bg-deep-blue-300 text-white border-deep-blue-300;
    }
}

.theme-btn-danger {
    @apply bg-red-700 text-white border-red-700;

    &:hover {
        @apply bg-red-900 text-white border-red-900;
    }
}

.theme-btn-small {
    @apply px-3;
    .svg-inline--fa, span.glyphicons, i {
        @apply mr-1;
    }
}

.theme-btn-xs {
    @apply px-3 py-0.5 leading-tight;
    .svg-inline--fa, span.glyphicons, i {
        @apply mr-0.5;
    }
}

.theme-btn-has-icon-only {
    .svg-inline--fa, span.glyphicons, i {
        @apply mr-0;
    }
}

.theme-btn-large {
    @apply py-1 text-base;
}

.theme-select-box {
    @apply mt-2 p-1 border-2 border-sky-blue-600 rounded-md w-full text-sky-blue-950;

    &:active,
    &:hover,
    &:focus {
        @apply outline-none;
    }
}

.incorrect {
    @apply border-red-400;
    @apply text-red-400;
}

.slider-range {
    .ui-slider-horizontal {
        height: .7em;
    }

    .ui-slider-range {
        background: $sky-blue-800;
        border-color: $sky-blue-800;
    }

    .ui-slider-handle {
        background: $sky-blue-950;
        border-color: rgba($sky-blue-950, 0.5);
        border-radius: 100%;
        top: -1px !important;
        height: 0.6rem;
        width: 0.6rem;
        margin-left: -0.6em;

        &:first-of-type {
            margin-left: 0;
        }
    }

    .ui-slider-handle:hover, .ui-slider-handle.ui-state-active {
        background: darken($sky-blue-800, 10%);
    }
}

.previewContainer {
    height: 720px;
    width: 1280px;
    transform-origin: 0 0;
    transform: scale(0.4);
}

.NonEditableVariable .input-group-addon {
    background-color: #eeeeee;
}

.emailActivated .successIcon {
    position: absolute;
    margin: 12px;
    right: 0;
    padding: 0 15px;
}

.onoffswitch {
    top: 6px;
    margin-right: 5px;
}

.input-sm {
    height: 31px;
}

.btn-outlined {
    background-color: transparent;
    border-color: $hihaho-green;
    color: $hihaho-green;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;

    &:hover {
        background-color: $green-600;
        border-color: $green-600;
        color: $hihaho-white;
    }
}

/**
 * Subtitles tab
 */
#subtitles-content #default-subtitle-settings.is-closed + div {
    @apply mt-0;
}

// This will not work with Tailwindcss - It mixes up the property specificity order
.transitioned-loader-button + .transitioned-loader p {
    color: $text-orange-300;
    opacity: 0;
    transition: opacity .5s ease-out;
}

.transitioned-loader-button[disabled] + .transitioned-loader p,
.transitioned-loader-button.show-delay + .transitioned-loader p {
    opacity: 1;
    transition-duration: 0ms;
}
