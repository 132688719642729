$screen-xs-min: 0px;
$screen-xs-max: 768px;

$screen-sm-min: 768px;
$screen-sm-max: 992px;

$screen-md-min: 992px;
$screen-md-max: 1200px;

$screen-lg-min: 1200px;

$progressbar-height: 6px;
$controlbar-height: 42px;
$controlbar-height-big: 52px;
$controlbar-big-extra-height: $controlbar-height-big - $controlbar-height;
$controlbar-controls-height: $controlbar-height - $progressbar-height;
$controlbar-controls-height-big: $controlbar-height-big - $progressbar-height;

$default-border-radius: (
    firstRadius: 30px,
    firstRadiusSmall: 20px,
    secondRadius: 50%,
);

$border-radius-theme-modern: (
    firstRadius: 10px,
    firstRadiusBig: 14px,
    firstRadiusSmall: 5px,
    secondRadius: 50%,
);

$default-gutter-modern: 2.7rem;
$full-screen-button-width: 22rem;

//player z-indexes
$player_layers: (
    demoInteraction: 200, //enrich
    bufferNotice: 200,
    validationMarker: 200,
    controlBar: 127,
    overlayTop: 127,
    overlayButtons: 127,
    overlayQuestion: 126,
    overlaySubtitles: 124,
    overlayMenu: 125,
    overlayChapter: 125,
    subtitleToggleButton: 124,
    menuButton: 124, // Over the mobile overlay, but under questions / optional variable
    overlayPause: 123,
    nonClickBackground: 122, //enrich
    playerBarHeightIndicator: 121, //enrich
    videoOverlay: 121,
    freeUserAdd: 121,
    gridBackground: 121,//enrich

    //20-120 are reserved for interactions

    playerPrevention: 12,
    videoContent:  -1,
    videoContainer: 10,
    playerWrapper: 10,
);
