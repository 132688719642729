
/* ============================================================================================================================
== RECTANGLE-BORDER STYLE WITH CURVE
** ============================================================================================================================ */

.rectangle-speech-border {
  position:relative;
  padding:50px 15px;
  margin:1em 0 3em;
  border:10px solid #5a8f00;
  text-align:center;
  color:#333;
  background:#fff;
  /* css3 */
  -webkit-border-radius:20px;
  -moz-border-radius:20px;
  border-radius:20px;
}

/* creates larger curve */
.rectangle-speech-border:before {
  content:"";
  position:absolute;
  z-index:10;
  bottom:-40px;
  left:50px;
  width:50px;
  height:30px;
  border-style:solid;
  border-width:0 10px 10px 0;
  border-color:#5a8f00;
  background:transparent;
  /* css3 */
  -webkit-border-bottom-right-radius:80px 50px;
  -moz-border-radius-bottomright:80px 50px;
  border-bottom-right-radius:80px 50px;
  /* reduce the damage in FF3.0 */
  display:block;
}

/* creates smaller curve */
.rectangle-speech-border:after {
  content:"";
  position:absolute;
  z-index:10;
  bottom:-40px;
  left:50px;
  width:20px;
  height:30px;
  border-style:solid;
  border-width:0 10px 10px 0;
  border-color:#5a8f00;
  background:transparent;
  /* css3 */
  -webkit-border-bottom-right-radius:40px 50px;
  -moz-border-radius-bottomright:40px 50px;
  border-bottom-right-radius:40px 50px;
  /* reduce the damage in FF3.0 */
  display:block;
}

/* creates a small circle to produce a rounded point where the two curves meet */
.rectangle-speech-border > :first-child:before {
  content:"";
  position:absolute;
  bottom:-40px;
  left:45px;
  width:10px;
  height:10px;
  background:#5a8f00;
  /* css3 */
  -webkit-border-radius:10px;
  -moz-border-radius:10px;
  border-radius:10px;
}

/* creates a white rectangle to cover part of the oval border*/
.rectangle-speech-border > :first-child:after {
  content:"";
  position:absolute;
  bottom:-10px;
  left:76px;
  width:24px;
  height:15px;
  background:#fff;
}

/* ============================================================================================================================
== OVER SPEECH BUBBLE, EMPTY, WITH BORDER (more CSS3)
** ============================================================================================================================ */

.oval-speech-border {
  position:relative;
  padding:70px 30px;
  margin:1em auto 60px;
  border:10px solid #f3961c;
  text-align:center;
  color:#333;
  background:#fff;
  /* css3 */
  /*
  NOTES:
  -webkit-border-radius:240px 140px; // produces oval in safari 4 and chrome 4
  -webkit-border-radius:240px / 140px; // produces oval in chrome 4 (again!) but not supported in safari 4
  Not correct application of the current spec, therefore, using longhand to avoid future problems with webkit corrects this
  */
  -webkit-border-top-left-radius:240px 140px;
  -webkit-border-top-right-radius:240px 140px;
  -webkit-border-bottom-right-radius:240px 140px;
  -webkit-border-bottom-left-radius:240px 140px;
  -moz-border-radius:240px / 140px;
  border-radius:240px / 140px;
}

/* creates larger curve */
.oval-speech-border:before {
  content:"";
  position:absolute;
  z-index:2;
  bottom:-40px;
  right:50%;
  width:50px;
  height:30px;
  border-style:solid;
  border-width:0 10px 10px 0;
  border-color:#f3961c;
  margin-right:-10px;
  background:transparent;
  /* css3 */
  -webkit-border-bottom-right-radius:80px 50px;
  -moz-border-radius-bottomright:80px 50px;
  border-bottom-right-radius:80px 50px;
  /* reduce the damage in FF3.0 */
  display:block;
}

/* creates smaller curve */
.oval-speech-border:after {
  content:"";
  position:absolute;
  z-index:2;
  bottom:-40px;
  right:50%;
  width:20px;
  height:31px;
  border-style:solid;
  border-width:0 10px 10px 0;
  border-color:#f3961c;
  margin-right:20px;
  background:transparent;
  /* css3 */
  -webkit-border-bottom-right-radius:40px 50px;
  -moz-border-radius-bottomright:40px 50px;
  border-bottom-right-radius:40px 50px;
  /* reduce the damage in FF3.0 */
  display:block;
}

/* creates a small circle to produce a rounded point where the two curves meet */
.oval-speech-border > :first-child:before {
  content:"";
  position:absolute;
  z-index:1;
  bottom:-40px;
  right:50%;
  width:10px;
  height:10px;
  margin-right:45px;
  background:#f3961c;
  /* css3 */
  -webkit-border-radius:10px;
  -moz-border-radius:10px;
  border-radius:10px;
}

/* creates a white rectangle to cover part of the oval border*/
.oval-speech-border > :first-child:after {
  content:"";
  position:absolute;
  z-index:1;
  bottom:-10px;
  right:50%;
  width:30px;
  height:15px;
  background:#fff;
}

/* ============================================================================================================================
== OVER THOUGHT BUBBLE, EMPTY, WITH BORDER (more CSS3)
** ============================================================================================================================ */

.oval-thought-border {
  position:relative;
  padding:70px 30px;
  margin:1em auto 80px;
  border:10px solid #c81e2b;
  text-align:center;
  color:#333;
  background:#fff;
  /* css3 */
  /*
  NOTES:
  -webkit-border-radius:240px 140px; // produces oval in safari 4 and chrome 4
  -webkit-border-radius:240px / 140px; // produces oval in chrome 4 (again!) but not supported in safari 4
  Not correct application of the current spec, therefore, using longhand to avoid future problems with webkit corrects this
  */
  -webkit-border-top-left-radius:240px 140px;
  -webkit-border-top-right-radius:240px 140px;
  -webkit-border-bottom-right-radius:240px 140px;
  -webkit-border-bottom-left-radius:240px 140px;
  -moz-border-radius:240px / 140px;
  border-radius:240px / 140px;
}

/* creates the larger circle */
.oval-thought-border:before {
  content:"";
  position:absolute;
  z-index:10;
  bottom:-40px;
  right:100px;
  width:50px;
  height:50px;
  border:10px solid #c81e2b;
  background:#fff;
  /* css3 */
  -webkit-border-radius:50px;
  -moz-border-radius:50px;
  border-radius:50px;
  /* reduce the damage in FF3.0 */
  display:block;
}

/* creates the smaller circle */
.oval-thought-border:after {
  content:"";
  position:absolute;
  z-index:10;
  bottom:-60px;
  right:50px;
  width:25px;
  height:25px;
  border:10px solid #c81e2b;
  background:#fff;
  /* css3 */
  -webkit-border-radius:25px;
  -moz-border-radius:25px;
  border-radius:25px;
  /* reduce the damage in FF3.0 */
  display:block;
}