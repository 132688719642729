@import "color";
@import "mixins";
@import "variables";
@import "filament";

/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 *
 * You can see the styles here:
 * https://github.com/tailwindcss/tailwindcss/blob/master/css/preflight.css
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/preflight";
 */
@tailwind base;

/**
 * This injects any component classes registered by plugins.
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/components";
 */
@tailwind components;

/**
 * Here you would add any of your custom component classes; stuff that you'd
 * want loaded *before* the utilities so that the utilities could still
 * override them.
 *
 * Example:
 *
 * .btn { ... }
 * .form-input { ... }
 *
 * Or if using a preprocessor or `postcss-import`:
 *
 * @import "components/buttons";
 * @import "components/forms";
 */

/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/utilities";
 */
@tailwind utilities;

/**
 * Here you would add any custom utilities you need that don't come out of the
 * box with Tailwind.
 *
 * Example :
 *
 * .bg-pattern-graph-paper { ... }
 * .skew-45 { ... }
 *
 * Or if using a preprocessor or `postcss-import`:
 *
 * @import "utilities/background-patterns";
 * @import "utilities/skew-transforms";
 */

@layer base {
    .fi-select-input {
        @apply form-select;
    }

    .fi-input {
        @apply form-input;
    }

    .fi-textarea {
        @apply form-textarea;
    }

    .fi-radio-input {
        @apply form-radio;
    }

    .fi-checkbox-input {
        @apply form-checkbox;
    }

    /* Dependencies */
    @import '~codemirror/lib/codemirror.css';
    @import '~jquery-ui/themes/base/all.css';
    @import '~bootstrap/dist/css/bootstrap.min.css';
    @import '../css/bootstrap-social.css';
    @import '../css/hihaho-icons.css';
    @import '../css/glyphicons-social.css';
    @import '../css/external/highlight.visualstudio.css';
    @import '~bootstrap-tourist/bootstrap-tourist.css';

    /* Global css */
    @import 'global/alerts';
    @import 'global/breadcrumbs';
    @import 'global/file-upload';
    @import 'global/fullscreen-loader';
    @import 'global/global';
    @import 'global/glyphicons';
    @import 'global/glyphicons-animations';
    @import 'global/onoffswitch';
    @import 'global/text-editor';

    @import '../css/bootstrap-hihaho.css';
}

@layer utilities {
    @import "master/forms";

    .tw-hidden {
        display: none;
    }
}

@import 'global/facebook-tags';

/* Content for the master template*/

/*html*/
@-ms-viewport {
    width: device-width;
}

[v-cloak], [x-cloak] {
    display: none !important;
}

html, body {
    height: 100%;
}

body {
    background-color: #FFF;
    margin: 0;
    font-family: Roboto, Arial, sans-serif;

    &.player, &.iframed {
        background: none !important;
        overflow: hidden;
    }

    > .container {
        min-height: 100%;
        height: auto !important;
        height: 100%;
        margin: 0 auto -183px;

        &.videoPage {
            margin-bottom: 50px;
            min-height: 1px;
        }
    }
}

#body {
    position: relative;
}

/*Footer*/
footer .whiteContent {
    padding-top: 0;
}

.pushfooter, .video-footer {
    height: 50px;
}

#footer {
    clear: both;
    padding-bottom: 25px;
    background-color: $deep-blue-800;
    color: $hihaho-white;

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    .container {
        position: relative;
        padding-top: 40px;
    }

    h4 {
        font-weight: bold;
        padding-bottom: 20px;
    }

    hr {
        border-color: $hihaho-primary;
    }

    address {
        margin-bottom: 5px;
        line-height: 30px;
        padding-top: 5px;
    }

    a {
        color: $hihaho-white;
    }

    .nav > li {
        > a {
            padding: 10px 0;
        }

        > .horizontal {
            padding: 10px 15px;
            font-size: 1.5em;
        }
    }

    .centered {
        display: flex;
        align-content: center;
        align-items: center;
    }

    .pink-hover > :hover,
    .nav > li > a:hover,
    .nav > li > a:focus {
        color: $hihaho-pink;
        text-decoration: none;
        background-color: transparent;
    }

    .social-icon {
        margin-right: 10px;
    }

    hr.light_grey {
        border-color: $sky-blue-200;
    }
}

/* Bootstrap modal overrides */
.modal {
    overflow: auto;
    overflow-y: scroll;
}

.modal-open {
    overflow: hidden;
}

.modal-content {
    border: 1px solid #0003;
    box-shadow: 0 3px 9px #00000080;
    background-clip: padding-box;
    outline: none;
}

.modal-header {
    background-color: $deep-blue-800;
    min-height: 16px;
    border-radius: 4px 4px 0 0;
    color: #FFF;

    .close {
        color: #FFF;

        &:not(:hover) {
            opacity: .8;
        }
    }

    .modal-title {
        font-weight: 700;
        padding-right: 27px;
    }
}

.modal-body {
    padding: 20px;
}

.modal-footer {
    margin-top: 15px;
    padding: 19px 20px 20px;
}

.modal-backdrop {
    background-color: #CEE7FE;

    &.in {
        opacity: .8;
    }
}

.progress-bar {
    background-color: #418ac9;
}

.progress-bar-primary {
    background-color: $deep-blue-600;
}

.progress-bar-success {
    background-color: $green-500;
}

.progress-bar-info {
    background-color: #aad;
}

.progress-bar-warning {
    background-color: $warning-500;
}

.progress-bar-danger {
    background-color: #FF6276;
}

/* Menu bar*/
.nav {
    .open {
        > a, > a:hover, > a:focus {
            border-color: $primary300;
            border-radius: 0;
            background-color: $primary300;
        }
    }

    > li {
        > a {
            &:focus, &:hover {
                background-color: $primary900;
            }
        }
    }
}

.dropdown-menu > li > a {
    &:hover, &:focus {
        background-color: $primary300;
        color: #FFF;
    }
}

.navbar {
    border-radius: 0;
    @include break($screen-xs-min, $screen-md-max) {
        .container {
            padding-left: 15px;
        }
    }
}

.navbar-brand {
    height: auto;
    padding: 8px 5px 5px;
    color: white;
    font-weight: bold;
    font-size: 28px;

    .logo {
        height: 30px;
    }

    &:hover {
        color: rgb(209, 223, 236);
    }

    @media (min-width: 769px) {
        .navbar-brand {
            margin-left: 0 !important;
        }
    }

    @media (max-width: 768px) {
        margin-left: 15px;
    }
}

// Fix for Bootstrap 3 in combination with Tailwind 3
.collapse {
    &.in, &.navbar-collapse {
        visibility: visible;
    }
}

.header .menu {
    .navbar-collapse.collapse.in li a,
    .navbar-collapse.collapsing li a,
    .navbar-nav > li > a {
        color: #FFF;
        font-weight: bold !important;

        &:hover, &:focus {
            color: $sky-blue-400;
        }
    }
}

.navbar-nav > .active > a {
    color: $sky-blue-400 !important;
    background-color: $deep-blue-800;
}

@media (max-width: 768px) {
    .navbar-toggle {
        margin-right: 22px;
        margin-top: 0;
    }
    .navbar-toggle .icon-bar {
        background-color: #FFF;
    }
    .navbar-collapse {
        max-height: none;
    }
}

.navbar {
    .branch {
        font-size: 11px;
        font-weight: bold;
        position: absolute;
        bottom: -11px;
        pointer-events: none;
        left: -45px;
        padding: 0 10px;
    }
}

@media all and (orientation: landscape) and (max-width: 992px) {
    /* Styles for Landscape screen */
    .VideoContainer {
        width: auto !important;
        height: auto !important;
        max-width: 100% !important;
        max-height: 100% !important;
    }
}

.container {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    @include break($screen-lg-min) {
        min-width: 900px;
        max-width: 1170px;
        margin-left: auto;
        margin-right: auto;
    }
}

.whiteContent {
    background-color: #FFF;
    width: 100%;
    padding: 0 15px;

    @media (max-width: $screen-md-max) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        overflow: hidden;

        body:not(.enrich):not(.register-education) &.whiteContentPadding {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }
    }

    body.video-settings #app & {
        padding: 0 !important;

        #navigation-steps-container {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

body.video-settings #container.container-fluid {
    padding: 0;
}

.user-dropdown .dropdown-menu {
    z-index: 100000;
}

.container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse {
    margin-right: 0;
}

.container > .navbar-header {
    position: relative;
}

.min-width-140 {
    &.col-sm-2, &.col-lg-2 {
        min-width: 140px;
    }
}

/* User page video list */
.video-small {
    @apply relative rounded-md text-deep-blue-800 flex items-center border-1 border-deep-blue-300;

    &.ui-selected,
    &.selected {
        @apply bg-deep-blue-800 text-white;
    }

    &.selectable {
        @apply cursor-pointer;
    }

    .videos.max-selected & {
        .video-small.selectable:not(.selected) {
            @apply cursor-default;
        }
    }

    .img-container {
        @apply bg-deep-blue-800 text-white text-xs;
        &.image-loading {
            img {
                @apply hidden;
            }

            .image-error {
                @apply block p-2 text-xs;
                p {
                    @apply m-0 text-xs;
                }
            }
        }

        img {
            @apply object-cover bg-deep-blue-800 max-w-none;
            .grid-cols-3 & {
                @apply aspect-ratio-video;
            }
        }
    }

    .videoinfo {
        @apply p-2 -mt-0.5 min-w-0 max-w-full;
        .title {
            @apply mt-0 mb-0.5 p-0 whitespace-nowrap truncate max-w-full text-base font-bold;
        }
    }

    .created-at {
        @apply text-xs text-gray-400;
    }

    .duration {
        @apply absolute right-0 bottom-0 rounded-tl-md py-1 px-2 bg-deep-blue-800 text-white font-bold text-xs;
    }

    &.image-only {
        .img-container {
            @apply w-full rounded-md aspect-ratio-video ;
            .image-error,
            img {
                @apply w-full rounded-md;
            }
        }

        .videoinfo {
            .title,
            .description,
            .created-at {
                @apply hidden;
            }
        }
    }

    &.image-left {
        @apply gap-2;
        .img-container {
            @apply rounded-l-md self-stretch aspect-ratio-16-10;
            .image-error,
            img {
                @apply w-40 rounded-l-md;
            }
        }
    }

    &.image-top {
        @apply flex-col gap-1;
        .img-container {
            @apply rounded-t-md aspect-ratio-video w-full;
            &.image-error {
                @apply p-2;
            }

            &.image-error,
            img {
                @apply w-full rounded-t-md max-w-none;
            }
        }

        .videoinfo {
            @apply p-0.5;
            .description,
            .created-at {
                @apply hidden;
            }

            .title {
                @apply text-xs font-normal;
            }
        }
    }

    .video-small-title {
        @apply
        self-start
        pt-0.5
        px-3
        pb-1.5
        text-xs
        leading-4;
    }
}

.interaction-promotion-label {
    position: absolute;
    right: 20px;
    top: 18px;
    z-index: 1;
    overflow: hidden;
    width: 32px;
    height: 32px;
    pointer-events: none;

    &.in-pop-up {
        position: absolute;
        right: 60px;
        top: 66px;
        width: 44px;
        height: 44px;
    }

    &.in-pop-up .new-label {
        .st0 {
            fill: #196542;
        }

        .st1 {
            fill: #ffffff;
        }
    }

    &.in-pop-up .beta-label {
        .st0 {
            fill: #327780;
        }

        .st1 {
            fill: #ffffff;
        }
    }
}

.ribbon.select-menu-type {
    right: 11px;
}

.nav-tabs.nav-justified.editor-tabs > li {

    + li > a {
        border-left: 0;
    }

    > a {
        border: 2px solid $deep-blue-800;
        color: $deep-blue-800;
        font-weight: bold;

        &:hover {
            border-color: $deep-blue-800;
            background-color: $deep-blue-100;
        }
    }

    &.active > a {
        border: 2px solid $deep-blue-800;
        color: $hihaho-white;
        background-color: $deep-blue-800;

        &:hover, &:focus {
            border: 2px solid $deep-blue-800;
            color: $hihaho-white;
            background-color: $deep-blue-800;
        }
    }

    > a {
        border-radius: 0;
    }

    &:first-of-type > a {
        border-radius: 12px 0 0 12px;
    }

    &:last-of-type > a {
        border-radius: 0 12px 12px 0;
    }
}

.nav-tabs.nav-justified > li > a {
    border-bottom-color: $sky-blue-800;
    color: $sky-blue-800;
    font-weight: bold;
}

.nav-tabs.nav-justified > li > a:hover {
    border-color: $deep-blue-100 $deep-blue-100 $sky-blue-800;
    background-color: $deep-blue-100;
}

.nav-tabs.nav-justified > li.active > a,
.nav-tabs.nav-justified > li.active > a:hover,
.nav-tabs.nav-justified > li.active > a:focus {
    color: #FFFFFF;
    background-color: $sky-blue-800;
    border: 1px solid $sky-blue-800;
    border-bottom-color: transparent;
    cursor: default;
    font-weight: bold;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: #dbdbdb;
}

.form-control[readonly]:not([disabled]),
.form-control {
    color: $deep-blue-800;
    background-color: #FFFFFF;
    border-color: $deep-blue-200;

    &:focus {
        border-color: $sky-blue-400;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba($sky-blue-400, 0.6);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba($sky-blue-400, 0.6);
    }

    &:-ms-input-placeholder, &::-webkit-input-placeholder, &::-moz-placeholder {
        color: $sky-blue-600;
        opacity: 1;
    }
}

.dropdown-menu {
    > li {
        > a {
            color: $deep-blue-800;

            &:hover, &:focus {
                color: $deep-blue-800;
                background-color: $deep-blue-200;
            }

            &.activated {
                color: #FFFFFF;
                background-color: $sky-blue-800;
            }
        }

        &.active {
            > a {
                &, &:hover, &:focus {
                    color: #FFFFFF !important;
                    background-color: $sky-blue-800;
                }
            }
        }
    }
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
    color: #FFFFFF;
    background-color: $deep-blue-800;
    border-bottom-color: $deep-blue-800;
}

.label-primary {
    background-color: $deep-blue-600;
    color: #FFFFFF;
}

.label-success {
    background-color: $green-500;

    &[href]:hover,
    &[href]:focus {
        background-color: $green-500;
    }
}

.input-group .form-control {
    border-color: $sky-blue-800;
}

.btn-success {
    background-color: $green-500;
    border-color: $green-500;

    &:hover {
        color: $green-500;
        background-color: white;
    }
}

a {
    color: $deep-blue-800;
}

/* Dashboard pagination styling */
ul.pagination {
    border-radius: 22px;
    box-shadow: 0 2px 10px 2px $deep-blue-100;
    padding: 0 7px;

    li {
        /* Span for Ellipses */
        > span {
            border-color: transparent;
            margin: 8px 4px;

            &:hover {
                border-color: transparent;
                background-color: transparent;
            }
        }

        &.disabled {
            span {
                border: none;
                opacity: 0.7;
                color: #B7B7B7;
            }
        }

        a {
            color: $deep-blue-800;
            border-color: transparent;
            margin: 8px 4px;
            outline: none;

            body &, #app & {
                border-radius: 32px;
            }

            &:hover,
            &:focus {
                background-color: $deep-blue-300;
                border-color: $deep-blue-300;
                color: $hihaho-white;
            }

            &.next-page,
            &.last-page,
            &.first-page,
            &.previous-page {
                background-color: transparent;
                border-color: transparent;
                color: $deep-blue-300;

                &:hover,
                &:focus,
                &:active,
                &:active:focus,
                &:active:hover {
                    background-color: transparent;
                    border-color: transparent;
                    color: $deep-blue-800;
                }
            }
        }

        &.active {
            a:not(.first-page):not(.last-page) {
                background-color: $deep-blue-800;
                border-color: $deep-blue-800;
                color: $hihaho-white;

                &:hover,
                &:focus {
                    background-color: $deep-blue-800;
                    border-color: $deep-blue-800;
                    color: $hihaho-white;
                }
            }
        }
    }
}

/* Datatables paginator specific styling */
.dataTables_paginate {
    .pagination {
        box-shadow: none;

        li.paginate_button.next,
        li.paginate_button.previous {
            a {
                background-color: transparent;
                border-color: transparent;
                color: $deep-blue-300;

                &:hover,
                &:focus,
                &:active,
                &:active:focus,
                &:active:hover {
                    background-color: transparent;
                    border-color: transparent;
                    color: $deep-blue-800;
                }
            }
        }
    }
}

body .btn {
    &:focus,
    &:active:focus {
        outline: none;
    }
}

.btn.btn-default {
    color: $sky-blue-800;
    background-color: #FFF;
    border-color: $sky-blue-800;

    &:hover,
    &:focus,
    &:active,
    &.active.focus,
    &.active:focus,
    &.active:hover,
    &:active.focus,
    &:active:focus,
    &:active:hover,
    &.active,
    .open > &.dropdown-toggle,
    .open > &.dropdown-toggle.focus,
    .open > &.dropdown-toggle:focus,
    .open > &.dropdown-toggle:hover {
        color: #FFFFFF;
        background-color: $sky-blue-800;
        border-color: $sky-blue-800;
        outline: none;
    }
}

.btn.btn-primary {
    color: #ffffff;
    background-color: $green-500;
    border-color: $green-500;

    &:hover,
    &:focus,
    .open > &.dropdown-toggle,
    .open > &.dropdown-toggle.focus,
    .open > &.dropdown-toggle:focus,
    .open > &.dropdown-toggle:hover {
        color: $green-500;
        background-color: #FFFFFF;
        border-color: $green-500;
        outline: none;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] &,
    &.disabled:hover,
    &[disabled]:hover,
    fieldset[disabled] &:hover,
    &.disabled:focus,
    &[disabled]:focus,
    fieldset[disabled] &:focus,
    &.disabled:active,
    &[disabled]:active,
    fieldset[disabled] &:active,
    &.disabled.active,
    &[disabled].active,
    fieldset[disabled] &.active {
        color: $hihaho-white;
        background-color: $green-300;
        border-color: $green-300;
    }
}

.btn.btn-login {
    color: $deep-blue-800;
    background-color: $hihaho-white;
    border-color: $deep-blue-800;

    &:hover,
    &:focus,
    &:active,
    &.active.focus,
    &.active:focus,
    &.active:hover,
    &:active.focus,
    &:active:focus,
    &:active:hover,
    &.active {
        color: $hihaho-white;
        background-color: $deep-blue-800;
        border-color: $deep-blue-800;
        outline: none;
    }
}

.btn.btn-cta {
    color: $hihaho-white;
    border-color: $cta-500;
    background-color: $cta-500;
    padding-left: 25px;
    padding-right: 25px;

    &:hover,
    &:focus,
    &:active,
    &:active:focus,
    &:active:hover {
        color: $hihaho-white;
        background-color: $cta-600;
        border-color: $cta-600;
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: none;
        transition: 0.5s;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] &,
    &.disabled:hover,
    &[disabled]:hover,
    fieldset[disabled] &:hover,
    &.disabled:focus,
    &[disabled]:focus,
    fieldset[disabled] &:focus,
    &.disabled:active,
    &[disabled]:active,
    fieldset[disabled] &:active,
    &.disabled.active,
    &[disabled].active,
    fieldset[disabled] &.active {
        border-color: $cta-400;
        background-color: $cta-400;
    }
}

.btn.cta-invert {
    color: $cta-500;
    background-color: $hihaho-white;
    border-color: $cta-500;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    padding-left: 25px;
    padding-right: 25px;

    &:hover,
    &:focus,
    &:active {
        color: $hihaho-white;
        background-color: $cta-600;
        border-color: $cta-600;
        transition: 0.5s;
    }
}

.btn.btn-skb {
    color: #FFFFFF;
    border-color: $sky-blue-400;
    background-color: $sky-blue-400;

    &:hover,
    &:focus,
    &:active,
    &:active:focus,
    &:active:hover {
        color: $sky-blue-400;
        background-color: #FFFFFF;
        border-color: $sky-blue-400;
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: none;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] &,
    &.disabled:hover,
    &[disabled]:hover,
    fieldset[disabled] &:hover,
    &.disabled:focus,
    &[disabled]:focus,
    fieldset[disabled] &:focus,
    &.disabled:active,
    &[disabled]:active,
    fieldset[disabled] &:active,
    &.disabled.active,
    &[disabled].active,
    fieldset[disabled] &.active {
        border-color: $sky-blue-400;
        background-color: $sky-blue-400;
    }
}

.btn.btn-dpb {
    color: #FFFFFF;
    border-color: $deep-blue-800;
    background-color: $deep-blue-800;

    &:hover,
    &:focus,
    &:active,
    &:active:focus,
    &:active:hover {
        color: $deep-blue-800;
        background-color: #FFFFFF;
        border-color: $deep-blue-800;
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: none;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] &,
    &.disabled:hover,
    &[disabled]:hover,
    fieldset[disabled] &:hover,
    &.disabled:focus,
    &[disabled]:focus,
    fieldset[disabled] &:focus,
    &.disabled:active,
    &[disabled]:active,
    fieldset[disabled] &:active,
    &.disabled.active,
    &[disabled].active,
    fieldset[disabled] &.active {
        border-color: $deep-blue-800;
        background-color: $deep-blue-800;
    }
}

.btn.btn-subtle-blue {
    color: $deep-blue-400;
    background-color: transparent;
    border-color: $deep-blue-400;

    &:hover,
    &:focus,
    &:active {
        color: $deep-blue-200;
        background-color: $deep-blue-600;
        border-color: $deep-blue-600;
        transition: 0.5s;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] &,
    &.disabled:hover,
    &[disabled]:hover,
    fieldset[disabled] &:hover,
    &.disabled:focus,
    &[disabled]:focus,
    fieldset[disabled] &:focus,
    &.disabled:active,
    &[disabled]:active,
    fieldset[disabled] &:active,
    &.disabled.active,
    &[disabled].active,
    fieldset[disabled] &.active {
        background-color: $deep-blue-600;
        border-color: $deep-blue-600;
    }
}

.btn.btn-frg {
    color: #FFFFFF;
    border-color: $green-500;
    background-color: $green-500;

    &:hover,
    &:focus,
    &:active,
    &:active:focus,
    &:active:hover {
        color: $green-500;
        background-color: #FFFFFF;
        border-color: $green-500;
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: none;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] &,
    &.disabled:hover,
    &[disabled]:hover,
    fieldset[disabled] &:hover,
    &.disabled:focus,
    &[disabled]:focus,
    fieldset[disabled] &:focus,
    &.disabled:active,
    &[disabled]:active,
    fieldset[disabled] &:active,
    &.disabled.active,
    &[disabled].active,
    fieldset[disabled] &.active {
        border-color: $green-500;
        background-color: $green-500;
    }
}

.btn.btn-danger {
    color: #fff;
    background-color: #FF6276;
    border-color: #FF6276;

    &:active,
    &:active.focus,
    &.active.focus,
    &:active:hover,
    &.active {
        color: #FF6276;
        background-color: #FFF;
        border-color: #FF6276;
    }

    &:hover,
    &:focus,
    .open > &.dropdown-toggle,
    .open > &.dropdown-toggle.focus,
    .open > &.dropdown-toggle:focus,
    .open > &.dropdown-toggle:hover {
        color: #FF6276;
        background-color: #FFF;
        border-color: #FF6276;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] &,
    &.disabled:hover,
    &[disabled]:hover,
    fieldset[disabled] &:hover,
    &.disabled:focus,
    &[disabled]:focus,
    fieldset[disabled] &:focus,
    &.disabled:active,
    &[disabled]:active,
    fieldset[disabled] &:active,
    &.disabled.active,
    &[disabled].active,
    fieldset[disabled] &.active {
        background-color: #FFB4BE;
        border: 1px solid #FFB4BE;
    }

    &:active,
    &.active,
    .open &.dropdown-toggle {
        background-image: none;
    }
}

.btn.btn-warning {
    background-color: $text-orange-200;
    border-color: $text-orange-500;
    color: $text-orange-900;

    &:active,
    &:active.focus,
    &.active.focus,
    &:active:hover,
    &.active {
        color: $text-orange-900;
        background-color: #FFFFFF;
        border-color: $text-orange-900;
    }

    &:hover,
    &:focus,
    .open > &.dropdown-toggle,
    .open > &.dropdown-toggle.focus,
    .open > &.dropdown-toggle:focus,
    .open > &.dropdown-toggle:hover {
        color: $text-orange-900;
        background-color: #FFFFFF;
        border-color: $text-orange-900;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] &,
    &.disabled:hover,
    &[disabled]:hover,
    fieldset[disabled] &:hover,
    &.disabled:focus,
    &[disabled]:focus,
    fieldset[disabled] &:focus,
    &.disabled:active,
    &[disabled]:active,
    fieldset[disabled] &:active,
    &.disabled.active,
    &[disabled].active,
    fieldset[disabled] &.active {
        background-color: #F1B553;
        border: none;
    }

    &:active,
    &.active,
    .open &.dropdown-toggle {
        background-image: none;
    }
}

.btn-rounded {
    border-radius: 25px;
}

.alert-info {
    background-color: $sky-blue-50;
    border-color: $sky-blue-400;
    color: $deep-blue-950;
}

.alert-warning {
    background-color: $text-orange-200;
    border-color: $text-orange-500;
    color: $text-orange-900;
}

.alert-danger {
    background-color: $text-red-200;
    border-color: $text-red-500;
    color: $text-red-900;
}

.alert-success {
    background-color: $green-100;
    border-color: $green-600;
    color: $green-700;
}

.blueitem {
    background: none !important;
}

.glyphicons.question_sign {
    color: $sky-blue-400 !important;
}

.input-group-addon {
    color: $sky-blue-800;
    border-color: $sky-blue-800;
}

.tab-pane .glyphicons.info_sign {
    margin-top: -.02rem;
    font-size: 14px;
    vertical-align: middle;
}

.vue-modal {
    overflow: hidden;
    z-index: 1041;
    display: flex;
    align-items: center;
    font-family: 'Nunito Sans', sans-serif;
    pointer-events: none;

    &.in {
        pointer-events: auto;
    }

    .modal-mask {
        position: absolute;
        z-index: 18;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        transition: opacity 0.3s ease;
    }

    .modal-dialog {
        z-index: 20;
    }
}

.video-purchase-information {
    .open-purchase-modal-btn {
        border-width: 2px;

        &:hover,
        &:focus,
        &:active {
            color: $hihaho-white;
        }
    }
}

#trialEndedModal {
    .modal-content {
        background-color: $deep-blue-800;
        background-image: url('/css/images/backgrounds/pop_up_background.svg');
        background-position: center;
        background-size: 120%;
        background-repeat: no-repeat;
        border-radius: 25px;
        color: $hihaho-white;
        padding: 15px;
        font-size: 1.5em;
    }

    .modal-header {
        border-width: 0;
        background-color: transparent;
        padding: 20px 20px 0;
    }

    .modal-body {

        .font-bold {
            font-weight: bold;
        }

        p {
            padding-top: 10px;
        }
    }

    .modal-footer {
        border-width: 0;
        margin-top: 0;
    }
}

#purchaseVideosModal {
    font-size: 16px;
    @media (max-width: 1700px) {
        font-size: 14px;
    }

    .font-bold {
        font-weight: bold;
    }

    .price-details {
        font-size: 0.85em;
        color: $sky-blue-400;
    }

    .mt-60 {
        margin-top: 60px;
    }

    .modal-dialog {
        width: 80%;
        background: white;
        border-radius: 25px;
    }

    .modal-content {
        background: transparent;
        border-radius: 20px;

        &::after {
            content: "";
            background-image: url('/css/images/backgrounds/select_videos_background._white.svg');
            background-repeat: no-repeat;
            opacity: 0.4;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            z-index: -1;
        }

        #purchaseVideosTitle {
            font-family: 'Nunito', sans-serif;
            font-weight: bold;
            margin-bottom: 20px;
            @media (max-width: 1700px) {
                margin-bottom: 10px;
            }
        }

        .trialEndedInfo {
            background-color: $sky-blue-50;
            border-radius: 15px;

            color: black;
            margin-bottom: 40px;
            @media (max-width: 1700px) {
                margin-bottom: 20px;
            }
            font-size: 1.35em;

            p {
                margin: 0;
                padding: 10px 9% 10px 5%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .trialEndedIcon {
                color: $hihaho-pink;
                margin-right: 15px;
                font-size: 1.3em;
            }
        }
    }

    .modal-header {
        border-width: 0;
        background-color: transparent;

        button.close {
            color: $sky-blue-800;
        }
    }

    .modal-body {
        @media (max-width: 1700px) {
            padding-top: 0;
        }

        h2 {
            color: $deep-blue-800;
        }

        .price-display {
            font-size: 2.7em;
            font-weight: bold;
            font-family: Roboto, Arial, sans-serif;
            line-height: 1.1;

            &:first-child {
                margin-top: 8px;
            }

            &.discounted {
                position: relative;
                display: inline-block;
                color: $pink-300;
                font-size: 1.5em;

                &::before {
                    content: '';
                    width: 100%;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    border-bottom: 2px solid $pink-600;
                    -webkit-transform: skewY(-15deg);
                    transform: skewY(-20deg);
                }
            }

            .price-tooltip {
                &::after {
                    content: attr(data-custom-tooltip);
                    position: absolute;
                    top: 50%;
                    @include translateY();
                    font-size: 0.7rem;
                    border: 1px solid #999;
                    padding: 5px 6px;
                    border-radius: 4px;
                    white-space: nowrap;
                    margin-left: 9px;
                    color: #ddd;
                }
            }
        }

        .contact-for-enterprise {
            padding-top: 15px;
        }

        .planRequirements {
            background-color: $deep-blue-800;
            background-image: url('images/backgrounds/select_videos_background.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            border-radius: 25px;
            color: $hihaho-white;
            box-shadow: 3px 4px 12px 1px rgba($hihaho-dark-blue, 0.45);
            padding: 25px 0;

            @media (max-width: 1700px) {
                padding: 15px 0;
            }

            h3 {
                margin: 60px 0 25px;
                @media (max-width: 1700px) {
                    margin: 40px 0 15px;
                }

                &:first-child {
                    margin-top: 35px;
                    @media (max-width: 1700px) {
                        margin-top: 20px;
                    }
                }
            }

            h4 {
                color: $deep-blue-300;
            }

            p {
                padding: 0 0 15px;
            }

            a:not(.btn) {
                color: inherit;
            }

            button.btn-cta {
                margin: 13px 0;
                @media (max-width: 1700px) {
                    margin: 10px 0;
                }
            }

            .price-details {
                color: white;
                font-size: 1.5em;

                span {
                    color: $sky-blue-400;
                }
            }

            .amount-videos-selection {
                display: flex;
                justify-content: center;

                button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-color: $hihaho-green;
                    border-width: 3px;
                    border-radius: 40px;
                    width: 100px;
                    height: 50px;
                    color: $hihaho-white;
                    font-size: 1.5em;
                    font-weight: bold;
                    margin: 0 20px;
                    opacity: 0.4;
                    background-color: transparent;
                    transition: 0s;

                    &:hover,
                    &:focus,
                    &:active {
                        background-color: transparent;
                        color: $hihaho-white;
                        outline: none;
                    }

                    &.planSelected {
                        opacity: 1;
                    }
                }
            }

            .slidecontainer {
                width: 60%;
                display: inline-block;
            }

            .currencyPicker {
                border-radius: 15px;
                color: $deep-blue-800;
                font-weight: bold;
                padding: 2px 5px 2px 15px;

                &:focus {
                    outline: none;
                }
            }
        }
    }

    .modal-footer {
        border-width: 0;
        margin-top: 0;
        padding-top: 0;

        .contact-for-enterprise {
            cursor: pointer;
        }

        .messages-send-success {
            color: $hihaho-green;
        }
    }
}

#afterPaymentResponseModal {
    .modal-dialog {
        display: flex;
        justify-content: center;
    }

    .modal-content {
        width: 80%;
        border-radius: 15px;
    }

    .modal-header {
        background: white;
        border: none;
        width: 100%;
        border-radius: 15px;
        padding: 0;

        button.close {
            position: absolute;
            top: 0;
            right: 0;
            padding: 12px 10px;
            color: $sky-blue-800;
        }

        .model-icon {
            display: flex;
            justify-content: center;
            position: absolute;
            top: -75px;
            width: 100%;

            .payment-feedback-icon {
                height: 150px;
            }
        }

        .modal-title {
            color: $hihaho-red;
            text-align: center;
            font-family: 'Nunito', sans-serif;
            font-weight: bold;
            padding-right: 0;
            margin-top: 82px;

            &.payment-success {
                color: $hihaho-green;
            }

            &.payment-pending {
                color: $hihaho-blue;
            }
        }
    }

    .modal-body {
        margin: 45px 20px 0 20px;

        .text-body {
            color: $deep-blue-950;
            font-size: 1.5em;
            text-align: center;
            font-weight: bold;
            padding-left: 15px;
        }

        .link-wrapper {
            display: flex;
            justify-content: center;
            margin-top: 46px;

            .btn-link {
                color: $deep-blue-800;
            }
        }
    }

    .modal-footer {
        border: none;
        display: flex;
        justify-content: center;
        padding: 0 20px 35px 20px;

        .btn-explore {
            background: white;
            border-color: $hihaho-pink;
            color: $hihaho-pink;
            width: 80%;

            &:hover {
                color: white;
                background: $hihaho-pink;
            }
        }
    }
}

#education-register-page {

    width: 100%;
    font-family: 'Nunito Sans', sans-serif;

    .highlight {
        color: $hihaho-pink;
    }

    .font-bold {
        font-weight: bold;
    }

    .btn {
        font-weight: normal;
    }

    .pb-15 {
        padding-bottom: 15px;
    }

    .no-padding-left {
        padding-left: 0;
    }

    #background-image {
        background-color: $deep-blue-800;
        background-image: url('/css/images/backgrounds/transpa_outlined_connector.svg');
        background-repeat: no-repeat;
        color: $hihaho-white;
        margin-bottom: 10px;

        h1 {
            padding-top: 10px;
            padding-bottom: 5px;
            font-size: 4vmin;
        }

        p {
            padding-bottom: 5px;
            padding-top: 5px;
        }

        #tips {
            margin-top: 10px;
            margin-bottom: 10px;
            background-color: $sky-blue-200;
            color: black;
            width: 100%;
            float: left;

            ul {
                list-style-type: none;
                padding-left: 0;
            }

            .m-10 {
                margin-top: 10px;
            }

            .info-bubble {
                background-color: $sky-blue-800;
                width: 280px;
                height: 100px;
                position: absolute;
                margin-left: 50px;
                color: $hihaho-white;
                border-radius: 20px;
                padding: 15px;

                a {
                    color: $hihaho-white;
                    position: relative;
                    z-index: 1;
                }
            }

        }

        #examples {
            padding-top: 20px;

            .m-20 {
                margin-top: 20px;
            }
        }

        h3 {
            font-size: 3vmin;
        }

        .terms-conditions {
            margin-top: 15px;

            a {
                color: $hihaho-white;

                i {
                    margin-right: 10px;
                }

                a:hover {
                    color: $sky-blue-300;
                }
            }

            .reverse-items {
                flex-direction: column-reverse;
                display: flex;
            }

            .in + a {
                color: $sky-blue-300;

                i {
                    transform: rotate(90deg);
                }
            }

        }
    }
}

input.ShareUrl[disabled] {
    color: #adadad;
    border-color: #bbbbbb;
}

.input-spacing {
    margin-bottom: 22px;
}

.loading-icon-pink {
    background-image: url('/img/icons/loading.svg');
    width: 200px;
    height: 200px;
    margin: 0 auto;
}

.liveInteraction.HotspotInteraction {
    overflow: visible;

    .placement-middle {
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%);
        pointer-events: none;

        .tooltip-arrow {
            display: none;
        }
    }
}

// Filament non-native dropdown options fall under the footer without this css
form .choices .choices__list--dropdown, .choices__list[aria-expanded] {
    z-index: 60;
}

@import './pages/index';
@import '~pretty-checkbox/src/pretty-checkbox.scss';
