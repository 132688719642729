@import "./player.color";
@import "../../mixins";
@import "../../variables";

.videoOverlay.chapter {
    z-index: map_get($player_layers, overlayChapter);
    pointer-events: none;

    .chapter-interaction {
        width: 40%;
        padding-right: 2%;
        padding-left: 2%;
        pointer-events: none;
    }

    body[data-orientation="portrait"] & {
        .chapter-interaction {
            width: 90%;
        }
    }

    .videoOverlayInner, .chapter-interaction .btn-wrapper {
        @include justify-content(flex-end);
    }

    &.left {
        .videoOverlayInner, .chapter-interaction .btn-wrapper {
            @include justify-content(flex-start);
        }
    }
    &.right {
        .videoOverlayInner, .chapter-interaction .btn-wrapper {
            @include justify-content(flex-end);
        }
    }
    &.center {
        &.collapsed.menu-layout {
            .videoOverlayInner, .chapter-interaction .btn-wrapper {
                @include justify-content(flex-end);
            }
        }

        .videoOverlayInner, .chapter-interaction .btn-wrapper {
            @include justify-content(center);
        }

        &:not(.menu-layout) .btn-expand {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .chapter-list {
            border-radius: 0 0 5px 5px;
        }
    }

    &.menu-layout {
        &:not(.collapsed) {
            .btn-wrapper {
                display: none;
            }
        }
        &.collapsed {
            .btn-wrapper {
                padding-top: 15px;
                padding-right: 2%;
                padding-left: 2%;

                .chapter-title {
                    font-size: calc(1.1 * var(--desktop-chapter-font-size, inherit));
                }
            }
        }

        .videoOverlayInner {
            @apply p-0;

            .chapter-interaction {
                width: 50%;
                @apply px-0;

                .chapter-list {
                    flex-grow: 1;
                    @apply py-6 px-0 rounded-none shadow-none;

                    .menu-layout-title {
                        display: flex;
                        font-size: calc(1.1 * 2.25rem); // text-4xl with the 10% modifier
                        @apply justify-between items-start px-6 py-2;

                        .chapter-title {
                            font-size: calc(1.1 * var(--desktop-chapter-font-size, inherit));
                            @apply font-bold items-center text-player-theme-menu-text-color;
                        }
                    }

                    .chapter-items {
                        @apply mx-6;

                        .chapter-item-wrapper {

                            .chapter-item {
                                display: flex;
                                @apply items-center justify-between;
                                @apply rounded-lg text-4xl px-4 py-2 mr-6 text-player-theme-menu-text-color;

                                &::before {
                                    @apply hidden;
                                }

                                .chapter-number-menu-layout {
                                    display: flex;
                                    flex-shrink: 0;
                                    @apply justify-center items-center w-16 h-16 rounded-md bg-player-theme-menu-item-hover-color text-player-theme-menu-text-hover-color;

                                    &::after {
                                        content: counter(chapterCount);
                                    }
                                }

                                &[data-disabled=false]:hover {
                                    @apply text-player-theme-menu-text-hover-color bg-player-theme-menu-item-hover-color
                                }

                                &[data-disabled=true] {
                                    .glyphicons.lock {
                                        @apply inline-block top-0;
                                    }

                                    .chapter-number-menu-layout {
                                        background-color: #6C6C6C;
                                    }
                                }

                                .chapter-text {
                                    flex-grow: 1;
                                    @apply pl-6 pr-2 truncate;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .btn-wrapper {
        flex: 0 0 auto;

        .btn-expand {
            display: flex;
            align-items: center;
            pointer-events: auto;
            border: 0;
            outline: 0;
            background-color: $player-primary;
            padding: .5em 1em;
            // @TODO - Needs a discussion about how to handle button size on small screens
            //padding: .8em 1.5rem;
            //@media (hover: none) and (pointer: coarse) and (max-width: 768px) {
            //    padding: .5em .8em;
            //    font-size: 2.9em;
            //}
            border-radius: #{map-get($default-border-radius, firstRadius)} #{map-get($default-border-radius, firstRadius)} 0 0 / #{map-get($default-border-radius, secondRadius)} #{map-get($default-border-radius, secondRadius)} 0 0;
            color: #ffffff;
            font-size: 25px;
            font-weight: bold;
            &.icon::before {
                display: block;
                content: '';
                font-size: 1em;
                width: 1.5em;
                height: 1.4em;
                margin-right: 5px;
                background-image: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 33 33' xmlns='http://www.w3.org/2000/svg'><path stroke='rgb(255, 255, 255)' stroke-width='4' stroke-linecap='square' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/></svg>");
                background-repeat: no-repeat;
                background-size: contain;

                .VideoContainer[data-text-direction="rtl"] & {
                    margin-right: 0;
                    margin-left: 1em;
                }
            }

            .chapter-title {
                font-size: var(--desktop-chapter-font-size, inherit);
            }

            &:hover {
                background-color: $player-primary-darken-5;
            }

            .caret {
                display: inline-block;
                width: 0;
                height: 0;
                margin-left: 1em;
                vertical-align: middle;
                border-top: 10px solid;
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;

                .VideoContainer[data-text-direction="rtl"] & {
                    margin-left: 0;
                    margin-right: 1em;
                }
            }
        }
    }

    .chapter-list {
        pointer-events: auto;
        counter-reset: chapterCount 0;
        border-radius: 5px 0 5px 5px;
        padding: 0.75em;
        background-color: rgb(236, 236, 236);
        box-shadow: 0 17px 29px 5px rgba(71,71,71,0.48);

        .menu-layout-title {
            display: none;
        }

        .chapter-items {
            overflow-y: auto;
        }

        .chapter-item-wrapper {
            &:not(:first-child) {
                margin-top: 0.25em;
            }

            .chapter-item {
                padding: 3px 10px;
                color: #5f5d5d;
                font-size: 25px;
                cursor: pointer;
                border-radius: 5px;
                position: relative;
                counter-increment: chapterCount;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                // @TODO - Needs a discussion about how to handle button size on small screens
                //@media (hover: none) and (pointer: coarse) and (max-width: 768px) {
                //    padding-top: .5em;
                //    padding-bottom: .5em;
                //}
                &:before {
                    content: counter(chapterCount) ".";
                    position: absolute;
                    font-weight: bold;
                    color: $player-primary;
                    font-size: var(--desktop-chapter-font-size, inherit);
                }

                .chapter-number-menu-layout {
                    @apply hidden;
                }

                &:hover {
                    background-color: $player-primary;
                    color: #D8D8D8;
                    &:before {
                        color: white;
                    }
                }

                &.active {
                    background-color: $player-primary;
                    color: white;

                    &:before {
                        color: white;
                    }
                }

                .chapter-text {
                    display: inline;
                    padding-left: 1.75em;
                    font-size: var(--desktop-chapter-font-size, inherit);

                    .VideoContainer[data-text-direction="rtl"] & {
                        padding-left: 0;
                        padding-right: 1.75em;
                    }

                    &.mobile {
                        display: none;
                    }
                }

                &[data-disabled=true] {
                    cursor: default;
                    background-color: #D8D8D8;
                    color: #B7B7B7;

                    &:before {
                        color: $player-primary-lighten-20;
                    }
                }

                .glyphicons.lock {
                    display: none;
                }
            }
        }
    }

    &.collapsed {
        .chapter-list {
            display: none;
        }

        .btn-expand {
            display: flex;
            align-items: center;
            border-radius: #{map-get($default-border-radius, firstRadius)} / #{map-get($default-border-radius, secondRadius)};
            .caret {
                content: '';
                border-top: 0;
                border-bottom: 10px solid;
                margin-left: 1em;
            }
        }
    }

    &.mobile:not(.menu-layout) {
        .btn-wrapper .btn-expand .chapter-title {
            font-size: var(--mobile-chapter-font-size, inherit);
        }

        .chapter-list {
            .chapter-items {
                flex-direction: row;
                flex-wrap: wrap;
                overflow-x: hidden;
                margin-top: -0.75rem;
                margin-left: -0.75rem;
            }

            .chapter-item-wrapper {
                margin-top: 0.75rem;
                width: 33.33333%;
                padding-left: 0.75rem;

                .chapter-item {
                    height: 8.5rem;
                    padding-top: 1rem;
                    border: 1.5px solid #c5cfd8;
                    white-space: normal;

                    &:before {
                        width: calc(100% - 20px);
                        text-align: center;
                        font-size: 35px;
                        content: counter(chapterCount);
                    }

                    &:hover {
                        border-color: #043348;
                    }

                    &.active {
                        border-color: $player-primary;
                    }

                    &.disabled {
                        border-color: #D8D8D8;
                    }

                    .chapter-text {
                        display: none;

                        &.mobile {
                            display: block;
                            display: -webkit-box;
                            padding-left: 0;
                            padding-top: 3.2rem;
                            text-align: center;
                            overflow-wrap: break-word;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: var(--mobile-chapter-font-size, 1.2rem);
                            font-weight: var(--font-weight, 700);
                            max-height: 8.5rem;
                            line-height: 1.5rem;
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}
