@import "../../variables";
@import "../../color";
@import "../../mixins";

#select-page {
    @media (min-width: 992px) {
        &[data-active-provider="upload"] {
            .tab-content {
                border-top-right-radius: 0;
            }
        }

        &:not([data-active-provider="upload"]) {
            .tab-content {
                border-top-left-radius: 0;
            }
        }
    }

    .loadMore {
        cursor: pointer;
        max-width: 100%;
        &:hover {
            opacity: 0.8;
        }
        &.disabled {
            cursor: default !important;
        }
        p {
            padding-top: 10px;
            font-weight: bold;
            font-size: 17px;
        }
    }

    .videoList {
        &.loading{
            position: relative;
        }
        .loader {
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 2;
            @include translate(-50%, -50%);
        }
    }

    .noResultsFound {
        border-bottom: 1px $sky-blue-400 solid;
        padding: 20px;
        height: 60px;
        max-width: 100%;
    }
}

.videoItem {
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
    user-select: none;

    &:hover {
        opacity: 0.8;
    }

    p, h4 {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
    }

    h4 {
        margin: 0;
        padding-top: 10px;
        color: $hihaho-primary;
        font-weight: bold;
    }

    .img-container {
        width: 100px;
        height: 75px;
        text-align: center;
        float: left;
        margin-right: 15px;
        background-color: #000000;

        img {
            @include object-fit(cover);
        }
    }

    .label {
        margin-top: 10px;
        margin-right: 5px;
    }
}

.search-bar {
    .inner-addon {
        position: relative;
    }

    .inner-addon .glyphicons {
        position: absolute;
        padding: 10px;
        pointer-events: none;
        z-index: 3;
    }
}

/* align icon */
.left-addon .glyphicons  { left:  0;}
.right-addon .glyphicons { right: 0;}

/* add padding  */
.left-addon input  { padding-left:  30px; }
.right-addon input { padding-right: 30px; }
.loading .loader{
    display: block!important;
}
.loader{
    display: none;
}
@media (max-width: 350px) {
    .player-button-options {
        .btn-default {
            margin-bottom: 10px;
        }
    }
}

.tippy-box[data-theme~='hihaho'] {
    background-color: $text-orange-50;

	border: 1px solid $text-orange-400;
    border-radius: 15px;

    color: $text-orange-400;
    text-align: center;
    font-style: italic;

    padding: 5px 10px;

    > .tippy-svg-arrow {
        fill: $text-orange-400;
    }
}
