@import "../../color";

#pricing-page{
    /*============================================================
    BACKGROUND COLORS
    ============================================================*/
    .pricing-block {
        background-color: lighten($hihaho-primary, 8%);
        &:hover{
            &:not(.enterprise){
                background-color: lighten($hihaho-primary, 16%);
            }
            .btn-subscribe{
                -webkit-transition-duration: 0.4s;
                transition-duration: 0.4s;
                -webkit-box-shadow: 2px 2px 5px rgba(50, 50, 50, 0.75);
                -moz-box-shadow:    2px 2px 5px rgba(50, 50, 50, 0.75);
                box-shadow:         2px 2px 5px rgba(50, 50, 50, 0.75);
                background-color: lighten($hihaho-secondary, 8%);
                &:hover {
                    background-color: lighten($hihaho-secondary, 16%);
                }
            }
        }
        &.popular {
            background-color: lighten($hihaho-secondary, 8%);

            .btn-subscribe{
                background-color: lighten($hihaho-primary, 8%);
            }
            &:hover{
                background-color: lighten($hihaho-secondary, 16%);
                .btn-subscribe{
                    background-color: lighten($hihaho-primary, 8%);
                    &:hover {
                        background-color: lighten($hihaho-primary, 16%);
                    }
                }
            }
        }
    }
    /*============================================================
    PRICING STYLES
    ==========================================================*/
    
    .db-button-color-link {
        color: lighten(#000, 50%);
        &:hover {
            color: lighten(#000, 25%);
        }
    }

    .pricing-block {
        margin-bottom: 30px;
        margin-top: 50px;
        text-align: center;
        box-shadow: 0 0 5px rgba(0, 0, 0, .5);
        color: #fff;
        line-height: 20px;
        &.enterprise{
            margin-top: 0;
            .type {
                padding: 40px;
                font-size: 20px;
            }
        }
        h3{
            font-size: 1.54em;
        }
        ul {
            list-style: none;
            margin: 0;
            text-align: center;
            padding-left: 0;
            li {
                font-size: 1.2em;
                font-weight: bold;
                padding-top: 10px;
                padding-bottom: 10px;
                i {
                    margin-right: 5px;
                }
            }
        }
        .type {
            padding: 40px 10px;
            small {
                color: #FFF;
                display: block;
                font-size: 12px;
                text-transform: none;
                padding-top: 3px;
            }
        }
        .trigger-hidden-content{
            position: absolute;
            margin-top: -37px;
            right: 28px;
            font-size: 25px;
            color: rgb(255, 255, 255);
            cursor: pointer;
        }
        .price {
            background-color: rgba(0, 0, 0, 0.5);
            padding: 40px 20px 20px 20px;
            font-size: 60px;
            font-weight: 900;
            color: #FFFFFF;
            &.enterprise{
                padding: 60px;
                font-size: 60px;
                line-height: 60px;
            }
            small {
                color: #B8B8B8;
                display: block;
                font-size: 16px;
                margin-top: 22px;
            }
            .glyphicons{ 
                font-size: 82px;
            }
            .decimals{
                font-size: 36px;
                line-height: 0;
            }
        }
        .features {
            position: relative;
            padding: 10px 25px;
            text-align: left;
            min-height: 250px;
            &.personal{
                min-height: 290px;
            }
            &.professioneel, &.zakelijk {
                min-height: 285px;
            }
            &.persoonlijk{
                min-height: 325px;
            }
            ul li {
                &.hidden-content {
                    cursor: pointer;
                }
                &.bottom-item {
                    padding: 25px 0 0 0;
                    a {
                        color: #ffffff;
                        text-decoration: underline;
                        font-weight: normal;

                    }
                }
                p {
                    &.hide-element{
                        opacity: 0;
                        height: 0;
                        transition-duration: 0s;
                        overflow: hidden;
                        cursor: pointer;
                    }
                    opacity: 1;
                    height: auto;
                    font-weight: normal;
                    margin-bottom: 0;
                    margin-top: 5px;
                    transition: opacity 0.5s linear;
                    a {
                        color: white;
                        text-decoration: underline;
                    }
                }
                ul.lms-info {
                    position: absolute;
                    left: 50%;
                    margin-left: -54px;
                    margin-right: 0;
                    text-align: left;
                    font-weight: normal;
                    margin-top: 5px;
                    li {
                        margin-bottom: 0;
                        font-size: 15px !important;
                        padding: 0;
                        font-weight: normal;
                        &:before {
                            content: "\2022 \20";
                        }
                        
                    }
                }
            }
        }
        .type {
            background-color: $hihaho-primary;
            padding: 50px 20px;
            font-weight: 900;
            text-transform: uppercase;
            font-size: 30px;
            &.small{
                padding: 20px 0 10px 0;
            }
            .decimals{
                font-size: 18px;
                line-height: 0;
            }
        }
        .buy-now{
            margin-top: -20px;
        }
        .pricing-footer {
            padding: 20px;
            .pricing {
                margin-bottom: 5px;
                margin-top: -10px;
            }
            p{
                margin: 10px 0 10px 0;
            }
            text-italic{
                font-style: italic;
            }
        }
        .need-more{
            height: 40px;
            a{
                color: #ffffff;
                text-decoration: underline;
                font-weight: normal;
                font-size: 14px;  
            }         
        }

        .btn-subscribe {
            min-width: 125px;
            color: #fff;
            background-color: lighten($hihaho-secondary,8%);
            border: none;
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            //box-shadow: 0 0 5px rgba(0, 0, 0, .5);
            text-transform: uppercase;
            &:hover {
                color: #fff;
                background-color: lighten($hihaho-secondary, 8%);
                border: none;
            }
        }
        &.popular {
            margin-top: 10px;

            .type{
                background-color: $hihaho-secondary;
            }
            .price {
                padding-top: 80px;
            }
        }
        
    }

    .db-attached > .col-lg-4,
    .db-attached > .col-lg-3,
    .db-attached > .col-md-4,
    .db-attached > .col-md-3,
    .db-attached > .col-sm-4,
    .db-attached > .col-sm-3 {
        padding-left: 0;
        padding-right: 0;
    }
    
    hr{
        margin-bottom: 0;
        border-color: #FFFFFF;
    }

}

.payment-well {
    margin-bottom: 0;
    background-color: #d7ebfe !important;
    border: 1px solid $hihaho-primary !important;
    &.disabled {
        border: 1px solid #e3e3e3 !important;
        .overlay {
            background-color: rgba(255, 254, 254, 0.5);
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
