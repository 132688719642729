@import "../../color";

.imgPlaceholder {
  margin: 10px 0 10px 0;
}
.imgPreview {
  margin: 4px 4px 0 0;
  background-color: $playerbar-color;
  padding: 4px 4px 4px 0;
}
.imgCol {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.imgPlaceholder {
  position: relative;
}
.removeImgBtn {
  position: absolute;
  bottom: 0;
  right: 0;
}

.select2.select2-container {
    width: 100% !important;
}
