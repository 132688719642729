@import "./player.color";
@import "../../variables";

$progress-background-color: $playerbar-primary-color-darken-12;
$progress-color: $playerbar-primary-color;
$progress-handle-button-color: $playerbar-primary-color;
$progress-handle-button-color-darken-10: $playerbar-primary-color-darken-10;
$progress-handle-border-color: $playerbar-primary-color-darken-5-alpha-0\.3;
$controlbar-background-color: $playerbar-color;
$volumebar-fill-color: $playerbar-primary-color;
$volumebar-fill-color-darken-5: $playerbar-primary-color-darken-5;
$volumebar-background-color: transparent;
$volumebar-handle-button-color: $playerbar-primary-color;

@layer components {
    .VideoContainer {
        &:not(.static-control-bar) {
            &.hide-control-bar {
                .controlBar {
                    @apply -bottom-control-bar;
                    @media screen and (min-width: 420px) {
                        @apply -bottom-control-bar-big;
                    }
                }

                &.big-control-bar .controlBar {
                    @apply -bottom-control-bar-big;
                    @media screen and (min-width: 420px) {
                        @apply -bottom-control-bar-big;
                    }
                }
            }

            @media screen and (min-width: 420px) {
                &:not(.hide-control-bar) {
                    .progressBar,
                    .controls {
                        @apply
                        w-floating-menu
                        mx-1.5
                        mb-1.5
                        transition-none;
                    }

                    .progress-bar-markers,
                    .progressBar {
                        @apply rounded-2xl;
                    }

                    .progressVideo {
                        @apply rounded-l-2xl;
                    }
                }

                &:not(.big-control-bar) {
                    .controls {
                        @apply py-1.25;
                    }
                }

                .controls {
                    @apply rounded-control-bar;
                }
            }
        }

        &.is-mobile .video-series {
            @apply
            static
            transform-none;
        }

        &.hide-control-bar {
            @apply duration-300;
        }

        &.asking-question .controlBar .progress-handle {
            cursor: not-allowed;
        }
    }

    .controlBar {
        @apply h-control-bar;
        z-index: map-get($player_layers, controlBar);

        @media screen and (min-width: 420px) {
            @apply h-control-bar-big;
        }

        &.hide-time-indication {
            .progressBar {
                @apply tw-hidden;
            }

            .controls {
                .time-control .time-text {
                    @apply tw-hidden;
                }
            }
        }
    }

    .tooltip  {
        .VideoContainer[data-text-direction="rtl"] & {
            direction: rtl;
        }
    }

    .controls {
        button {
            @apply resize-none;
        }

        &.big {
            @apply h-control-bar-big;
        }

        &:not(.searchable) .progressBar .progress-handle {
            @apply tw-hidden;
        }

        .dropdown-item.subtitle-select {
            &[aria-checked="true"]::before {
                @apply
                content-check
                absolute
                left-2.5
                inline-block;
            }

            .VideoContainer[data-text-direction="rtl"] & {
                @apply
                pr-8
                text-right;
                &[aria-checked="true"]::before {
                    @apply
                    content-check
                    absolute
                    right-2.5
                    left-auto;
                }
            }
        }

        .progressBar .progress-handle.ui-draggable-dragging {
            @apply bg-player-theme-playerbar-primary;
        }

        .left-buttons-hidden {
            @media screen and (max-width: 340px) {
                @apply -ml-37.5;
            }
        }
    }

    .static-control-bar .controlBar {
        @apply h-control-bar-static;
        &.hide-time-indication {
            @apply h-control-bar-static-no-time-indication;
        }
    }

    .control-bar-region {
        @apply
        flex
        items-center
        grow-0
        w-auto
        min-w-icon-size
        ml-0
        text-icon-size
        }

    .control-bar-component {
        @apply
        text-player-theme-playerbar-icon-color
        transition-all duration-300 ease-out;

        &.control-hidden:not(.force-visible) {
            @media screen and (max-width: 480px) {
                @apply no-width-and-left-margin;
                .control-bar-item {
                    @apply no-width-and-left-margin;
                }
            }
        }

        &.control-can-hide-reverse {
            @apply transition-all duration-300 ease-out;
            &.control-hidden {
                @media screen and (max-width: 480px) {
                    @apply no-width-and-left-margin;
                    .control-bar-item-reverse {
                        @apply
                        overflow-hidden
                        no-width-and-left-margin;
                    }
                }
            }
        }

        + .control-bar-component {
            @apply ml-3.5;
            @media screen and (min-width: 480px) {
                @apply ml-4;
            }
        }

        .fak {
            @apply mt-0;
        }

        .control-bar-item {
            @apply
            flex
            items-center
            h-full
            max-w-icon-size
            text-icon-size
            text-player-theme-playerbar-icon-color
            fill-current
            overflow-hidden
            transition-all duration-300 ease-out;
        }
    }

    .control {
        @apply origin-center;
        &:hover {
            @apply
            text-player-theme-playerbar-icon-color
            opacity-50
            no-underline
            cursor-pointer;
        }

        &:focus {
            @apply
            outline-none
            no-underline
            animate-small-pop;
        }
    }

    .volume-control-animation {
        &.dragging,
        &.expanded,
        &:hover, {
            .volume-control {
                @apply
                w-20
                opacity-100
                volume-transition delay-200;
            }

            .volume-handle {
                @apply
                opacity-100
                transition-opacity duration-500 delay-75;
            }
        }
    }

    .default-subtitle {
        @apply opacity-50;
        &.subtitle_active {
            @apply opacity-100;
        }
    }
}
