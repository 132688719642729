@import "../../color";

body.home-dashboard {
    #dashboardSearch {
        .input-group {
            display: flex;
            position: relative;

            .form-control {
                border-radius: 50px;
                border: 2px solid $deep-blue-600;
                padding: 0 12px;
            }
        }

        .btn-success {
            border-radius: 16px;
        }

        .filter-sort, .filter-containers {
            border: 2px solid $deep-blue-600;
            background: $hihaho-white;
            color: $deep-blue-600;
            border-radius: 16px;
            margin-left: 20px;

            .glyphicons {
                padding-right: 10px;
            }

            &:hover,
            &:focus,
            &:visited,
            &:active {
                background-color: $deep-blue-600;
                color: $hihaho-white;
                outline: none;
            }
        }

        @media (max-width: 768px) {
            .filter-sort .glyphicons,
            .filter-containers .glyphicons {
                padding-left: 10px;
            }

            .input-group {
                flex-wrap: wrap-reverse;
                width: 100%;
                justify-content: space-between;

                .search-container {
                    width: 100%;
                    margin-bottom: 20px;
                    flex-grow: 1;
                    flex-basis: 100%;
                }

                .filter-sort {
                    width: 100%;
                    margin-left: 0;
                }

                .sort-filter {
                    flex: 1;
                    margin-bottom: 16px;
                    margin-right: 8px;
                }

                .type-filter {
                    flex: 1;
                    margin: 0 8px 16px;
                }

                .filter-containers {
                    flex: 1;
                    margin: 0 8px 16px 0;
                }
            }
        }
    }

    .bulk-edit-section {
        &:not(.bulk-edit-show) {
            visibility: hidden;
            width: 0;
            transition: all 0.8s;
            opacity: 0;
        }

        &.bulk-edit-show {
            width: 50px;
            transition: all 0.8s;
        }
    }

    #home-content {
        .uploaded-video, .video {
            opacity: 0;
        }

        &:not(.dashboard-components-loaded) {
            .uploaded-video, .video {
                transition: opacity 0s, background-color 0.1s;
            }
        }

        &.dashboard-components-loaded {
            .uploaded-video, .video {
                opacity: 1;
                transition: opacity 1s, background-color 0.1s;
            }
        }
    }

    .dashboard-uploaded-video-container {
        transition: max-height 1.2s;

        &:not(.uploaded-videos-show) {
            visibility: hidden;
            max-height: 0;
        }

        &.uploaded-videos-show {
            max-height: 342px;
        }
    }

    .bulk-edit-control-bar {
        &:not(.bulk-edit-control-bar-show) {
            visibility: hidden;
            transition: all 0.8s;
            opacity: 0;
        }

        &.bulk-edit-control-bar-show {
            transition: all 0.8s;
        }
    }

    .uploaded-videos-scrollable-hint {
         &:not(.scrollable-uploaded-videos-hint-show) {
             visibility: hidden;
             transition: all 0.5s;
             opacity: 0;
         }

         &.scrollable-uploaded-videos-hint-show {
             transition: all 1.5s;
         }
     }
}
