/*.subresult {
    display: none;
}
.videoOverlayInner .result {
    width: 100px;
    border: 2px solid black;
    height: 100px;
    margin: 0 auto 10px;
    font-size: 78px;
    text-align: center;
    position: relative;
}
.videoOverlayInner .result .subresult {
    font-size: 24px;
    right: 5px;
    top: 5px;
    position: absolute;
}*/

.videoOverlayInner table.questions td {
    font-size: 200%;
}
.videoOverlayInner table.questions td.questionResult {
    font-weight: var(--font-weight, 700);
}
.videoOverlayInner .questionsAndAnswers {
    margin-top: 2%;
}

/*Endscreen*/
.radio label input[type=radio]:checked + .endScreen {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 96px;
    height: 60px;
    border-radius: 4px;
    background-color: #333;
    border: solid #000 2px;
}
.endScreen-none:before {
    font-size: 82px;
    position: absolute;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    content: "\e014";
    color: #C00;
    margin-left: 6px;
    margin-top: -12px;
}

.endScreen-text:before {
    font-size: 50px;
    position: absolute;
    font-style: normal;
    font-weight: bold;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    content: "Abc";
    color: #FFF;
    margin-left: 0px;
    margin-top: 3px;
}
