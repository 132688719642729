@import '../../bootstrapsizes';

@media (min-width: $screen-md-min) {
    #sidebar-affix.affix-top {
        position: static;
        width: 228px;
    }
    #sidebar-affix.affix {
        position: fixed;
        top: 25px;
        width: 228px;
    }
}
#sidebar-affix.nav .nav {
    display: none;
    font-size: 12px;
}
#sidebar-affix.nav>.active>ul {
    display: block;
}
#sidebar-affix li.active {
    border: 0 #428bca solid;
    border-left-width: 2px;
}
#sidebar-affix>li.active>a {
    border-left: 3px solid #428bca;
}
.sub-nav li a {
    padding: 5px 30px;
}
        