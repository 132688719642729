@import '../../variables';

.videoinformation h2 {
    font-size: 18px;
    margin-top: 0px;
    color: rgb(89, 89, 89);
}

@media(max-width: $screen-xs-max) {
    .videoinformation {
        h1 {
            font-size: 23px;
        }
        h2 {
            font-size: 15px;
        }
    }
}

@media (max-width: $screen-md-max) {
    .videoinformation {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }
}
