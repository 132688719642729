@import "../../color";

$player-primary: var(--player-theme-primary, theme('playerColors.primary'));
$playerbar-color: var(--player-theme-playerbar-color, theme('playerColors.playerbar'));

$text-color: var(--player-theme-text-color, theme('playerColors.hihahoWhite'));

$player-primary-alpha-0\.9: var(--player-theme-primary-alpha-0_9, theme('playerColors.primary_alpha_0_9'));
$player-primary-alpha-0\.85: var(--player-theme-primary-alpha-0_85, theme('playerColors.primary_alpha_0_85'));
$player-primary-alpha-0\.78: var(--player-theme-primary-alpha-0_78, theme('playerColors.primary_alpha_0_78'));

$player-primary-darken-5: var(--player-theme-primary-darken-5, darken($hihaho-primary, 5%));

$player-primary-lighten-20: var(--player-theme-primary-lighten-20, lighten($hihaho-primary, 20%));
$player-primary-lighten-50: var(--player-theme-primary-lighten-50, lighten($hihaho-primary, 50%));

$playerbar-primary-color: var(--player-theme-playerbar-primary, theme('playerColors.playerbarPrimary'));
$playerbar-primary-color-darken-5: var(--player-theme-playerbar-primary-darken-5, theme('playerColors.primary_darken_5'));
$playerbar-primary-color-darken-5-alpha-0\.3: var(--player-theme-playerbar-primary-darken-5-alpha-0_3, theme('playerColors.primary_darken_5_alpha_0_3'));
$playerbar-primary-color-darken-10: var(--player-theme-playerbar-primary-darken-10, theme('playerColors.playerbarPrimary_darken_10'));
$playerbar-primary-color-darken-12: var(--player-theme-playerbar-primary-darken-12, theme('playerColors.playerbarPrimary_darken_12'));

$playerbar-icon-color: var(--player-theme-playerbar-icon-color, $hihaho-white);
$playerbar-icon-color-lighten-20: var(--player-theme-playerbar-icon-color-lighten-20, lighten($hihaho-white, 20%));

$question-text-color: var(--player-theme-question-text-color, theme('playerColors.playerQuestionTextColor'));

$question-icon-color: var(--player-theme-question-icon-color, $hihaho-white);
$question-icon-color-alpha-0\.4: var(--player-theme-question-icon-color-alpha-0_4, rgba($hihaho-white, 0.4));

$_background-color-value: darken($hihaho-dark-blue, 7%);
$background-color: var(--player-theme-background-color-rgbValues, round(red($_background-color-value)), round(green($_background-color-value)), round(blue($_background-color-value)));

$playerbar-text-color: var(--player-theme-playerbar-text-color, $hihaho-white);

$menu-text-color: var(--player-theme-menu-text-color, #9D9D9C);
$menu-text-hover-color: var(--player-theme-menu-text-hover-color, $hihaho-white);
$menu-item-hover-color: var(--player-theme-menu-item-hover-color, $hihaho-primary);

$player-secondary: var(--player-theme-secondary, theme('playerColors.secondary'));

$player-text-color: var(--player-theme-text-color, $hihaho-white);
$player-button-bg-color: var(--player-theme-button-color, $hihaho-green);
