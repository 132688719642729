@import '../../mixins';

.panel-created {
  @include panel-variant(#d6e9c6, #468847, #dff0d8, #d6e9c6);
}

.panel-deleted {
  @include panel-variant(#ebccd1, #a94442, #f2dede, #ebccd1);
}

.panel-restored {
  @include panel-variant(#faebcc, #8a6d3b, #fcf8e3, #faebcc);
}

.show-changes-toggle {
  &[aria-expanded=false] .help-text-expanded {
    display: none;
  }
  &[aria-expanded=true] .help-text-collapsed {
    display: none;
  }
}
