@import "../../color";

#main-register-page {
    padding-top: 50px;

    @media (max-width: 996px) {
        padding-top: 0;
    }

    .g-recaptcha div {
        width: 50px;
        height: 50px;
    }

    .captcha {
        display: inline-block;
        vertical-align: middle;
    }

    .container {
        margin-bottom: 150px;
    }

    .background-overlay {
        background-image: url('/img/register-background.svg');
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        width: 130%;
        height: 120%;
        z-index: 35;
        top: -5px;
        overflow: visible;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
        justify-content: center;

        &::before, &::after {
            display: none;
        }
    }

    .image-card {
        border-radius: 15px 0 0 15px;
        background-color: $deep-blue-800;
        text-align: center;
        position: relative;
        z-index: 37;

        .image-wrapper {
            display: inline-block;
            height: 100%;
            width: 60%;
            vertical-align: middle;

            img {
                width: 100%;
                height: 100%;
                vertical-align: middle;
                padding-bottom: 60px;
            }
        }
    }

    .form-card {
        border-radius: 0 15px 15px 0;
        overflow: hidden;
        background-color: $deep-blue-300;
        position: relative;

        .card-button-block {
            position: relative;
            z-index: 37;
            padding-bottom: 28px;

            .sign-up-button-and-iso-logo {
                display: flex;
                justify-content: center;
            }

            .iso-logo {
                width: 4.5rem;
                height: 4.5rem;
                margin-left: .3rem;
                margin-bottom: 3.5rem;
                object-fit: contain;
                transition: all .3s ease-out;

                &:hover {
                    width: 8rem;
                    height: 8rem;
                    margin-bottom: 0;
                }
            }

            p {
                color: $pink-600;
            }

            a {
                color: $pink-600;
                font-weight: bold;
                text-decoration: underline;
            }
        }

        .card-text-block {
            position: relative;
            z-index: 37;

            a {
                font-weight: bold;
                color: $deep-blue-950;
            }

            p {
                color: $deep-blue-950;
                margin-bottom: 50px;
            }
        }

        .education-info {
            p {
                color: $hihaho-white;
                margin-bottom: 10px;
            }

            a {
                color: $hihaho-white;
                border-color: $hihaho-white;

                &:hover {
                    background-color: $hihaho-white;
                    color: $hihaho-black;
                }

                margin-bottom: 30px;
            }
        }

        .right-card-wrapper {
            justify-content: center;
            display: flex;
            padding: 40px 160px 30px;
            margin-top: 54px;
            background-color: $deep-blue-300;

            .registration-modal {
                border-radius: 15px;
                padding: 28px;
                background-color: $hihaho-white;
                z-index: 37;

                .form-control:focus {
                    box-shadow: none;
                }

                h1 {
                    font-weight: bold;
                    margin-bottom: 30px;
                }

                form {
                    width: 100%;
                }

                .terms-of-service {
                    font-size: 12px;
                    text-align: left;

                    .checkbox {
                        min-height: 0;
                        padding: 0;
                        margin: 0;
                        color: $deep-blue-950;
                        display: inline-block;
                        vertical-align: middle;

                        #agreed_to_tos_privacy {
                            top: 4px;
                        }
                    }

                    label {
                        display: inline-block;
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    .checkbox input[type=checkbox],
    .checkbox-inline input[type=checkbox],
    .radio input[type=radio],
    .radio-inline input[type=radio] {
        margin: 0;
        float: none;
    }

    .btn-cta {
        padding: 10px 30px 8px 30px;
        margin-bottom: 6px;
        font-size: large;
    }

    @media (max-width: 996px) {
        .image-card {
            border-radius: 0;
            width: 100%;
            padding: 14px;
            height: 200px;
        }

        .form-card {
            border-radius: 0;
            border-width: 0;
            width: 100%;
            margin-top: -90px;
            background-color: $sky-blue-600;

            .right-card-wrapper {
                padding: 40px 20px 30px;
                margin-top: 0;
                background-color: $sky-blue-600;

                img {
                    padding-bottom: 60px;
                }

                .registration-modal {
                    max-width: 520px;
                }
            }
        }
    }

    @media (max-width: 1200px) {
        .background-overlay {
            display: none;
        }
    }
}
