.includedlanguages {
    border-radius: 4px;
    background-color: #eee;  
    border: solid #ccc 1px;
    padding: 3px;    
    
    label {
        border: dashed #bbb 1px;
        padding: 0px 4px;
        background-color: #FFF;
        margin-top: 2px;
        margin-bottom: 2px;
        margin-left: 3px;
        margin-right: 3px;
        border-radius: 3px;
        cursor: pointer;
    }
    .btn {
        padding: 3px 6px!important;
    }
}