@import "../../color";
@import "../../mixins";

/* Dashboard video list */

body.home-dashboard {
    .filter-containers, .filter-sort {
        &:hover {
            .label-primary {
                background: $hihaho-white;
                color: $deep-blue-600;
            }
        }
    }
}

.img-container {
    position: relative;
    background-color: #000;

    img {
        @include object-fit(cover);
    }

    > .video-thumb-link, > .anchor-replacement {
        height: 100%;
        width: 100%;
        display: block;
        position: absolute;

        &:hover {
            text-decoration: none;
        }
    }

    .title {
        color: white;
        padding: 10px;
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        margin: 0;
    }

    .duration {
        right: 0;
        bottom: 0;
        position: absolute;
        padding: 2px 7px;
        background-color: $deep-blue-600;
        color: #FFF;
        float: right !important;
        border-top-left-radius: 5px;
        font-size: 80%;
    }

    .status {
        left: 0;
        bottom: 0;
        position: absolute;
        padding: 2px 2px;
        color: #FFF;

        a {
            text-decoration: none;
        }
    }

    @media (max-width: 767px) {
        .duration {
            font-size: 100%;
        }
        .status {
            font-size: 120%;
        }
    }
}

.sortActive>a,
.sortActive>a:hover,
.sortActive>a:focus {
    color: #fff !important;
    text-decoration: none;
    background-color: #8E99A3 !important;
}
.sortInactive>a:hover,
.sortInactive>a:focus {
    color: #043348!important;
    background-color: $deep-blue-100!important;
}

.thumbnail-loading {
    min-height: 85px;
    background-color: #afafaf;
    color: white;
    font-size: 20px;
    padding-top: 5px;
    text-align: center;
}

@media (max-width: 767px) {
    li.visible-xs{
        display: inline !important;
    }
}

#UserInContainer, #ContainerTable, #AddUsers {
    tr td {
        background-color: inherit;
    }
}

//ContainerModal
#FilterContainersModal {
    .subcontainers {
        margin-left: 5px;
        border-left: $hihaho-secondary 2px dotted;
        position: relative;

        .btn-check-all {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
        }
    }

    .modal-body {
        max-height: 70vh;
        overflow-y: auto;
    }
}

//Upload_viewers
body.upload-viewers {
    .tableHeading {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }

    .tableHeading:first-letter {
        text-transform: uppercase;
    }

    .allowedViewerTable {
        overflow: hidden;
    }

    .delete-all-viewers {
        margin-bottom: 1%;
    }
}
