@import "./player.color";
@import "../../variables";
@import "../../mixins";

$color-play-icon-text: $player-text-color;

.videoOverlay {
    &.pause .indicator  {
        cursor: pointer;
    }

    &.subtitles {
        z-index: map_get($player_layers, overlaySubtitles);
        pointer-events: none;

        .wrapper {
            width: 100%;
            height: 100%;
            padding: 0 2%;
            pointer-events: none !important;

            &.subtitle_font_size_extrasmall {
                font-size: 16px;
            }
            &.subtitle_font_size_small {
                font-size: 24px;
            }
            &.subtitle_font_size_medium {
                font-size: 32px;
            }
            &.subtitle_font_size_large {
                font-size: 40px;
            }
            &.subtitle_font_size_extralarge {
                font-size: 48px;
            }

            .inner {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                pointer-events: none;

                .subtitles {
                    width: 100%;
                    min-height: 98px;
                    $subtitlesMarginBottom: 6px;
                    margin-bottom: $subtitlesMarginBottom;
                    transition: margin-bottom 100ms ease-in-out;

                    .VideoContainer.static-control-bar.is-mobile {
                        margin-bottom: 4px;
                        min-height: 96px;
                    }

                    .hide-control-bar & {
                        transition-duration: 300ms;
                    }

                    .VideoContainer:not(.static-control-bar):not(.hide-control-bar) & {
                        margin-bottom: $controlbar-height * 1.1 + $subtitlesMarginBottom;
                    }

                    .VideoContainer:not(.static-control-bar):not(.hide-control-bar).fullscreen & {
                        margin-bottom: $controlbar-height * 0.7 + $subtitlesMarginBottom;
                    }

                    .VideoContainer:not(.static-control-bar).big-control-bar:not(.hide-control-bar) & {
                        margin-bottom: $controlbar-height-big * 1.1 + $subtitlesMarginBottom;
                    }

                    .VideoContainer:not(.static-control-bar).big-control-bar:not(.hide-control-bar).fullscreen & {
                        margin-bottom: $controlbar-height-big * 0.8 + $subtitlesMarginBottom;
                    }

                    .VideoContainer:not(.static-control-bar):not(.hide-control-bar).is-mobile.orientation-portrait & {
                        margin-bottom: 20vh;
                    }

                    .VideoContainer:not(.static-control-bar):not(.hide-control-bar).is-mobile.orientation-landscape & {
                        margin-bottom: 27vh;
                    }

                    .VideoContainer:not(.static-control-bar).is-mobile.orientation-portrait.fullscreen & {
                        margin-bottom: 4px !important;
                    }

                    .subtitle-text {
                        background-color: var(--player-theme-subtitle-background-color, rgba(0, 0, 0, 0.63));
                        color: var(--player-theme-subtitle-text-color, rgb(255, 255, 255));
                        padding: 0 10px;
                        border-radius: 5px;
                        margin-top: 5px;
                        line-height: 1.35;
                        max-width: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }
    &.videoPlaylist {
        svg.hihaho-svg-icon {
            .inner .play {
                fill: $deep-blue-800;
            }
        }
    }
    &.pause, &.mobilePlayButton, &.nextVideo {
        z-index: map_get($player_layers, overlayPause);
        background-color: rgba(7, 26, 39, 0.8);

        .indicator {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -75px;
            margin-top: -75px;
            color: $color-play-icon-text;
            width: 150px;
            height: 150px;
            border-radius: 100%;
            text-align: center;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ef8121+0,f29621+100 */

            /* IE9 SVG, needs conditional override of 'filter' to 'none' */
            /*        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VmODEyMSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmMjk2MjEiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
                    background: -moz-linear-gradient(left,  #ef8121 0%, #f29621 100%);  FF3.6+
                    background: -webkit-gradient(linear, left top, right top, color-stop(0%,#ef8121), color-stop(100%,#f29621));  Chrome,Safari4+
                    background: -webkit-linear-gradient(left,  #ef8121 0%,#f29621 100%);  Chrome10+,Safari5.1+
                    background: -o-linear-gradient(left,  #ef8121 0%,#f29621 100%);  Opera 11.10+
                    background: -ms-linear-gradient(left,  #ef8121 0%,#f29621 100%);  IE10+
                    background: linear-gradient(to right,  #ef8121 0%,#f29621 100%);  W3C
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ef8121', endColorstr='#f29621',GradientType=1 );  IE6-8 */
            opacity: 1;
            span {
                width: 100%;
                height: 100%;
                text-align: center;
                line-height: 150px;
                font-size: 100px;
            }

            svg.hihaho-svg-icon {
                g.inner {
                    path, rect {
                        fill: $player-primary;
                    }
                }
            }

            &.clickable {
                cursor: pointer;
            }
        }
        .timer {
            cursor: pointer;
            top: 50%;
            left: 50%;
            margin-left: -75px;
            margin-top: -75px;
            width: 150px;
            height: 150px;
            position: absolute; /* Enable clipping */
            clip: rect(0px, 150px, 150px, 75px); /* Hide half of the progress bar */

            &.animate {
                @include animation-duration(0.01s); /* Complete keyframes asap */
                @include animation-delay(5s); /* Wait half of the animation */
                @include animation-name(close-wrapper); /* Keyframes name */
            }

            .circle {
                width: 150px;
                height: 150px;
                border: 8px solid $player-primary;
                border-radius: 50%;
                position: absolute;

                clip: rect(0px, 75px, 150px, 0px);

                &[data-anim~=left].animate {
                    @include animation-duration(10s); /* Full animation time */
                    @include animation-name(left-spin);
                }
                &[data-anim~=right].animate {
                    @include animation-duration(5s); /* Half animation time */
                    @include animation-name(right-spin);
                }
            }

            &[data-anim~=base], [data-anim~=base] {
                @include animation-iteration-count(1); /* Only run once */
                @include animation-fill-mode(forwards); /* Hold the last keyframe */
                @include animation-timing-function(linear); /* Linear animation */
            }
        }
      .next-video-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .next-video-content {
          max-width: 60%;
          .next-video-title {
            font-size: 24px;
          }
          .indicator-wrapper{
            margin-top: 15px;
            position: relative;
            height: 150px;
          }
          .text-indicator {
            color: $player-text-color;
            text-align: center;
            opacity: 1;
            font-size: 25px;
            background-color: $player-primary;
            font-weight: bold;
            border-radius: 5px;
            margin: 15px auto 0;
            padding: 8px;
            max-width: 220px;

            .cancel-button {
              cursor: pointer;
            }
          }
        }
      }
    }

    &.chapter .videoOverlayInner {
        padding-top: 15px;
    }

    &.pause {
        background-color: rgba(7, 26, 39, 0.4);
    }

    &.videoPlaylist {
        .playlist {
            padding: 5%;
            .title {
                text-align: center;
                margin-bottom: 50px;
                color: white;
                h2 {
                    font-size: 45px;
                    font-weight: var(--font-weight, 700);
                }
            }
            .playlist-item {
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 30px;
                img {
                    position: absolute;
                    @include object-fit(cover);
                }
                .playlist-item-title {
                    color: white;
                    font-size: 26px;
                    line-height: 1.2;
                    padding: 15px;
                    text-align: center;
                    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8), 0 1px 5px rgba(0, 0, 0, 0.8);
                }
                .embed-responsive {
                    background: black;
                    .indicator {
                        display: none;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        margin-left: -58px;
                        margin-top: -58px;
                        color: rgb(255, 255, 255);
                        width: 120px;
                        height: 120px;
                        text-align: center;
                        opacity: 1;
                        border-radius: 100%;
                        z-index: 1;
                        .playicon-inner {
                            fill: transparent;
                        }
                    }
                    &:hover {
                        .indicator {
                            display: block;
                            .playicon-inner {
                                fill: $player-primary;
                            }
                        }
                        .playlist-item-title {
                            opacity: 0.5;
                        }
                        a {
                            background-color: rgba(0, 0, 0, 0.35);
                        }
                    }
                }
                .image-error {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    background-color: $player-primary;
                    p {
                        font-size: 35px;
                        margin: 10px;
                        color: $player-text-color;
                    }
                }

                a {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    z-index: 100;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    background-color: rgba(0, 0, 0, 0.1);
                }
            }
        }
    }
}

.session-stored-validation-marker {
    &:not(.loading) .status-loading, &:not(.valid) .status-valid, &:not(.invalid) .status-invalid {
        display: none;
    }

    position: absolute;
    right: 5px;
    top: 5px;
    z-index: map-get($player_layers, validationMarker);

    .glyphicons {
        color: white;
        text-shadow: rgba(0, 0, 0, 0.7) 1px 0 3px;
        &.status-valid {
            color: #17D854;
        }
        &.status-invalid {
            color: #FF3E19;
            cursor: pointer;
        }
    }
}

/* Rotate the right side of the progress bar from 0 to 180 degrees */
@include keyframes(right-spin) {
    from {
        @include transform(rotate(0deg));
    }
    to {
        @include transform(rotate(180deg));
    }
}
/* Rotate the left side of the progress bar from 0 to 360 degrees */
@include keyframes (left-spin) {
    from {
        @include transform(rotate(0deg));
    }
    to {
        @include transform(rotate(360deg));
    }
}
/* Set the wrapper clip to auto, effectively removing the clip */
@include keyframes (close-wrapper) {
    to {
        clip: rect(auto, auto, auto, auto);
    }
}
