//branding color pallet
$text-orange-50: #FFF8ED;
$text-orange-100: #FFEFD4;
$text-orange-200: #FFDBA9;
$text-orange-300: #FEC072;
$text-orange-400: #FD9A3A;
$text-orange-500: #FC8323;
$text-orange-600: #ED611F;
$text-orange-700: #C44819;
$text-orange-800: #9C3914;
$text-orange-900: #7D3111;
$text-orange-950: #441706;

$text-red-50: #FFF1F1;
$text-red-100: #FFE0E0;
$text-red-200: #FFC5C5;
$text-red-300: #FE9D9D;
$text-red-400: #FE6565;
$text-red-500: #FF2929;
$text-red-600: #EC171B;
$text-red-700: #C71417;
$text-red-800: #A41013;
$text-red-900: #881414;
$text-red-950: #4A0505;

$pink-50: #FEF1F8;
$pink-100: #FEE5F3;
$pink-200: #FFCBE8;
$pink-300: #FFA0D3;
$pink-400: #FF66B4;
$pink-500: #FB3995;
$pink-600: #ED257A;
$pink-700: #CD0956;
$pink-800: #A90B48;
$pink-900: #8D0E3F;
$pink-950: #570021;

$deep-blue-50: #F5F8FA;
$deep-blue-100: #DDE8ED;
$deep-blue-200: #BFD1D9;
$deep-blue-300: #86AEBF;
$deep-blue-400: #5C8699;
$deep-blue-500: #356B85;
$deep-blue-600: #225770;
$deep-blue-700: #09425C;
$deep-blue-800: #043348;
$deep-blue-900: #042D40;
$deep-blue-950: #032533;

$sky-blue-50: #EFFCFC;
$sky-blue-100: #D5F5F8;
$sky-blue-200: #B1EBF0;
$sky-blue-300: #7BDAE5;
$sky-blue-400: #52C7D7;
$sky-blue-500: #22A4B8;
$sky-blue-600: #1F849B;
$sky-blue-700: #206B7E;
$sky-blue-800: #225868;
$sky-blue-900: #204A59;
$sky-blue-950: #10303C;

$green-50: #EEFBF3;
$green-100: #D7F4E1;
$green-200: #B1E9C8;
$green-300: #7FD6A9;
$green-400: #4ABD84;
$green-500: #29A96E;
$green-600: #198253;
$green-700: #146845;
$green-800: #125339;
$green-900: #10442F;
$green-950: #08261B;

$cta-700: $text-orange-900;
$cta-600: $text-orange-700;
$cta-500: $text-orange-400;
$cta-400: $text-orange-300;
$cta-300: $text-orange-200;
$cta-200: $text-orange-100;
$cta-100: $text-orange-50;

$hihaho-dark-blue: #043348;
$hihaho-blue: #53C6D6;
$hihaho-green: #29A96E;
$hihaho-pink: #ED257A;
$hihaho-primary: $deep-blue-800;
$hihaho-secondary: $green-500;
$hihaho-white: #FFFFFF;
$hihaho-black: #000000;
$hihaho-red: #ef2c25;

$icon-grey: #82969E;
$text-color: $hihaho-white;
$playerbar-color: $hihaho-primary;

$primary100:             lighten($hihaho-primary, 8%);
$primary200:             lighten($hihaho-primary, 13%);
$primary300:             lighten($hihaho-primary, 20%);
$primary400:             lighten($hihaho-primary, 30%);
$primary500:             lighten($hihaho-primary, 45%);
$primary600:             lighten($hihaho-primary, 62%);
$primary700:             lighten($hihaho-primary, 66%);
$primary800:             lighten($hihaho-primary, 73%);
$primary900:             lighten($hihaho-primary, 78%);
$primary1000:            lighten($hihaho-primary, 82%);

$secondary100:           lighten($hihaho-secondary, 7%);
$secondary200:           lighten($hihaho-secondary, 12%);
$secondary300:           lighten($hihaho-secondary, 18%);
$secondary400:           lighten($hihaho-secondary, 28%);
$secondary500:           lighten($hihaho-secondary, 35%);
$secondary600:           lighten($hihaho-secondary, 43%);
$secondary700:           lighten($hihaho-secondary, 50%);

//Color tinten player aanpassingen
$player-secondary-color: $hihaho-primary;
$player-option-color: $hihaho-white;

// popup type colors
$warning-700: $text-orange-900;
$warning-600: $text-orange-700;
$warning-500: $text-orange-400;
$warning-400: $text-orange-300;
$warning-300: $text-orange-200;
$warning-200: $text-orange-100;
$warning-100: $text-orange-50;

$alert-700: #742A2A;
$alert-600: #C53030;
$alert-500: #F56565;
$alert-400: #FC8181;
$alert-300: #FEB2B2;
$alert-200: #FED7D7;
$alert-100: #FFF5F5;
