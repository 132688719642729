@import "./player.color";
@import "../../variables";
@import "../../mixins";

.liveInteraction {
    z-index: 70;
    position: absolute;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    display: flex;
    flex-wrap: wrap;

    > .interactionText {
        flex: 1;
    }

    &.active {
        opacity: 1;
        &:not(.clickThrough) {
            pointer-events: auto;
        }
    }

    &[data-action-type-activated="true"]:not(.LinkInteraction) {
        box-shadow: inset 0 0 0 1px $player-primary, 0 0 0 3px $player-primary;
    }

    &:not([data-action-type-activated="true"]) {
        .action-type-activated-icon {
            opacity: 0;
        }
    }

    &.strokeBlack {
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

        > .interactionText {
            color: white;
        }
    }

    &.strokeWhite {
        text-shadow: -1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF;

        > .interactionText {
            color: black;
        }
    }

    &.ZoomInteraction {
        pointer-events: none;
    }

    &.interactionLink {
        cursor: pointer;
    }

    &.ScrollInteraction {
        white-space: nowrap;
    }

    .action-type-activated-icon {
        position: absolute;
        left: 50%;
        bottom: 0;
        @include translateX(-50%);
        z-index: 3;
        color: $text-color;
        background: $player-primary;
        padding: 0 25px;
        font-size: 24px;
        border-radius: 12px 12px 0 0;
        pointer-events: none;
        transition: opacity 0.5s ease-in-out;
    }

    &.LinkInteraction {
        .action-type-activated-icon {
            position: fixed;
            bottom: auto;
            left: auto;
            top: 0;
            right: 0;
            padding: 3px 18px;
            font-size: 50px;
            transform: none;
            border-radius: 0 0 0 12px;
        }
    }

    img {
        width: 100%;
        height: 100%;

        &.is-removed {
            object-fit: contain;
            background: $hihaho-primary !important;
        }
    }
}

.imageLink{
    cursor: pointer;
    &:hover{
        cursor: pointer;
        opacity: 0.7;
    }
    &.disabled:hover{
        cursor: not-allowed;
        opacity: 1;
    }
}

.tooltip {
    cursor: pointer;

    &.hotspot-tooltip {
        .tooltip-inner {
            overflow: hidden;
        }
    }
}

.LinkInteraction,
.HotspotInteraction {
    background-color: transparent !important;
}
