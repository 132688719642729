@charset "UTF-8";

/*
 * Updating dem fonts?
 *
 * Place the fonts in /resources/assets/fonts and change the ?v= to a new version
 */
@font-face {
  font-family: "hihaho-icon-font";
  src:url("/assets/fonts/hihaho-icon-font.eot?v=3");
  src:url("/assets/fonts/hihaho-icon-font.eot?v=3#iefix") format("embedded-opentype"),
    url("/assets/fonts/hihaho-icon-font.woff?v=3") format("woff"),
    url("/assets/fonts/hihaho-icon-font.ttf?v=3") format("truetype"),
    url("/assets/fonts/hihaho-icon-font.svg?v=3#hihaho-icon-font") format("svg");
  font-weight: normal;
  font-style: normal;

}
.hihaho-icon{
    position:relative;
    top:-1px;
    display:inline-block;
    font-style:normal;font-weight:normal;line-height:1;vertical-align:middle; text-transform: none; -webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}

.hihaho-icon[data-icon]:before {
  font-family: "hihaho-icon-font" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hihaho-icon:before {
  font-family: "hihaho-icon-font" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.hihaho-icon.floppy:before {
  content: "\69";
}
.hihaho-icon.icon-grid:before {
  content: "\6e";
}
.hihaho-icon.jump-to:before {
  content: "\6f";
}
.hihaho-icon.arrow-right:before {
  content: "\63";
}
.hihaho-icon.essay:before {
  content: "\64";
}
.hihaho-icon.exit-essay:before {
  content: "\65";
}
.hihaho-icon.exit-fullscreen:before {
  content: "\42";
}
.hihaho-icon.fullscreen:before {
  content: "\43";
}
.hihaho-icon.fly-in-rtl:before {
  content: "\67";
}
.hihaho-icon.radio-active-bg:before {
  content: "\68";
}
.hihaho-icon.radio-active-fg:before {
  content: "\6a";
}
.hihaho-icon.radio-empty:before {
  content: "\6b";
}
.hihaho-icon.checkbox-active-fg:before {
  content: "\6d";
}
.hihaho-icon.checkbox-active-bg:before {
  content: "\70";
}
.hihaho-icon.checkbox-empty:before {
  content: "\71";
}
.hihaho-icon.play:before {
  content: "\72";
}
.hihaho-icon.submit:before {
  content: "\73";
}
.hihaho-icon.arrow-left:before {
  content: "\74";
}
.hihaho-icon.menus:before {
  content: "\7a";
}
.hihaho-icon.volume-mute:before {
  content: "\6c";
}
.hihaho-icon.volume-0:before {
  content: "\75";
}
.hihaho-icon.pause:before {
  content: "\76";
}
.hihaho-icon.volume-1:before {
  content: "\78";
}
.hihaho-icon.volume-2:before {
  content: "\79";
}
.hihaho-icon.volume-3:before {
  content: "\41";
}
.hihaho-icon.mc-question-nonactive:before {
  content: "\6b";
}
.hihaho-icon.mr-question-nonactive:before {
  content: "\71";
}
