/* Example tokeninput style #2: Facebook style */
ul.token-input-list-facebook {
  overflow: hidden;
  min-height: 60px;
  height: auto !important;
  height: 1%;
  border: 1px solid #ccc;
  cursor: text;
  z-index: 999;
  margin: 0;
  padding: 0;
  background-color: #fff;
  list-style-type: none;
  clear: left;
  font-family: Arial;
}

ul.token-input-list-facebook li input {
  border: 0;
  width: 100px;
  padding: 3px 8px;
  background-color: white;
  margin: 2px 0;
  -webkit-appearance: caret;
}

li.token-input-token-facebook {
  overflow: hidden;
  height: auto !important;
  height: 15px;
  margin: 3px;
  padding: 2px 5px;
  background-color: #eff2f7;
  color: #222;
  cursor: default;
  border: 1px solid #ccd5e4;
  font-size: 14px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  float: left;
  white-space: nowrap;
}

li.token-input-token-facebook p {
  display: inline;
  padding: 0;
  margin: 0;
}

li.token-input-token-facebook span {
  color: #a6b3cf;
  margin-left: 5px;
  font-weight: bold;
  cursor: pointer;
}

li.token-input-selected-token-facebook {
  background-color: #5670a6;
  border: 1px solid #3b5998;
  color: #fff;
}

li.token-input-input-token-facebook {
  float: left;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

div.token-input-dropdown-facebook {
  position: absolute;
  background-color: #fff;
  overflow: hidden;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  cursor: default;
  font-size: 11px;
  font-family: Verdana;
  z-index: 1;
  width: 100%;
  max-width: 525px;
}

div.token-input-dropdown-facebook p {
  margin: 0;
  padding: 5px;
  font-weight: bold;
  color: #777;
}

div.token-input-dropdown-facebook ul {
  margin: 0;
  padding: 0;
}

div.token-input-dropdown-facebook ul li {
  background-color: #fff;
  padding: 3px;
  margin: 0;
  list-style-type: none;
}

div.token-input-dropdown-facebook ul li.token-input-dropdown-item-facebook {
  background-color: #fff;
}

div.token-input-dropdown-facebook ul li.token-input-dropdown-item2-facebook {
  background-color: #fff;
}

div.token-input-dropdown-facebook ul li em {
  font-weight: bold;
  font-style: normal;
}

div.token-input-dropdown-facebook ul li.token-input-selected-dropdown-item-facebook {
  background-color: #3b5998;
  color: #fff;
}