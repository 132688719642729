/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 15-jun-2015, 12:28:07
    Author     : Robert Boes
*/

@import "../../variables";
@import "../../mixins";

//default modus RTL

//posible dingen:
//
.scroll-text {
    display: inline-block;
    padding-left: 100%;

  @include animation-name(scrollHorizontal);
  @include count(infinite);
  @include duration(10s);
  @include function(linear);
  &.ltr:not(.fly-in){
      @include animation-reverse();
  }

  &.fast {
    @include duration(15s);
  }
  &.slow {
    @include duration(15s);

  }
  &.ttb, &.btt{
    @include animation-name(scrollVertical);
    padding-left: 0%;
    height: 100%;
    display: block;
  }
  &.ttb:not(.fly-in){
    @include animation-reverse();
  }
    &.fly-in {
        /*starting position*/
        padding-left: 0%;
      display: block;
      @include animation-name(fly-in);
      @include duration(5s);
      @include count(1);
      //up
        &.ttb{
          padding-left: 0%;
          height: 100%;
          @include animation-name(fly-in-down);
        }
      //down
        &.btt {
          padding-left: 0%;
          height: 100%;
          @include animation-name(fly-in-up);
        }
        &.ltr{
          @include animation-name(fly-in-reverse);
        }
    }
}
.scroll-wrap {
    display:block;
    width: 100%;
    white-space: nowrap;
    overflow:hidden;
    height: 60px;
    position: relative;
}

.scroll-text.text-bounce{
  @include animation-name(text-bounce);
    padding: 0;
    width: 100%;
}
@include keyframes(scrollHorizontal){
    0% {
        @include translateX(0);
    }
    100% {
        @include translateX(-100%);
    }
}
@include keyframes(scrollVertical){
    0% {
        @include translateY(100%);
    }
    100% {
        @include translateY(-100%);
    }
}
@include keyframes(text-bounce){
    0% {
        @include translateX(0);
    }
    50% {
        @include translateX(100%);
    }
    100% {
        @include translateX(0);
    }
}
@include keyframes(fly-in){
    0%   {
        @include translateX(100%);
    }
    100% {
        @include translateX(0);
    }
}
@include keyframes(fly-in-reverse){
    0%   {
        @include translateX(-100%);
    }
    100% {
        @include translateX(0);
    }
}
@include keyframes(fly-in-up){
    0%   {
        @include translateY(100%);
    }
    100% {
        @include translateY(0);
    }
}
@include keyframes(fly-in-down){
  0%   {
      @include translateY(-100%);
  }
  100% {
      @include translateY(0);
  }
}

@keyframes box-bounce {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.28);
    }
    100% {
        transform: scale(1);
    }
}

.VideoContainer {
    .buffer-notice {
        background-color: #000;
        color: #FFF;
        bottom: 20%;
        position: absolute;
        left: 50%;
        font-size: 30px;
        border-radius: 5px;
        padding: 10px 35px;
        width: 20%;
        margin-left: -10%;
        z-index: map_get($player_layers, bufferNotice);
        display: none;
    }

    .dots-animation:after {
        @include animation(ellipsis steps(4, end) 900ms infinite);
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        content: "\2026"; /* ascii code for the ellipsis character */
        width: 0;
    }

    .fade {
        transition: opacity .5s ease-out;

        &.in {
            animation-name: fade;
            opacity: 1;
        }

        &.out {
            animation-name: fade;
            animation-direction: reverse;
            opacity: 0;
        }
    }
}

@include keyframes(ellipsis) {
    to {
        width: 1.25em;
    }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes toastDown {
    0% {
        transform: translate(-50%, -100%);
    }
    100% {
        transform: translate(-50%, 0);
    }
}

@keyframes outlineSplash {
    0% {
        outline: 0 solid var(--player-theme-primary, theme('playerColors.secondary'));
    }
    100% {
        outline: .7rem solid rgb(0 0 0 / 0);
    }
}

.outline-splash {
    animation: outlineSplash ease-out .3s forwards 1;
}
