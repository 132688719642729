@import '../../variables';
@import "../../mixins";

.VideoContainer.notavailable .interactionContainer {
    transform: scale(1);
    z-index: 20;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    height: 100%;
}

#VideoNotAvailable {
    position: absolute;
    height: 720px;
    width: 1280px;
    max-height: 100%;
    max-width: 100%;
    z-index: 14;

    .VideoContainer.notavailable & {
        width: 100%;
        height: 100%;
    }

    > img {
        margin: 0;
        left: 0;
        top: 0;
        @include object-fit(cover);
        padding: 0;
    }
    > span {
        text-align: center;
        color: #FFF;
        font-weight: bold;
        position: absolute;
        @include translate(-50%, -50%);
        top: 50%;
        left: 50%;
        vertical-align: middle;
        margin: 0 auto;
        width: 100%;
        padding: 0 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        #enrich & {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        span.big {
            font-size: 250%;
            @media (max-width: 768px) {
                font-size: 150%;
            }
            #enrich & {
                @media (max-width: 1400px) {
                    font-size: 200%;
                }
                @media (max-width: 1100px) {
                    font-size: 150%;
                }
            }
        }

        span.small {
            font-size: 130%;
            font-weight: normal;
            @media (max-width: 768px) {
                font-size: 95%;
            }
        }

        .btn {
            margin-top: 10px;
            border: none;
            font-size: 150%;
            @media (max-width: 768px) {
                font-size: 120%;
            }
            #enrich & {
                @media (max-width: 1400px) {
                    font-size: 130%;
                }
                @media (max-width: 1100px) {
                    font-size: 120%;
                }
            }
        }
    }

    > span.no-connection {

        span.small {
            margin: 3% 0;
        }
    }
}
