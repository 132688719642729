@import "../../color";
@import "../../variables";
@import "../../mixins";

/*Enrich tools*/
.gridBackground{
    z-index: map-get($player_layers, gridBackground);
}

.EditArea {
    --label-width: 20%;
    --row-padding: 15px;
    --answer-true-switch-width: 90px;
    html[lang="de-DE"] &, // German
    html[lang="hi-IN"] &, // Hindi
    html[lang="ja-JP"] &, // Japanese
    html[lang="pt-PT"] &, // Portuguese
    html[lang="ru-RU"] &, // Russian
    html[lang="es-ES"] & { // Spanish
        --label-width: 30%;
        --answer-true-switch-width: 120px;
    }
}

.background-grid-detailed{
    background-image: linear-gradient(white 2px, transparent 2px),
        linear-gradient(90deg, white 2px, transparent 2px),
        linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),
        linear-gradient(90deg, rgba(255,255,255,.3) 1px, transparent 1px);
    background-image: -moz-linear-gradient(white 2px, transparent 2px),
        linear-gradient(90deg, white 2px, transparent 2px),
        linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),
        linear-gradient(90deg, rgba(255,255,255,.3) 1px, transparent 1px);
    background-image: -o-linear-gradient(white 2px, transparent 2px),
        linear-gradient(90deg, white 2px, transparent 2px),
        linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),
        linear-gradient(90deg, rgba(255,255,255,.3) 1px, transparent 1px);
    background-image: -webkit-linear-gradient(white 2px, transparent 2px),
        linear-gradient(90deg, white 2px, transparent 2px),
        linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),
        linear-gradient(90deg, rgba(255,255,255,.3) 1px, transparent 1px);
    -webkit-background-size: 80px 80px, 80px 80px, 20px 20px, 20px 20px;
    background-size: 80px 80px, 80px 80px, 20px 20px, 20px 20px;
    background-position: 0px 0px, -1px -1px, -1px -1px, 0px 0px;
    background-color: rgba(0,0,0,0.25)!important;
    pointer-events: none;
}

.background-grid-rough{
    background-image: linear-gradient(white 2px, transparent 2px),
        linear-gradient(90deg, white 2px, transparent 2px),
        linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),
        linear-gradient(90deg, rgba(255,255,255,.3) 1px, transparent 1px); /* Standard */
    background-image: -moz-linear-gradient(white 2px, transparent 2px),
        linear-gradient(90deg, white 2px, transparent 2px),
        linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),
        linear-gradient(90deg, rgba(255,255,255,.3) 1px, transparent 1px); /* Mozzila Firefox */
    background-image: -o-linear-gradient(white 2px, transparent 2px),
        linear-gradient(90deg, white 2px, transparent 2px),
        linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),
        linear-gradient(90deg, rgba(255,255,255,.3) 1px, transparent 1px); /* Opara */
    background-image: -webkit-linear-gradient(white 2px, transparent 2px),
        linear-gradient(90deg, white 2px, transparent 2px),
        linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),
        linear-gradient(90deg, rgba(255,255,255,.3) 1px, transparent 1px); /* Webkit (Chrome) */
    -webkit-background-size: 160px 160px, 160px 160px, 40px 40px, 40px 40px;
    background-size: 160px 160px, 160px 160px, 40px 40px, 40px 40px;
    background-position: -1px -41px, -1px -41px, -1px -1px, -1px -1px;
    background-color: rgba(0,0,0,0.25)!important;
    pointer-events: none;
}

.background-player-bar-height-indicator-wrapper{
    border-top: 4px solid $hihaho-secondary;
    position: absolute;
    width: 100%;
    bottom: 0px;
    height: 65px;
    z-index: map_get($player_layers, playerBarHeightIndicator);
    &:hover {
        border-top-color: $hihaho-primary;
    }
    pointer-events: none;
}
.background-player-bar-height-indicator{
    width: 100%;
    background-color: $hihaho-secondary;
    opacity:0.4;
    height: 100%;
    &:hover {
        background-color: $hihaho-primary;
    }
}

.tooltip-inner {
    font-size: 16px;
    max-width: 240px;
}

#selectQuestionTypeModal, #selectMenuTypeModal {
    svg {
        &.select-menu-type, &.select-question-type {
            margin-left: auto;
            margin-right: auto;
            display: block;
            max-width: 150px;
        }
    }
}

#VideoTitleBar {
    #EditTitle h2::before {
        content: "\E031";
        background-color: #dcddde;
        padding: 6px 8px;
        margin-right: 5px;
        font-family: 'Glyphicons Regular';
        font-weight: normal;
        font-style: normal;
        font-size: 70%;
        display: inline-block;
        line-height: 1em;
    }

    .input-group {
        .input-group-btn {
            .btn {
                padding-left: 30px;
                padding-right: 30px;
            }
        }
    }
}

/*timeline CSS*/
.timerContainer{
    position: absolute;
    height: 100%;
    padding: 0;
    width: 100%;
}
.timerContainer .videoTimer .sleepbuttonUp{
    position: absolute;
    bottom:-15px;
    left: -7px;
}
.timerContainer .videoTimer .sleepbuttonDown{
    position: absolute;
    top:-15px;
    left: -7px;
}

#Timeline {
    body:not(.disable-scrollable-timeline) & {
        padding-right: 17px;
    }

    body.disable-scrollable-timeline & {
        padding-bottom: 35px !important;
    }

    &:not(.loading) {
        #timeline-loader {
            display: none;
        }
    }

    .interaction-name {
        padding-left: 15px;
        line-height: 30px;
    }

    .separate-question:hover,
    #scrollable-timeline .row.interaction:hover {
        background: $hihaho-white;
        outline: 1px solid #eeeeee;
        .interaction-timeline {
            background: #c2dcdf;
        }
    }
}

/*enrich question*/
#answers label {
    display: block;
    font-weight: normal;
}
.timeline .vraag{
    position: absolute;
    color: $hihaho-primary;
    height: 100%;
}
.timeline.interactions{
    padding: 0;
    height: 20px;
}
.timeline .interaction{
    background-color: #d2322d;
    position: absolute;
    border: 1px solid #d43f3a;
    height: 18px!important;
    border-radius: 10px;
}

#enrich .studio-element textarea.form-control,
#enrich .studio-element textarea.form-control[disabled] {
    &[data-feedback-correct="correct"] {
        border-left: 6px solid $green-500;
    }

    &[data-feedback-correct="incorrect"] {
        border-left: 6px solid $text-red-500;
    }

    &[data-feedback-correct="partly-correct"] {
        border-left: 6px solid $text-orange-500;
    }
}
/*end enrich question*/

.input-feedback-title-custom-text-row:not(.tw-hidden) + .input-feedback-row > label > i.fak {
    display: none;
}

/* Edit interaction */
.interaction-settings-input,
.interaction-settings-label,
#answers label.interaction-settings-label {
    display: block;
    width: 100%;
    padding-right: 0;
    padding-left: var(--row-padding);
}

.interaction-settings-input {
    width: calc(100% - var(--row-padding));
}

@include break($screen-lg-min) {
    .interaction-settings-input,
    .interaction-settings-label,
    #answers label.interaction-settings-label {
        display: inline-block;
    }

    #answers label.interaction-settings-label,
    .interaction-settings-label {
        width: var(--label-width);
        vertical-align: top;
    }

    .interaction-settings-input {
        width: calc(100% - var(--label-width) - var(--row-padding));
        margin-left: -0.45ch;
    }

    label[for="inputLimitCharacters"] {
        width: calc(100% - 4rem);
    }
}

.demoInteraction {
    position: absolute;
    left: 10%;
    width: 30%;
    height: 20%;
    top: 10%;
    display: none;
    z-index: map-get($player_layers, demoInteraction);
    opacity: 0.8;
    border: none;

    .InteractionText{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }

    label {
        font-weight: normal;
    }

    .transparent {
        z-index: -1;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        /* http://css3pie.com/demos/gradient-patterns/ */
        background-image: -webkit-linear-gradient(45deg, #CBCBCB 25%, transparent 25%, transparent 75%, #CBCBCB 75%, #CBCBCB), -webkit-linear-gradient(45deg, #CBCBCB 25%, transparent 25%, transparent 75%, #CBCBCB 75%, #CBCBCB);
        background-image: -moz-linear-gradient(45deg, #CBCBCB 25%, transparent 25%, transparent 75%, #CBCBCB 75%, #CBCBCB), -moz-linear-gradient(45deg, #CBCBCB 25%, transparent 25%, transparent 75%, #CBCBCB 75%, #CBCBCB);
        background-image: -o-linear-gradient(45deg, #CBCBCB 25%, transparent 25%, transparent 75%, #CBCBCB 75%, #CBCBCB), -o-linear-gradient(45deg, #CBCBCB 25%, transparent 25%, transparent 75%, #CBCBCB 75%, #CBCBCB);
        background-image: -ms-linear-gradient(45deg, #CBCBCB 25%, transparent 25%, transparent 75%, #CBCBCB 75%, #CBCBCB), -ms-linear-gradient(45deg, #CBCBCB 25%, transparent 25%, transparent 75%, #CBCBCB 75%, #CBCBCB);
        background-image: linear-gradient(45deg, #CBCBCB 25%, transparent 25%, transparent 75%, #CBCBCB 75%, #CBCBCB), linear-gradient(45deg, #CBCBCB 25%, transparent 25%, transparent 75%, #CBCBCB 75%, #CBCBCB);
        -webkit-background-size: 30px 30px;
        -moz-background-size: 30px 30px;
        background-size: 30px 30px;
        background-position: 0 0, 15px 15px;
    }

    .demoInteractionBorder {
        width: 100%;
        height: 100%;
        border: 2px dashed $hihaho-primary;

        &:hover {
            border-style: solid;
        }
    }

    .ui-resizable-handle {
        width: 8px;
        height: 8px;
        background-color: #ffffff;
        border: 1px solid #000000;
    }

    #nwgrip, #negrip, #ngrip {
        top: 0;
    }

    #segrip, #swgrip, #sgrip {
        bottom: 0;
    }

    #wgrip, #nwgrip, #swgrip {
        left: 0;
    }

    #negrip, #segrip, #egrip {
        right: 0;
    }

    #ngrip, #sgrip {
        left: 50%;
        @include translateX(-50%);
    }

    #wgrip, #egrip {
        top: 50%;
        @include translateY(-50%);
    }

    @include break('0px', '1199px') {
        .ui-resizable-handle {
            width: 12px;
            height: 12px;
        }
    }
}

.nonClickBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: map_get($player_layers, nonClickBackground);
    display: none;
    top: 0;
    bottom: 0;
}

.answer {
    padding-bottom: 10px;
    padding-top: 10px;

    & + .answer {
        border-top: 1px solid $deep-blue-100;
    }

    & .title-row button {
        margin-top: -15px;
    }
}

.interaction-dropdown {
    display: block;

    button {
        text-align: left;
        float: none !important;

        .caret {
            position: absolute;
            top: 13px;
            right: 15px;
        }

        .plus {
            margin-top: -2px;
            font-size: 14px;
        }
    }
}

.interactions {
    position: relative;
}

.timerContainer {
    position: absolute;
    height: 100%;
    padding: 0;

    .videoTimer {
        z-index: 100;
        position: absolute;
        top: 4px;
        left: 0;
        bottom: 0;
        width: 14px;
        margin-left: -7px;
        background: linear-gradient(90deg, transparent 6px, $hihaho-pink 6px, $hihaho-pink 8px, transparent 8px);
        cursor: e-resize;

        #enrich.is_livestream & {
            display: none;
        }

        .topTriangle {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 10px 0 10px;
            border-color: $hihaho-pink transparent transparent transparent;
            margin-left: 31px;
            margin-top: -2px;
        }

        .bottomTriangle {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10px 10px 10px;
            border-color: transparent transparent $hihaho-pink transparent;
            position: absolute;
            margin-left: -3px;
            bottom: 0;
        }

        .indicator-handle {
            position: absolute;
            width: 0;
            height: 0;

            &.handle-top {
                position: relative;
                top: 0;
                background-color: transparent;
                height: 30px;
                width: 12px;
            }
        }
    }
}

body.enrich {
    #container {
        overflow: hidden;
    }

    footer:not(#studio-footer), .pushfooter {
        display: none;
    }

    .whiteContent {
        padding: 0;
    }

    .CodeMirror {
        height: auto;
        min-height: 300px;
    }
}

#JsonInteractionsImportModal {
    .modal-body {
        max-height: 70vh;
        overflow-y: auto;
    }
}

#video-preview-and-editor {
    #video-preview {
        max-width: 860px;

        body[data-orientation="portrait"] & {
            max-width: 500px;
        }

        @media (max-width: 2050px) {
            max-width: 780px;

            body[data-orientation="portrait"] & {
                max-width: 475px;
            }
        }

        @media (max-width: 1950px) {
            max-width: 700px;

            body[data-orientation="portrait"] & {
                max-width: 450px;
            }
        }

        @media (max-width: 1850px) {
            max-width: 630px;

            body[data-orientation="portrait"] & {
                max-width: 440px;
            }
        }

        @media (max-width: 1650px) {
            max-width: 600px;

            body[data-orientation="portrait"] & {
                max-width: 430px;
            }
        }

        @media (max-width: 1550px) {
            max-width: 560px;

            body[data-orientation="portrait"] & {
                max-width: 420px;
            }
        }

        @media (max-width: 1400px) {
            max-width: 530px;

            body[data-orientation="portrait"] & {
                max-width: 415px;
            }
        }
    }
}

#studio-footer {
    a {
        font-size: 0.8rem;
        padding: 0.4rem 0;
    }
}

#TimelineSpec {
    height: 30px;
    border-bottom: 0;
    background-color: $hihaho-primary;
    color: #FFF;
    cursor: pointer;
    border-radius: 10px;

    #enrich.is_livestream & {
        display: none;
    }

    .start-time, .end-time {
        cursor: default;
        display: block;
        position: absolute;
        top: -18px;
        font-weight: bold;
        text-align: center;
        width: 65px;
        color: #222;
    }

    .start-time {
        left: 0;
        text-align: left;
    }

    .end-time {
        right: 0;
        text-align: right;
    }

    .ruler-item {
        width: 1px;
        margin-top: 0.64em;
        //margin-top: 1.5em;
        text-align: center;
        position: absolute;

        &:before {
            content: '';
            position: absolute;
            border-left: 3px solid lighten($hihaho-primary, 20%);
            height: .64em;
            top: -.64em;
            //height: 1.5em;
            //top: -1.5em;
            left: -1px;
        }

        &.small:before {
            height: .32em;
        }

        span {
            position: absolute;
            left: -16px;
        }
    }
}

.interaction {
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
    -webkit-transition: background-color 1s;
    -moz-transition: background-color 1s;
    -o-transition: background-color 1s ;
    transition: background-color 1s;

    &.saved {
        background-color: rgba(65, 138, 201, 0.65);
    }
}

.interaction > * {
    padding-right: 0;
}

.ui-state-highlight-timelines{
    background-color: $hihaho-primary;
    opacity:0.4;
    height: 40px;
}

#Timeline .interaction-timeline {
    &:not(.no-background) {
        background-color: #DDD;
    }

    height: 32px;
    padding-left: 0;
    padding-right: 0;
    border-radius: .5rem;
    transition: background-color .4s;
    &:not(#timeline-header-questions) {
        cursor: pointer;
    }

    #enrich.is_livestream & {
        background-color: transparent;

        .time-bar, .question {
            display: none !important;
        }
    }

    .time-bar {
        $timeBarBackgroundColor: #BCD;

        position: absolute;
        top: 0;
        bottom: 0;
        background-color: $timeBarBackgroundColor;
        min-width: 1%;

        &.time-pause {
            transition: background-color .4s;
            background-color: $hihaho-blue;
            .glyphicons {
                line-height: 30px;
                top: 0;
            }
        }
        &.time-text{
            background-color: $hihaho-blue;
        }
        &.time-subtitle {
            border-left: 1px solid darken($timeBarBackgroundColor, 4%);
            border-right: 1px solid darken($timeBarBackgroundColor, 4%);

            &:hover {
                background-color: darken($timeBarBackgroundColor, 4%);
            }
        }
        &.time-chapter-item {
            margin: auto;
            height: 65%;
            border-left: 1px solid rgba(25, 101, 66, 0.70);
            border-right: 1px solid rgba(25, 101, 66, 0.70);
            background-color: rgba(25, 101, 66, 0.65);
            pointer-events: none;

            &.warning {
                border-left: 1px solid rgba(192, 86, 33, 0.70);
                border-right: 1px solid rgba(192, 86, 33, 0.70);
                background-color: rgba(192, 86, 33, 0.65);
            }
        }
    }

    .jump-to-time {
        width: 2px;
        height: 100%;
        background-color: $hihaho-blue;
        position: absolute;
        margin-left: -1px;
        top: 0;
    }
}

#timeline-header-questions, #timeline-header-adaptive, .separate-question {
    .question {
        position: absolute;
        color: $hihaho-blue;
        height: 100%;
        font-size: 25px;
        line-height: 1.214;
    }
    .interaction {
        position: absolute;
    }
}

.ColorPicker {
    display: block;
}

$interactionButtonSize: 110px;
$interactionButtonColumnItems: 5;
$interactionButtonColumnItemsGap: 14px;
.newInteractionSection {
    float: left;
    width: 100%;

    .section {
        &::after {
            content: " ";
            display: block;
            height: 0;
            clear: both;
        }

        width: $interactionButtonColumnItems * ($interactionButtonSize + $interactionButtonColumnItemsGap);
        width: fit-content;
        max-width: 100%;
        display: grid;
        grid-template-columns: repeat($interactionButtonColumnItems, 1fr);
        grid-auto-rows: auto;
        grid-gap: $interactionButtonColumnItemsGap;

        @media (max-width: 1650px) {
            grid-gap: ($interactionButtonColumnItemsGap - 5px) $interactionButtonColumnItemsGap;
        }

        @media (max-width: 1550px) {
            grid-gap: ($interactionButtonColumnItemsGap - 7px) $interactionButtonColumnItemsGap;
        }

        @media (max-width: 1400px) {
            grid-gap: ($interactionButtonColumnItemsGap - 8px) $interactionButtonColumnItemsGap;
        }

        @media (max-width: 1250px) {
            grid-gap: ($interactionButtonColumnItemsGap - 10px) ($interactionButtonColumnItemsGap - 2px);
        }

        .interaction-header {
            float: left;
            width: 100%;
            grid-column-end: span $interactionButtonColumnItems;
        }

        .btn.btn-create-interaction {
            margin: 5px;
            float: left;
            position: relative;
            padding: 7px 5px;
            border-radius: 0;
            color: #58595b;
            background: $hihaho-white;
            border: none;

            width: $interactionButtonSize;
            height: $interactionButtonSize * 0.93;

            @media (max-width: 1650px) {
                width: $interactionButtonSize * 0.96;
                height: $interactionButtonSize * 0.90;
            }

            @media (max-width: 1550px) {
                width: $interactionButtonSize * 0.88;
                height: $interactionButtonSize * 0.80;
            }

            @media (max-width: 1400px) {
                width: $interactionButtonSize * 0.85;
                height: $interactionButtonSize * 0.75;
            }

            @media (max-width: 1250px) {
                width: $interactionButtonSize * 0.75;
                height: $interactionButtonSize * 0.68;
            }

            &#interaction-button-link {
                .hihaho-svg-icon {
                    height: 83%;
                    margin-top: -10%;

                    @media (max-width: 1650px) {
                        height: 72%;
                    }

                    @media (max-width: 1550px) {
                        height: 69%;
                    }
                }
            }

            .btn-content {
                position: relative;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;

                .hihaho-svg-icon {
                    display: block;
                    margin: 1% 0 0 0;
                    height: 48%;
                    width: 100%;
                    float: left;

                    @media (max-width: 1650px) {
                        height: 44%;
                    }

                    @media (max-width: 1550px) {
                        height: 42%;
                    }

                    *:not(g) {
                        fill: $icon-grey;
                    }
                }

                .interaction-name {
                    text-align: center;
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: 0;
                    background: transparent;
                    color: $icon-grey;
                    line-height: 17px;
                    overflow: unset;
                    text-overflow: initial;
                    white-space: pre-wrap;

                    @media (max-width: 1400px) {
                        line-height: 16px;
                        font-size: 12px;
                        bottom: 0;
                    }
                }
            }

            &:hover {
                .btn-content {
                    background-color: white;

                    .hihaho-svg-icon {
                        *:not(g) {
                            fill: $hihaho-blue;
                        }
                    }

                    .interaction-name {
                        color: $sky-blue-950;
                    }
                }
            }
        }

        //Overwrite certain properties when grid is supported
        @supports (display: grid) {
            &::after {
                display: none;
            }
            .btn.btn-create-interaction {
                margin: 0;
            }
        }
    }

    .tooltip-inner {
        font-size: 16px;
    }
}

.interaction-header {
    font-size: 30px;
    font-weight: 700;
    font-family: 'Nunito Sans', sans-serif;
    text-transform: lowercase;
    color: black;
}

#enrich {
    &:not(.loading) {
        .enrich-loader {
            display: none;
        }
    }

    &:not(.timeline-disabled-before-playing) {
        #timeline-disabled-before-playing-overlay {
            display: none;
        }
    }

    &.timeline-disabled-before-playing {
        .videoTimer {
            opacity: 0.6;
            pointer-events: none;
        }

        .counters-right .current-time-block, #TimelineSpec .start-time {
            display: none;
        }
    }

    .enrich-loader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0,0,0, 0.55);
        z-index: 144;
        .enrich-loader-wrapper {
            margin-bottom: 20%;
            text-align: center;
            h2 {
                color: white;
                text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
            }
        }
    }
}

#enrich .studio-element {
    .form-control:not(.studio-time-input) {
        border: 1px solid $deep-blue-200;
        border-radius: 0;
        font-weight: bold;
        box-shadow: none;
        color: $deep-blue-800;
        min-height: 2rem;

        &:focus {
            border-color: lighten(desaturate($hihaho-primary, 25%), 35%);
            box-shadow: 0 0 2px 1px rgba($hihaho-primary, 0.25);
        }
    }
    .form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
        border-color: grayscale($hihaho-primary);
        cursor: default;
        background-color: #f3f3f3 !important;
    }
    .input-group-addon {
        border: 1px solid $deep-blue-200;
        font-weight: bold;
        border-radius: 0;

        &:last-child {
            color: $deep-blue-800;
            border-left: 0;
        }
    }
    .btn-group {
        .btn-group-block ul {
            padding: 0;
            margin-right: 0;
        }

        > .btn:hover {
            z-index: 25;
        }
        .btn + .btn,
        .btn + .btn-group,
        .btn-group + .btn,
        .btn-group + .btn-group {
            margin-left: -3px;
        }
        .open {
            > .dropdown-toggle {
                box-shadow: none;

                &.btn-primary {
                    &, &.focus, &:focus, &:hover {
                        color: #FFFFFF;
                        background-color: lighten(desaturate($hihaho-primary, 20%), 10%);
                        border-color: lighten(desaturate($hihaho-primary, 20%), 10%);
                        outline: none;
                    }
                }
            }
        }
    }
    .btn {
        &:not(.btn-filter-interaction) {
            border-width: 1px;
        }


        &:not(.btn-icon) {
            border-radius: 0;
        }

        &:hover {
            box-shadow: none;
        }

        &.btn-rounded {
            border-radius: 25px;
        }
        &.btn-success {
            background-color: $green-500;
            border-color: $green-500;

            &:hover {
                background-color: $green-600;
                border-color: $green-600;
                color: $green-100;
            }
        }

        &.btn-default {
            border-color: $deep-blue-200;
            background-color: $hihaho-white;
            color: $deep-blue-800;

            &:hover {
                border-color: $hihaho-primary;
            }
        }

        &.btn-primary {
            border-color: $deep-blue-200;
            background-color: $hihaho-white;
            color: $deep-blue-800;

            &:hover {
                background-color: $hihaho-primary;
                border-color: $hihaho-primary;
                color: $deep-blue-100;
            }
        }

        &.btn-outlined {
            background-color: transparent;
            border-color: $hihaho-green;
            color: $hihaho-green;
            -webkit-box-shadow: none;
            box-shadow: none;
            outline: none;

            &:hover {
                background-color: $green-600;
                border-color: $green-600;
                color: $green-100;
            }
        }
    }
    &.addInteractions textarea {
        min-height: 2rem;
    }
}

.separate-question {
    background-color: rgba(242, 242, 242, 0.9);
    .last {
        border-bottom: 1px solid rgb(126, 126, 126);
    }
}

.interaction-name {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 30px;
    white-space: nowrap;
    max-width: 100%;
    cursor: pointer;
}

$timerWidth: 245px;

//Counters statische breedte meegeven.
#enrich .counters-right {
    float: right;
    width: $timerWidth - 15px;
    padding-right: 15px;

    .delayTime {
        .input-group {
            .static-counter-size {
                width: 100%;
                padding: 5px 8px;
                outline: none;
                transition: border-color ease-in-out 015s, box-shadow ease-in-out .15s;
            }
            .input-group-addon {
                padding: 5px 12px;
            }
        }
    }

    .static-counter-size input, .delayTime .static-counter-size {
        font-weight: bold;
        height: 100%;
        font-size: 12px;
    }

    .add-tooltip {
        margin-left: 5px;
    }
}

#enrich.is_livestream {
    .counters-right {
        .startTime, .endTime {
            display: none;
        }
    }
}

#enrich:not(.is_livestream), #enrich.edit-screen-open {
    .livestream-currently-shown-toggle, .edit-livestream-interaction-button {
        display: none;
    }
}

.current-time-block {
    float: right;
    margin-right: 5px;

    .studio-time-input {
        font-weight: bold;
    }
}

#IndicatorWrapper {
    height: 100%;
    position: absolute;
    right: $timerWidth;
    left: 16.666666666666664%;
    margin-left: -35px;
    margin-right: -30px;
}

.col-without-timers {
    //https://stackoverflow.com/questions/17982111/sass-variable-in-css-calc-function
    width: calc(100% - #{$timerWidth});
    padding-left: 30px;
}

.label-info {
    background-color: $hihaho-primary;
}

.media-library-wrapper {
    overflow-y: auto;
    max-height: calc(100vh - 400px);
}

.thumbnail {
    border: 5px solid $sky-blue-200;
    color: $deep-blue-300;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 10px;

    &:not(.with-border) {
        border-color: transparent;
    }

    #app & img {
        @include object-fit(contain);
    }

    &.selected {
        border-color: $sky-blue-400;
    }

    &:hover {
        cursor: pointer;

        .hihaho-svg-icon {
            *:not(g) {
                fill: $hihaho-blue;
            }
        }

        .caption {
            color: $sky-blue-950;
        }
    }

    #imageLibraryContainer .media-library-wrapper & {
        height: 280px;

        img {
            max-height: 250px;
        }
    }

    #soundLibraryContainer .media-library-wrapper & {
        &.selected .glyphicons {
            color: $deep-blue-800;
        }
    }

    .hihaho-svg-icon {
        *:not(g) {
            fill: $icon-grey;
        }
    }

    .caption {
        text-align: center;
        font-size: 18px;
        color: $icon-grey;
    }

    p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
        margin: 0;
    }
}

.hihaho-svg-icon.delete-icon {
    fill: $icon-grey;
    &:hover {
        cursor: pointer;
        *:not(g) {
            fill: $alert-600;
        }
    }
}

#slider-border-radius, #slider-scroll-speed, #ImageOpacityInput {
    background: $pink-600;
    border: none;
    border-radius: 0;
    .ui-slider {
        border-radius: 0;
    }
    .ui-slider-horizontal {
        height: .7em;
    }
    .ui-slider-range {
        background: $pink-200;
        border-color: $pink-200;
    }
    .ui-slider-handle {
        background: $pink-800;
        border-color: rgba($pink-800, 0.5);
        border-radius: 100%;
        color: #FFFFFF;
        width: 2em;
        height: 2em;
        top: 50%;
        margin-top: -1em;
        text-align: center;
        line-height: 2em;
        margin-left: -1.2em;
        font-size: 0.8em;
        cursor: grab;
    }
    .ui-slider-handle:hover, .ui-slider-handle.ui-state-active {
        background: darken($pink-600, 10%);
    }
}
$menu-item-height: 140px;
#edit-menu-items{
    .glyphicons.sorting{
        height: 100px;
        line-height: 100px;
        cursor: ns-resize;
    }
    .menu-item{
        background-color: $deep-blue-100;
        height: $menu-item-height;
        border-radius: 0;
        margin-top: 3px;
        margin-bottom: 4px;
        padding: 15px;
    }
    .ui-state-highlight{
        height: $menu-item-height;
        background: $secondary700;
        border: dotted $hihaho-secondary 2px;
        border-radius: 0;
        margin-top: 3px;
        margin-bottom: 4px;
    }
}
$chapter-item-height: 215px;

.showChapterSettings .chapter-top-wrapper {
    display: flex;

    .chapter-item-out-of-range-warning {
        display: flex;
        border-radius: 5px;
        background: rgba(192, 86, 33, 0.8);
        margin-left: auto;
        padding-left: 5px;
        padding-right: 5px;

        .glyphicons {
            align-self: center;
            margin-right: 5px;
            margin-left: 5px;
            color: white;
        }

        .warning-header {
            font-weight: bold;
            margin-right: 5px;
            margin-left: 5px;
            align-self: center;
        }

        .warning-text {
            margin-right: 5px;
            align-self: center;
            color: white;
        }
    }
}

#edit-chapter-items {
    .glyphicons.sorting {
        height: 100px;
        line-height: 100px;
        cursor: ns-resize;
    }

    .chapter-item {
        border: 1px solid $deep-blue-100;
        background-color: $deep-blue-100;
        height: $chapter-item-height;
        border-radius: 0;
        margin-top: 3px;
        margin-bottom: 4px;
        padding: 15px;
    }

    .ui-state-highlight {
        height: $chapter-item-height;
        background: lighten($deep-blue-800, 40%);
        border: dotted $deep-blue-800 2px;
        border-radius: 0;
        margin-top: 3px;
        margin-bottom: 4px;
    }
}

#inputPoints{
    width: 90px;
}

#ImportInteractionContainer{
    .import-interaction-label{
        .title label{
            font-weight: normal;
        }
    }
}
.backbuttonHr {
    border-color: #85b4dc;
    margin: 5px 30px 0px 0px;
}

.indicatorLabel {
    position: absolute;
    width: 80px;
    height:20px;
    margin-top: -19px;
    margin-left: -34px;
}
.indicatorLabelInput {
    background-color: $hihaho-pink !important;
    color: #fff;
    border: 0px;
    text-align: center;
    height: 19px !important;
    font-size: 12px;
    padding-top: 2px;
    border-radius: 3px;
}

#EnrichCurrentTimeOne {
    width:80px;
    display: inline-block;
    padding-left:5px !important;
    padding-right:5px !important;
    position: relative;
}
#ContainerTable {
    .details-control {
        width: 15px !important;
    }
}
.confirmEmailResend {
    text-align: center;
    margin-top: 3%;
}
.confirmDescriptionOuter {
    text-align: center;
    .confirmEmailDescription {
        margin-top: 2%;
    }
}

.dropdown-menu.filter-interactions {
    @media screen and (max-height: 1000px) {
        transform: translate(0, calc(50% - 4rem));
    }
    @media screen and (max-height: 800px) {
        position: fixed;
        top: 4rem;
        left: 2rem;
        bottom: 0;
        overflow-y: auto;
        overscroll-behavior: contain;
        transform: translate(0);
    }
    .checkbox {
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;

        &:hover {
            background-color: $deep-blue-600;
            color: $deep-blue-100;
        }

        label {
            display: inline-block;
            white-space: nowrap;
            font-weight: bold;
            position: relative;
            padding: 5px 5px 5px 35px;

            input[type="checkbox"] {
                display: none;

                &:not(:checked) + .check-mark {
                    > i, > svg  {
                        opacity: 0;
                    }
                }
            }

            .check-mark {
                position: absolute;
                border: 1px solid $deep-blue-100;
                border-radius: .25em;
                width: 18px;
                height: 18px;
                display: inline-block;
                left: 10px;
            }

            span.glyphicons {
                padding-right: 5px;
            }

            &:after {
                content: ' ';
                display: table;
                clear: both;
            }
        }
    }
}
.import-description{
    font-weight: 100;
    margin-bottom: 0;
}
.importLoadingScreen {
    display:none;
    position: inherit;
    width: 100%;
    height: 85%;
    text-align: center;
    z-index: 1;
    color: $hihaho-primary;
    background-color: $deep-blue-100;
    padding-top: 50px;

    & img {
        margin-left: auto;
        margin-right: auto;
    }
}

 .btn-toolbar {
     .toolbar-icons {
         img {
             height: 16px;
             width: 16px;
         }

         .btn {
             color: $deep-blue-100;
             position: relative;

             svg, .glyphicons, i {
                 fill: $deep-blue-600;
                 color: $deep-blue-600;
                 width: 20px;
                 height: 20px;
             }

             &.btn-icon {
                 border-radius: 10px;
             }

             &:hover,
             &:focus {
                 background-color: $deep-blue-800;

                 svg, .glyphicons, i {
                     fill: $deep-blue-100;
                     color: $deep-blue-100;
                 }
             }
         }
     }
 }

#interactionTimelines, #questionTimelines {
    .right-rounded-bar {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .left-rounded-bar {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
}

#scrollable-timeline {
    body.disable-scrollable-timeline & {
        max-height: 100% !important;
        overflow-y: visible !important;
        width: 100% !important;
    }
}

#enrich #scrollable_timeline_toggle {
    body:not(.disable-scrollable-timeline) & {
        background: $sky-blue-400;
        color: $sky-blue-50;
    }
}

#switchViewType > .btn-filter-interaction {
    border-width: 0;
}

#navigation-steps-container {
    .dropdown-menu {
        z-index: 20000;

        li > a {
            border-radius: 0;
            color: $deep-blue-800;

            &:hover {
                color: $deep-blue-800;

                &.danger {
                    background-color: $hihaho-red;
                }
            }
        }
    }
}

.action-buttons {
    display: flex;
    width: 100%;
}

.mr-10 {
    margin-right: 10px;
}

#VideoTitleBar #ChangeTitle {
    margin-left: 10px;
}

.interaction .interactionZIndex {
    color: $deep-blue-800;
    font-style: italic;
    display: none;
    width: 100px;
    &.block {
        display: block;
    }
}

#timelines {
    position: relative;
    margin-top: 25px;

    h3 {
        position: absolute;
        margin-top: 10px;
    }
}

.timeline {
    background-color: #EEE;
    border-radius: 10px;
    border: 1px solid #CCC;
    padding: 3px;
}

.timeIndicator {
    height: 25px;
    width: 2px;
    position: absolute;
    left: 200px;
    background-color: rgb(247, 144, 34);
    margin-top: -5px;
}

button.editInteraction {
    float: right;
    border-radius: 100%;
    margin-right: -11px;
    font-size: 10px;
}

#enrich[data-mode="standard"] {
    #editorStandardTab {
        background-color: #DDE8ED;
        border-color: #DDE8ED;
    }

    #editorStandardSegment {
        display: flex;

        .row {
            width: 100%;
        }
    }
}

#enrich[data-mode="adaptive"] {
    #editorAdaptiveTab {
        background-color: #DDE8ED;
        border-color: #DDE8ED;
    }

    #editorAdaptiveSegment {
        display: flex;
    }

}

 #enrich[data-mode="non_adaptive_edit"] {
     #editorAdaptiveControls {
         visibility: hidden;
     }

     #editorStandardSegment {
         display: block;
     }
 }

#enrich[data-mode="adaptive_question_edit"] {
    #editorAdaptiveTab {
        background-color: #DDE8ED;
        border-color: #DDE8ED;
    }

    #editorStandardSegment {
        display: block;
        overflow-y: scroll;
    }

    #editorAdaptiveControls {
        visibility: hidden;
    }

    .question-container {
        overflow: hidden;
    }

    .EditArea {
        margin-left: 5px!important;
    }
}

#enrich[data-mode="adaptive_interactions"] {
    #editorAdaptiveTab {
        background-color: #DDE8ED;
        border-color: #DDE8ED;
    }

    #editorStandardSegment {
        display: flex;
        height: 30rem;

        #interaction-button-jump_to {
            display: block;
        }

        #interaction-button-link_video {
            display: block;
        }

        .btn-create-interaction {
            display: none;
        }

        .adaptive-interaction-controls {
            display: flex;
        }

        .adaptive-interaction-text-jump_to {
            display: block;
        }
    }
}

#enrich[data-mode="adaptive_interaction_edit"] {
    #editorAdaptiveTab {
        background-color: #DDE8ED;
        border-color: #DDE8ED;
    }

    #editorStandardSegment {
        display: flex;
    }

    #editorAdaptiveControls {
        visibility: hidden;
    }

    #editorStandardSegment .row {
        display: flex;
        width: 100%;
        flex-direction: column;
        flex: 1;

        .EditArea {
            display: flex;
            flex-direction: column;
            flex: 1;
        }

        .interactionForm {
            display: flex;
            flex-direction: column;
            margin-bottom: 25px;
            flex: 1;
        }

        .action-buttons {
            position: absolute;
            bottom: 0;
        }

        .tab-content {
            overflow-y: hidden;
            padding-top: 5px;
        }
        .adaptive-jump-options {
            display: flex;
        }
    }
}
