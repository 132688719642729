@import "./player.color";

/*
 * JQuery context menu
 */
ul.context-menu-root {
  &.context-menu-list li.context-menu-item {
    &.context-menu-input {
      min-width: 320px;

      > label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: normal;

        > input {
          width: 100%;
          border: 1px solid $deep-blue-200;
          border-radius: 0.5rem;
          font-weight: bold;
          box-shadow: none;
          color: $deep-blue-800;
          height: 24px;
          padding: 3px 10px;
          font-size: 12px;

          &[name="context-menu-input-move_layer_manually"] {
            max-width: 44px;
          }
        }
      }
    }

    &.context-menu-hover {
      background-color: $deep-blue-800;
      color: white;
    }

    &:not(.text):not(.icon) {
      padding-left: 4px !important;
    }

    .text {
      padding-left: 19px !important;
    }

    .icon {
      padding-left: 4px !important;

      .glyphicons {
        top: -1px;
      }
    }
  }
}

