@import '../../variables';
@import "../../mixins";
@import "player.color";

/*question screen*/
.questionType {
    font-style: italic;
    color: $question-text-color;
    @media (min-width: $screen-md-min) {
        font-size: 115%;
    }
    @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
        font-size: 140%;
        margin-bottom: 25px !important;
    }
}

.videoOverlay.question {
    pointer-events: auto !important;

    &.open-question {
        .answers-wrapper {
            flex-wrap: wrap;
        }

        .question-image-container {
            width: 100%;
            margin: 1rem 0;
        }
    }

    .error-text {
        font-size: 24px;

        .error {
            @media (max-width: $screen-sm-min) {
                font-size: 150%;
            }

            @media (max-width: $screen-xs-min) {
                font-size: 160%;
            }

        }
    }

    .title {
        font-size: 1.5rem;

        .question-text-wrapper {
            @media (min-width: $screen-xs-min) {
                font-size: 240% !important;
            }
            @media (min-width: $screen-sm-min) {
                font-size: 200% !important;
            }
        }
    }

    .answerRadio, .answerCheckbox {
        font-size: 1.5em;

        .answer-text {
            white-space: pre-wrap;
            font-weight: var(--font-weight, 400);

            @media (min-width: $screen-xs-min) {
                font-size: 160% !important;
            }

            @media (min-width: $screen-sm-min) {
                font-size: 150% !important;
            }
        }
    }

    .interactionTextQuestion:not(.questiontext) {
        font-size: 24px;

        .questionType {
            @media (min-width: $screen-xs-min) {
                font-size: 140% !important;
            }
            @media (min-width: $screen-sm-min) {
                font-size: 120% !important;
            }
            @media (min-width: $screen-md-min) {
                font-size: 100% !important;
            }
        }
    }

    &[data-layout="CLASSIC"] {
        .question-image-container {
            img {
                max-height: 25rem;
                object-fit: contain;
                padding-left: 1.5rem;
            }
        }

        .close-error {
            display: none;
        }
    }

    &.mc-question,
    &.mr-question,
    &.open-question {
        .scrollable {
            grid-template-areas:
                "title ."
                "top image"
                "bottom image";
        }

        &[data-layout="MODERN"] {
            .scrollable {
                display: grid;
                grid-template-rows: auto auto 1fr;
                grid-template-columns: 1fr fit-content(40%);
                width: auto;
                margin-right: $default-gutter-modern;
                margin-left: $default-gutter-modern;
                padding: 0;

                > *:blank {
                    display: none;
                }
            }

            .title-and-questions {
                grid-area: title;
            }

            &.has-image .title-and-questions {
                grid-column-end: 3;
            }

            .error-text {
                grid-area: top;

                .error {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    margin-bottom: $default-gutter-modern;
                    border-radius: #{map-get($border-radius-theme-modern, firstRadius)};
                    padding: 1.2rem .9rem 1.2rem 2rem;
                    color: $text-red-900;
                    background-color: $text-red-50;
                    @media (min-width: $screen-sm-min) {
                        font-size: 2rem;
                    }

                    &.no-answer-selected {
                        margin-bottom: $default-gutter-modern;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    .close-error {
                        cursor: pointer;
                    }
                }

                &.has-error + .answers .answer {
                    border-color: $text-red-900;
                }
            }

            .title {
                .question-text-wrapper {
                    @media (min-width: $screen-xs-min) {
                        font-size: 200% !important;
                    }
                }
            }

            .answers {
                grid-area: bottom;

                &.empty-title {
                    margin-top: 30px;
                }
            }

            .interactionTextQuestion:not(.questiontext) {
                .questionType {
                    color: var(--player-theme-question-text-color-fade-70, rgba($hihaho-white, .7));
                    font-weight: normal;

                    @media (min-width: $screen-xs-min) {
                        font-size: 125% !important;
                    }
                    @media (min-width: $screen-sm-min) {
                        font-size: 125% !important;
                    }
                    @media (min-width: $screen-md-min) {
                        font-size: 125% !important;
                    }
                }
            }

            .question-image-container,
            .question-image-container-fullscreen-placeholder {
                grid-area: image;
                justify-self: end;
                width: 24rem;
                margin: 0 0 0 $default-gutter-modern;
            }

            .question-image-container {
                // estimated values. These get overridden by js
                --this-element-top: 141px;
                --this-element-bottom: 455px;
                --this-element-left: 792px;
                top: var(--this-element-top);
                right: 0;
                bottom: var(--this-element-bottom);
                left: var(--this-element-left);
                height: 100%;
                max-width: 24rem;
                min-height: 23rem;
                background: #222222;
                cursor: pointer;
                transition: left .3s ease-out, top .3s ease-out, width .3s ease-out, border .3s ease-out, background-color .3s ease-out;

                &.view-full-screen-button,
                &:hover {
                    img {
                        border: .4rem solid var(--player-theme-question-text-color, $hihaho-white);
                    }

                    .full-screen-button {
                        opacity: 1;
                    }
                }

                .full-screen-button {
                    position: relative;
                    display: block;
                    left: calc(100% - (#{$full-screen-button-width} + 1rem));
                    width: $full-screen-button-width;
                    margin-bottom: -3.1rem;
                    border-bottom-right-radius: #{map-get($border-radius-theme-modern, firstRadius)};
                    border-bottom-left-radius: #{map-get($border-radius-theme-modern, firstRadius)};
                    padding: .7rem 1.3rem .9rem 1.3rem;
                    color: var(--player-theme-background-color, $deep-blue-800);
                    background: var(--player-theme-question-text-color, $hihaho-white);
                    background-size: 1rem;
                    font-size: 1rem;
                    font-weight: 600;
                    text-align: center;
                    opacity: 0;
                    transition: opacity .3s ease-out;

                    &:focus {
                        border: none;
                        outline: none;
                    }

                    svg,
                    .fak {
                        margin-right: .65rem;
                        margin-bottom: -.14rem;
                    }
                }

                img {
                    width: 100%;
                    height: 100%;
                    border: 0 solid var(--player-theme-question-text-color, $hihaho-white);
                    border-radius: #{map-get($border-radius-theme-modern, firstRadiusBig)};
                    object-fit: cover;
                    transition: border .3s ease-out;
                }

                &[data-image-fit="CONTAIN"],
                &[data-image-fit="COVER"] {
                    background: transparent;
                    border-radius: 0;
                }

                &[data-image-fit="COVER"] {
                    border-radius: #{map-get($border-radius-theme-modern, firstRadiusBig)};

                    img {
                        object-fit: cover;
                    }
                }

                &[data-image-fit="CONTAIN"] {
                    img {
                        object-position: top;
                        object-fit: contain;
                        height: auto;
                        max-height: 100%;
                    }
                }
            }

            .full-screen-image {
                .error-text .error,
                .answers .answer {
                    margin-right: 0;
                    margin-left: 0;
                }

                .question-image-container {
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    right: $default-gutter-modern;
                    bottom: 19%;
                    left: $default-gutter-modern;
                    max-width: none;
                    width: auto;
                    height: auto;
                    margin: 0;
                    border-radius: #{map-get($border-radius-theme-modern, firstRadiusBig)};
                    background: $hihaho-black;

                    img {
                        height: 100%;
                        object-position: center;
                    }

                    .full-screen-button {
                        left: calc(100% - (#{$full-screen-button-width} + #{$default-gutter-modern}));
                        opacity: 1;
                    }

                    img {
                        margin-right: auto;
                        border: .4rem solid var(--player-theme-question-text-color, $hihaho-white);
                        object-fit: contain;
                    }
                }

                .question-image-container-fullscreen-placeholder {
                    display: block;
                }
            }
        }
    }

    &.mc-question,
    &.mr-question {
        &[data-layout="MODERN"] {
            .interactionTextQuestion:not(.questiontext) {
                margin-top: 1.8rem;
            }
        }

        &[data-layout="CLASSIC"] {
            .scrollable {
                display: grid;
                grid-template-rows: auto auto 1fr;
                grid-template-columns: 1fr fit-content(40%);
            }

            .title-and-questions {
                grid-area: title;
            }

            .question-image-container {
                grid-area: image;
            }

            .answers {
                grid-area: bottom;
            }

            .error-text {
                grid-area: top;

                .error.no-answer-selected {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.vacancy-question {
        &[data-layout="MODERN"] {
            .answers {
                margin-top: 25px;

                .form-input {
                    max-height: 4.2rem;
                }
            }
        }
    }
}

.videoOverlay.questionScore,
.videoOverlay.feedback,
.videoOverlay.question {
    &[data-layout="MODERN"] {
        .form-input-question,
        .answer,
        .input-group-addon {
            height: 5rem;
            padding: 1.5rem 1.9rem;
            border: 2px solid var(--player-theme-question-text-color-fade-70, rgba($hihaho-white, .7));
            border-radius: #{map-get($border-radius-theme-modern, firstRadius)};
            color: var(--player-theme-question-text-color, #FFFFFF);
            font-style: normal;
            background-color: transparent;
            @media (min-width: $screen-sm-min) {
                font-size: 2rem;
            }

            &:focus {
                border: 2px solid var(--player-theme-question-text-color, $hihaho-white);
                box-shadow: inset 0 0 4px var(--player-theme-question-text-color-fade-40, $hihaho-white);
            }

            &::placeholder {
                color: var(--player-theme-question-text-color-fade-70, rgba($hihaho-white, .7));
            }
        }


        &.open-question {

            .scrollable {
                grid-row-gap: $default-gutter-modern;
            }

            .title-and-questions {
                grid-area: title;
            }

            .input-group {
                padding: 0;
            }

            .answers {
                grid-area: top;
                justify-self: stretch;
                width: auto;
                margin-top: 0;

                .input-group {
                    display: flex;
                    align-items: flex-start;
                    margin-top: 0;
                    margin-left: auto;
                    margin-right: auto;

                    .answer {
                        border-radius: 0;
                        line-height: 1.2;
                    }

                    > *:first-child {
                        border-top-left-radius: #{map-get($border-radius-theme-modern, firstRadius)};
                        border-bottom-left-radius: #{map-get($border-radius-theme-modern, firstRadius)};
                    }

                    > *:last-child {
                        border-top-right-radius: #{map-get($border-radius-theme-modern, firstRadius)};
                        border-bottom-right-radius: #{map-get($border-radius-theme-modern, firstRadius)};
                    }
                }

                .input-group-addon {
                    width: auto;
                    font-weight: normal;

                    &.prefix {
                        border-bottom-right-radius: 0;
                        border-top-right-radius: 0;
                        margin-right: -2px;
                    }

                    &.suffix {
                        border-bottom-left-radius: 0;
                        border-top-left-radius: 0;
                        margin-left: -2px;
                    }
                }

                .answer {
                    line-height: 1.2;
                }

                > .answer {
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .error-text {
                grid-area: bottom;
            }
        }
    }
}

.answerRadio,
.answerCheckbox {
    padding-bottom: 5px;
    color: $question-icon-color;
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;

    [data-layout="MODERN"] & {
        padding-bottom: 22px;
    }

    &:last-child {
        padding-bottom: 0;
    }

    &::after {
        // This extends the hover area on the right side of the label
        content: '';
        height: 100%;
        float: right;
        width: 50px;
    }

    label {
        position: relative;
        display: block;
        min-height: 3em;
        padding: 0.625em 1.3em 0.625em 4.3em;
        color: $question-text-color;
        font-weight: var(--font-weight, 400);

        // Disable hover effect on touch devices to prevent https://hihaho.atlassian.net/browse/HPB-3773
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                .icon-placeholder {
                    .hihaho-icon.checkboxToggle.checkbox-empty::before,
                    .hihaho-icon.radioToggle.radio-empty::before {
                        border: 0;
                    }

                    .hiha-stack {
                        display: block;
                        z-index: 2;

                        .hiha-stack-1x {
                            z-index: 5;
                            position: absolute;
                            opacity: 0.4;
                        }

                        .hiha-stack-2x {
                            position: absolute;
                            color: $question-icon-color-alpha-0\.4;
                        }

                        .radio-active-fg {
                            color: #d3d3d3;
                            display: block;
                        }
                    }
                }
            }

            &:not(.text-primary) .hihaho-icon {
                &.circle, &.checkbox-empty {
                    color: #fff;
                }
            }
        }

        &.text-primary {
            // Active state arrow keys
            background: rgba(255, 255, 255, 0.2);
        }
    }

    .icon-placeholder {
        color: #ffffff;

        .hihaho-icon,
        .hiha-stack {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            max-width: 100%;
            margin: 0;
        }

        .hihaho-icon {
            line-height: unset;

            [data-layout="MODERN"] & {
                top: .56em;
                left: 1.1em;
            }

            &::before {
                font-size: 3.1em;

                [data-layout="MODERN"] & {
                    font-size: 2.28em;
                }
            }

            &.checkboxToggle.checkbox-empty::before,
            &.radioToggle.radio-empty::before {
                content: '';
                display: block;
                width: .7em;
                height: .7em;
                border: 2px solid var(--player-theme-question-icon-color, #fff);
                margin: .15em;
                border-radius: 50%;
                @media screen and (max-width: $screen-xs-max) {
                    border: 4px solid var(--player-theme-question-icon-color, #fff);
                }
            }

            &.checkboxToggle.checkbox-empty::before {
                border-radius: 10%;
            }
        }
    }
}

div.interactionContainer .questiontext {
    color: $question-text-color;
    font-weight: 700;
    @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
        margin-bottom: 25px !important;
        margin-top: 25px !important;
        padding-bottom: 25px !important;
    }
}

.questionmarkQuestions {
    .hiha-stack {
        display: block;
        font-size: 50px;

        .hiha-stack-1x {
            z-index: 5;
            position: absolute;
            color: $player-primary;
        }

        .radio-active-bg {
            color: #FFFFFF;
            display: block;
            visibility: visible;
        }

        .hover_sign {
            visibility: hidden;
            display: none;
        }
    }

    // Disable hover effect on touch devices to prevent https://hihaho.atlassian.net/browse/HPB-3773
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            .hiha-stack {
                margin-left: 20px;
                display: block;
                font-size: 50px;

                .hiha-stack-1x {
                    z-index: 5;
                    position: absolute;
                    color: #FFFFFF;
                }

                .radio-active-bg {
                    visibility: hidden;
                    display: none;
                }

                .hover_sign {
                    display: block;
                    visibility: visible;
                    margin-top: -19px;
                    margin-left: -19px;
                    color: $player-primary;
                    font-size: 88px;
                }
            }
        }
    }
}

.icon-placeholder {
    .radioToggle {
        display: block;
        margin-top: 12px;

    }

    .hiha-stack {
        display: none;
        margin-top: 7px;
    }
}

input[type=checkbox]:checked + .icon-placeholder {
    .checkboxToggle {
        display: none;
    }

    .hiha-stack {
        display: block;

        .hiha-stack-1x {
            z-index: 5;
            color: $question-icon-color;
        }

        .hiha-stack-2x {
            color: #fff;
        }

        .checkbox-empty {
            display: none;
        }

        .checkbox-active-bg {
            display: block;
            color: $question-icon-color;
        }

        .checkbox-active-fg {
            color: var(--player-theme-primary-lighten-50, $green-500);
            z-index: 6;

            &::before {
                display: inline-flex;
                animation: box-bounce .2s 1 linear;
                transform-origin: center;
            }
        }
    }
}

input[type=radio]:checked + .icon-placeholder {
    .radioToggle {
        display: none;
    }

    .hiha-stack {
        display: block;

        .hiha-stack-1x {
            color: $question-icon-color;
            opacity: 1;
        }

        .hiha-stack-2x {
            color: #fff;
        }

        .radio-empty {
            display: none;
        }

        .radio-active-bg {
            display: block;
            color: $question-icon-color;
        }

        .radio-active-fg {
            color: var(--player-theme-primary-lighten-50, $green-500);
            display: block;

            &::before {
                display: inline-flex;
                animation: box-bounce .2s 1 linear;
                transform-origin: center;
            }
        }
    }
}

.radio-active-bg {
    color: $question-icon-color;
    transition: background-color 1000ms linear;
}

.answerCheckbox label:not(.text-primary) .hihaho-icon.square {
    color: $question-icon-color;

    label {
        color: $question-text-color;

        .icon-placeholder {
            color: $question-icon-color;
        }
    }
}

.answers {
    margin-top: 50px;

    .input-group {
        margin-top: 15px;
    }

    .form-control {
        border: 1px solid $hihaho-white;
    }

    input.form-control {
        font-size: 2rem;
        line-height: 2rem;
    }

    .prefix {
        border: 4px solid #fff;
        font-size: 2em;
        font-weight: bold;
        background-color: #999999;
        color: #fff;
    }

    .suffix {
        border: 4px solid #fff;
        font-size: 2em;
        font-weight: bold;
        background-color: #999999;
        color: #fff;
    }

    .answer {
        color: #575756;
        font-style: italic;
        font-weight: var(--font-weight, 400);
    }

    input.answer {
        height: 70px;
        font-size: 2em;
        line-height: 2em;
    }

    .ratings-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .circle-active {
        color: $player-primary;
    }

    #review-form {
        .star-rating {
            display: inline-flex;
            flex-direction: row-reverse;
            font-size: 75px;
            color: yellow;
        }

        input[type=radio] {
            display: none;

            + .label_star {
                display: flex;
                cursor: pointer;
                margin-left: 20px;
                margin-right: 20px;

                i {
                    &.star {
                        display: none;
                        position: absolute;
                    }

                    &.star_empty {
                        display: inline-block;
                    }
                }
            }

            &:checked, &:hover {
                ~ .label_star i.star {
                    display: inline-block;
                }
            }

            // Disable hover effect on touch devices to prevent https://hihaho.atlassian.net/browse/HPB-3773
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    ~ .label_star i.star {
                        opacity: 0.55;
                    }

                    &:checked {
                        ~ .label_star i.star {
                            opacity: 0.9;
                        }
                    }
                }
            }
        }
    }
}

.videoOverlay.rating-question {
    .videoOverlayInner {
        display: flex;
    }

    .scrollable {
        position: relative;
        height: 50%;
        width: 100%;
        display: flex;
        overflow-y: visible;
        justify-content: center;

        h3 {
            display: flex;
            overflow-y: auto;
            flex: 1 1 auto;

            .question-text-wrapper {
                display: block;
                width: 100%;
                margin-top: auto;
                line-height: normal;
                text-align: center;

                .interactionTextQuestion {
                    display: inline-block;
                    line-height: 1.1;
                }
            }
        }

        p.error-text {
            position: absolute;
            bottom: -160px;
            left: 0;
            width: 100%;
            display: flex;
            justify-content: center;

            .error {
                margin: 0;
                padding: 0;
            }
        }

        .answers {
            position: absolute;
            bottom: -100px;
            left: 0;
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }

    h5.interactionTextQuestion, .scroll-hint {
        display: none;
    }

    &[data-layout="MODERN"] {
        .scrollable {
            height: calc(50% - 2.5em);
        }
    }
}
