table.no-linebreak {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;

    .displayname {
        width: 146px;
    }

    .emailaddress {
        width: 157px;
    }

    .language {
        width: 92px;
    }

    .timezone {
        width: 200px;
    }

    .usertpe {
        width: 148px;
    }

    .actions {
        width: 170px;
    }
}
