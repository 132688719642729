/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 11-apr-2017, 12:28:07
    Author     : Robert Boes
*/

@import "player.color";
@import "../../variables";
@import "../../mixins";

.VideoContent{
    .btn {
        border-radius: #{map-get($default-border-radius, firstRadius)} / #{map-get($default-border-radius, secondRadius)};
        padding: 6px 31px;
        transition: background-color .2s ease-out;
    }
    [data-layout="MODERN"] .btn {
        padding: .6rem $default-gutter-modern .4rem $default-gutter-modern;
        border-radius: #{map-get($border-radius-theme-modern, firstRadius)};
        font-weight: bold;
    }
    .btn-rounded {
        border-radius: #{map-get($default-border-radius, firstRadiusSmall)} / #{map-get($default-border-radius, secondRadius)};
        padding: 6px 31px;
        transition: background-color .3s ease-out;
    }
    .btn-primary{
        @include button-variant($player-text-color, $player-primary, $player-primary);
    }
    [data-layout="MODERN"] .btn-primary {
        @include button-variant($player-text-color, $player-button-bg-color, $player-button-bg-color, $player-button-bg-color, true);
    }
    .btn-warning{
        @include button-variant($player-text-color, $player-secondary, $player-secondary);
    }
    .btn-default {
        @include button-variant($player-primary, $player-text-color, $player-primary, $player-primary);
    }

    ul.loading > li {
        background-color: $player-primary;
    }
}
