input textarea, select {
    @apply text-gray-700 font-normal bg-white border-gray-300 border;

    &::placeholder {
        @apply text-gray-500 opacity-100;
    }

    &:focus {
        @apply border-none text-deep-blue-800 border-sky-blue-400 outline-none;
    }

    &:disabled {
        @apply bg-gray-200 cursor-not-allowed;
    }
}

input, textarea {
    @apply caret-blue-450;
}

input[type="checkbox"], input[type="radio"] {
    @apply m-0;

    &:focus {
        @apply shadow-none border-transparent outline-none;
    }

    &:hover {
        @apply border-blue-450;
    }

    &.form-input-error {
        @apply text-red-500;

        &:hover {
            @apply bg-red-500;
        }
    }

    &.form-input-warning {
        @apply text-orange-500;

        &:hover {
            @apply border-orange-500;
        }
    }

    &.form-input-success {
        @apply text-green-500;

        &:hover {
            @apply border-green-500;
        }
    }

    &.form-input-pink {
        @apply text-pink-600;

        &:hover {
            @apply border-pink-600;
        }
    }
}

input[type="checkbox"] {
    &.form-checkbox-auth {
        @apply text-blue-500 border-deep-blue-800 text-2xs rounded;

        &:hover {
            @apply text-deep-blue-300 border-deep-blue-300;
        }

        &:checked {
            @apply text-green-500;
        }
    }
}

input {
    &.form-input-question {
        @apply bg-transparent border-white border-solid border-3 rounded-lg text-white text-2xl;

        &:focus {
            @apply shadow-none border-blue-450 border-3 border-solid text-white;
        }

        &::placeholder {
            @apply text-gray-500;
        }
    }

    &.form-input-login {
        @apply bg-deep-blue-200 rounded-md border-none caret-sky-blue-800 text-deep-blue-900;

        &::placeholder {
            @apply text-deep-blue-900;
        }

        &:focus:not(.form-input-error) {
            @apply border-none shadow-none bg-deep-blue-100 ring-deep-blue-300;
        }

        &:placeholder-shown {
            @apply bg-deep-blue-200;
        }
    }
}

input, textarea, select {
    &.form-input-error {
        @apply text-red-700 bg-red-100 border-red-500 caret-red-600;

        &::placeholder {
            @apply text-red-400;
        }

        &:focus {
            @apply border-red-600;
        }
    }

    &.form-input-warning {
        @apply text-orange-700 bg-orange-100 border-orange-500 caret-orange-600;

        &::placeholder {
            @apply text-orange-400;
        }

        &:focus {
            @apply border-orange-600;
        }
    }

    &.form-input-success {
        @apply text-green-700 bg-green-100 border-green-500 caret-green-600;

        &::placeholder {
            @apply text-green-400;
        }

        &:focus {
            @apply border-green-600;
        }

    }

    &.form-input-pink {
        @apply text-pink-800 bg-pink-100 border-pink-600 caret-pink-600;

        &::placeholder {
            @apply text-pink-400;
        }

        &:focus {
            @apply border-pink-600;
        }
    }
}
