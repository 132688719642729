@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/vendor-prefixes";
@import "functions";

@mixin break($args...) {
    @if length($args) == 1 {
        @media (min-width: nth($args, 1)) {
            @content;
        }
    } @else {
        @media (min-width: nth($args, 1))
            and (max-width: nth($args, 2)) {
            @content;
        }
    }
}

@mixin count($count: 1) {
    -webkit-animation-iteration-count: $count;
    -moz-animation-iteration-count: $count;
    -ms-animation-iteration-count: $count;
    -o-animation-iteration-count: $count;
    animation-iteration-count: $count;
}

@mixin duration($duration: 1s) {
    -webkit-animation-duration: $duration;
    -moz-animation-duration: $duration;
    -ms-animation-duration: $duration;
    -o-animation-duration: $duration;
    animation-duration: $duration;
}

@mixin delay($delay: .2s) {
    -webkit-animation-delay: $delay;
    -moz-animation-delay: $delay;
    -ms-animation-delay: $delay;
    -o-animation-delay: $delay;
    animation-delay: $delay;
}

@mixin function($function: ease) {
    -webkit-animation-timing-function: $function;
    -moz-animation-timing-function: $function;
    -ms-animation-timing-function: $function;
    -o-animation-timing-function: $function;
    animation-timing-function: $function;
}

@mixin fill-mode($fill: both) {
    -webkit-animation-fill-mode: $fill;
    -moz-animation-fill-mode: $fill;
    -ms-animation-fill-mode: $fill;
    -o-animation-fill-mode: $fill;
    animation-fill-mode: $fill;
}

@mixin visibility($visibility: hidden) {
    -webkit-backface-visibility: $visibility;
    -moz-backface-visibility: $visibility;
    -ms-backface-visibility: $visibility;
    -o-backface-visibility: $visibility;
    backface-visibility: $visibility;
}

@mixin transform($property) {
    -webkit-transform: $property;
    -moz-transform: $property;
    -ms-transform: $property;
    -o-transform: $property;
    transform: $property;
}

@mixin transform-origin($transform-origin: center center) {
    -webkit-transform-origin: $transform-origin;
    -moz-transform-origin: $transform-origin;
    -ms-transform-origin: $transform-origin;
    -o-transform-origin: $transform-origin;
    transform-origin: $transform-origin;
}

@mixin transform-style($transform-style: flat) {
    -webkit-transform-style: $transform-style;
    -moz-transform-style: $transform-style;
    -o-transform-style: $transform-style;
    transform-style: $transform-style;
}

@mixin animation-name($animation-name) {
    -webkit-animation-name: $animation-name;
    -moz-animation-name: $animation-name;
    -ms-animation-name: $animation-name;
    -o-animation-name: $animation-name;
    animation-name: $animation-name;
}

@mixin animation($animation) {
    -webkit-animation: $animation;
    -moz-animation: $animation;
    -ms-animation: $animation;
    -o-animation: $animation;
    animation: $animation;
}

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }

    @-moz-keyframes #{$animation-name} {
        @content;
    }

    @-ms-keyframes #{$animation-name} {
        @content;
    }

    @-o-keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin object-fit($value: cover) {
    width: 100%;
    height: 100%;
    object-fit: $value;
}

@mixin justify-content($value: flex-start) {
    @if $value == flex-start {
        -webkit-box-pack: start;
        -moz-box-pack: start;
        -ms-flex-pack: start;
    } @else if $value == flex-end {
        -webkit-box-pack: end;
        -moz-box-pack: end;
        -ms-flex-pack: end;
    } @else if $value == space-between {
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
    } @else if $value == space-around {
        -ms-flex-pack: distribute;
    } @else {
        -webkit-box-pack: $value;
        -moz-box-pack: $value;
        -ms-flex-pack: $value;
    }
    -webkit-justify-content: $value;
    justify-content: $value;
}

@mixin button-variant($color, $background, $border, $hover-border: $background, $modern: false) {
    color: $color;
    background-color: $background;
    border-color: $border;

    &:focus,
    &.focus,
    &:active,
    &.active {
        color: $color;
        background-color: $background;
        border-color: $border;
        outline: none;
    }
    // Disable hover effect on touch devices to prevent https://hihaho.atlassian.net/browse/HPB-3773
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            color: if($modern, $color, $background);
            background-color: if($modern, var(--player-theme-button-color-accent-20, darken($hihaho-secondary, 10%)), $color);
            border-color: if($modern, var(--player-theme-button-color-accent-20, darken($hihaho-secondary, 10%)), $hover-border);
        }
    }
    .open > &.dropdown-toggle {
        color: $background;
        background-color: $color;
        border-color: $hover-border;

        &:hover,
        &:focus,
        &.focus {
            color: $background;
            background-color: $color;
            border-color: $hover-border;
        }
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
        background-image: none;
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &:hover,
        &:focus,
        &.focus {
            background-color: $background;
            border-color: $border;
        }
    }

    .badge {
        color: $background;
        background-color: $color;
    }
}

@mixin animation-reverse(){
    -webkit-animation-direction: reverse;
    -moz-animation-direction: reverse;
    animation-direction: reverse;
}
@mixin gradient-color-overlay($start-color, $end-color, $start-alpha:0.7, $end-alpha:1, $start-position:0%, $end-position:85%) {
    background: -moz-linear-gradient(top,
            native-rgba($start-color, $start-alpha) $start-position,
            native-rgba($end-color, $end-alpha) $end-position
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,
            native-rgba($start-color, $start-alpha) $start-position,
            native-rgba($end-color, $end-alpha) $end-position
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,
            native-rgba($start-color, $start-alpha) $start-position,
            native-rgba($end-color, $end-alpha) $end-position
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin gradient-left-to-right($start-color, $end-color) {
    background: $start-color; /* Old browsers */
    background: -moz-linear-gradient(left, $start-color 0%, $end-color 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, $start-color 0%, $end-color 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, $start-color 0%, $end-color 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1 ); /* IE6-9 */
}

@mixin panel-variant($border, $heading-text-color, $heading-bg-color, $heading-border) {
    border-color: $border;

    & > .panel-heading {
        color: $heading-text-color;
        background-color: $heading-bg-color;
        border-color: $heading-border;

        + .panel-collapse > .panel-body {
            border-top-color: $border;
        }
        .badge {
            color: $heading-bg-color;
            background-color: $heading-text-color;
        }
    }
    & > .panel-footer {
        + .panel-collapse > .panel-body {
            border-bottom-color: $border;
        }
    }
}

@mixin ribbon-color-variant($name, $color) {
    $ribbon-start-color: $color;
    $ribbon-end-color: darken($ribbon-start-color, 10%);
    $ribbon-back-color: darken($ribbon-start-color, 20%);

    &.#{$name} {
        span {
            @include gradient-left-to-right($ribbon-start-color, $ribbon-end-color);

            &::before, &::after {
                border: 3px solid transparent;
                border-top-color: $ribbon-back-color;
            }

            &::before {
                border-left-color: $ribbon-back-color;
            }

            &::after {
                border-right-color: $ribbon-back-color;
            }
        }
    }
}

@mixin translate($trX: -50%, $trY: -50%) {
    transform: translate($trX, $trY);
}

@mixin translateY($trY: -50%) {
    transform: translateY($trY);
}

@mixin translateX($trX: -50%) {
    transform: translateX($trX);
}

@mixin translateNone() {
    transform: none;
}
